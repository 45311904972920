import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { AccessTokenStorage } from "../core/security";
import { AuthState } from "../context/auth";
import { ComponentEnum, log, logT } from "../log";

const Component = ComponentEnum.Client;

export function getBackendHost(): string {
  const backendHost = process.env.REACT_APP_BACKEND;
  log(logT.INFO, Component, "REACT_APP_BACKEND", backendHost)
  if (!backendHost) {
    if(window.location.host.includes("localhost")) {
      return "http://localhost:8080";
    }
    if(window.location.host.includes(".dev.")) {
      return "https://bk-dev.eonbit.com";
    }
    if(window.location.host.includes(".stage.")) {
      return "https://bk.stage.eonbit.com";
    }
    if(window.location.host.includes(".prod.")) {
      return "https://bk.prod.eonbit.com";
    }

    throw new Error("REACT_APP_BACKEND is not defined");
  }
  return backendHost;
}

export function getClient(
  entity: String,
  setAuthenticated: (authenticated: AuthState) => void
): ApolloClient<any> {
  // Init API endpoint
  const endpoint = getBackendHost() + entity;
  const httpLink = createHttpLink({
    uri: endpoint,
  });

  const authLink = setContext(async (_, { headers }) => {
    const accessTokenStorage = new AccessTokenStorage();
    const accessToken = await accessTokenStorage.get();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
        "Accept-Language": "en",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors) {
      const errCode = graphQLErrors[0].extensions?.code;
      console.debug(errCode);
      switch (errCode) {
        case "errMdwTokenNotSet":
          setAuthenticated(AuthState.NotAuthenticated);
          break;
        case "errSecurityTokenInvalid":
          setAuthenticated(AuthState.NotAuthenticated);
          break;
        case "errSecurityTokenCannotBeChecked":
          setAuthenticated(AuthState.Expired);
          break;
      }
    }
  });

  // Wrapper links
  const link = ApolloLink.from([errorLink, authLink, httpLink]);

  // Init client
  return new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            readAllUsers: relayStylePagination(),
            readCoupons: relayStylePagination(),
            readTranslationCodes: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
    connectToDevTools: true,
  });
}
