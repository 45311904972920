import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface IProps {
    variant?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark';
}

export const LoadingPage = ({variant = 'secondary'}: IProps) => {
    return (
        <div className="d-flex justify-content-center mt-5 mb-5">
            <Spinner animation={"grow"} variant={variant}/>
        </div>
    );
};
