import React from 'react';
import Container from "react-bootstrap/Container";
import {StatisticsUsers} from "../component/StatisticsUsers";
import {StatisticsInvoices} from "../component/StatisticsInvoices";
import {StatisticsIssuers} from "../component/StatisticsIssuers";
import {StatisticsPower} from "../component/StatisticsPower";
import {StatisticsAccess} from "../component/StatisticsAccess";
import {StatisticsSubscriptions} from "../component/StatisticsSubscriptions";
import { StatisticsChurn } from "../component/StatisticsChurn";

export default () => {
	return (
		<Container>
			<StatisticsUsers />
			<StatisticsSubscriptions />
			<StatisticsAccess />
			<StatisticsInvoices />
			<StatisticsIssuers />
			<StatisticsChurn />
			<StatisticsPower />
		</Container>
	);
}
