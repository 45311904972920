import gql from "graphql-tag";
import { IssuerFragments } from "./fragments/issuer";

export const ReadRejectedInvoicesFromRemittance = gql`
    query readRejectedInvoicesFromRemittance($showArchived: Boolean) {
        result: readRejectedInvoicesFromRemittance(showArchived: $showArchived) {
            id
            invoiceId
            userId
            userName
            userEmail
            customerUId
            customerUIdType
            remitBatchId
            remitAttempt
            archived
            supportNote
            pushNote
            remitStatus
            remitStatusDescription
            createdAt
            amount
        }
    }
`;

export const UpdateRemittedInvoiceCustomerUId = gql`
    mutation updateRemittedInvoiceCustomerUId(
        $invoiceId: ID!
        $userId: ID!
        $customerUId: String!
        $customerUIdType: CustomerUidType!
    ) {
        result: updateRemittedInvoiceCustomerUId(
            invoiceId: $invoiceId
            userId: $userId
            customerUId: $customerUId
            customerUIdType: $customerUIdType
        ) {
            id
            customerUId
            customerUIdType
        }
    }`;


export const SetInvoiceArchived = gql`
    mutation setInvoiceArchived($userId: ID!, $invoiceIdentifier: ID!, $archived: Boolean!) {
        result: setInvoiceArchived(userId: $userId, invoiceIdentifier: $invoiceIdentifier, archived: $archived) {
            isOk
        }
    }
`;

export const SetSupportNote = gql`
    mutation setSupportNote($userId: ID!, $invoiceIdentifier: ID!, $supportNote: String!) {
        result: setSupportNote(userId: $userId, invoiceIdentifier: $invoiceIdentifier, supportNote: $supportNote) {
            isOk
        }
    }
`;

export const SendPushMessageToUser = gql`
    mutation sendPushMessageToUser($userId: ID!, $invoiceIdentifier: ID!, $message: String!, $header: String!) {
        result: sendPushMessageToUser(userId: $userId, invoiceIdentifier: $invoiceIdentifier, message: $message, header: $header) {
            isOk
        }
    }
`;


