import { format } from 'date-fns';
import { OfferServiceCreationPage } from "./page/offerServiceCreation";

enum ComponentEnum {
    ALL = "ALL_COMPONENTS", //special case
    BadgeBool = "Badge-Bool",
    BalanceHistory = "Balance-History",
    BufferForecasting = "Buffer-Forecasting",
    Client = "Client",
    CouponDetail = "Coupon-Detail",
    Error = "Error",
    FilterByMonthYear = "Filter-By-Month-Year",
    FormBalanceByDates = "Form-Balance-By-Dates",
    FormBalanceSnapshot = "Form-Balance-Snapshot",
    FormIssuerTransactionRule = "Form-Issuer-Transaction-Rule",
    FormIssuerUpdateEmail = "Form-Issuer-Update-Email",
    FormPictureUpdate = "Form-Picture-Update",
    FormSearchByString = "Form-Search-By-String",
    FormSearchId = "Form-Search-Id",
    FormUserNote = "Form-User-Note",
    Header = "Header",
    IndustryCodesOverview = "Industry-Codes-Overview",
    InvoiceActions = "Invoice-Actions",
    InvoiceHistory = "Invoice-History",
    InvoiceHistoryDetail = "Invoice-History-Detail",
    InvoiceMonthlyChart = "Invoice-Monthly-Chart",
    InvoicesOverview = "Invoices-Overview",
    IssuerDetail = "Issuer-Detail",
    IssuerPicture = "Issuer-Picture",
    IssuersOverview = "Issuers-Overview",
    IssuerSuggestions = "Issuer-Suggestions",
    IssuerWhitelistOverview = "Issuer-Whitelist-Overview",
    LoaderButton = "Loader-Button",
    Loading = "Loading",
    LoadingButton = "Loading-Button",
    ModalChangeSubscription = "Modal-Change-Subscription",
    ModalDeleteAccount = "Modal-Delete-Account",
    ModalSendInvoicePush = "Modal-Send-Invoice-Push",
    ModalUpdateCustomerUId = "Modal-Update-Customer-UId",
    MomentDate = "Moment-Date",
    OfferCategoriesOverview = "Offer-Categories-Overview",
    OfferCategoryDetail = "Offer-Category-Detail",
    OfferCategoryForm = "Offer-Category-Form",
    OfferPage = "Offer-Page",
    OfferDetail = "Offer-Detail",
    OfferForm = "Offer-Form",
    OfferPreview = "Offer-Preview",
    OfferServiceCreationPage = "Offer-Service-Creation-Page",
    OfferServiceDetail = "Offer-Service-Detail",
    OfferServiceForm = "Offer-Service-Form",
    OfferServiceTable = "Offer-Service-Table",
    OffersOverview = "Offers-Overview",
    Profile = "Profile",
    SelectTranslation = "Select-Translation",
    SnapshotsTable = "Snapshots-Table",
    StatisticCard = "Statistic-Card",
    StatisticsAccess = "Statistics-Access",
    StatisticsChart = "Statistics-Chart",
    StatisticsInvoices = "Statistics-Invoices",
    StatisticsIssuers = "Statistics-Issuers",
    StatisticsLastUpdate = "Statistics-Last-Update",
    StatisticsPower = "Statistics-Power",
    StatisticsSubscriptions = "Statistics-Subscriptions",
    StatisticsUsers = "Statistics-Users",
    Status = "Status",
    SubscriptionsManager = "Subscriptions-Manager",
    TranslationCode = "Translation-Code",
    UserActivity = "User-Activity",
    UserDetail = "User-Detail",
    UserGroupMemberList = "User-Group-Member-List",
    UserGroupOverview = "User-Group-Overview",
    UserNoteList = "User-Note-List",
    UsersList = "Users-List",
    UsersOverview = "Users-Overview",
    UserSubscriptions = "User-Subscriptions",
    Footer = "Footer",
    Utils = "Utils",
    ZenPointsOverview = "Zen-Points-Overview",
    ZenCost = "Zen-Cost",
    InvoiceRemittance = "Invoice-Remittance",
    ZenManualCorrection = "Zen-Manual-Correction",
}

enum logT {
    INFO = 0,
    WARN,
    ERROR = 2,
    ALL = 3,
}

const host = window.location.origin;
let logLevel: logT = logT.ERROR;

if (host.includes("localhost")) {
    logLevel = logT.INFO;
    console.log("Log level is INFO as we're on localhost");
    //TODO: Add filter when you're developing
    // LogFilter = [Components.Login, Components.PostAuth]
}

function levToStr(level: logT): string {
    switch (level) {
        case logT.INFO:
            return "NFO";
        case logT.WARN:
            return "WRN";
        case logT.ERROR:
            return "ERR";
        case logT.ALL:
            return "ALL";
        default:
            return "Unknown";
    }
}

//this is the filter for logging - with all components enabled
let LogFilter: ComponentEnum[] = [
    ComponentEnum.BadgeBool,
    ComponentEnum.BalanceHistory,
    ComponentEnum.BufferForecasting,
    ComponentEnum.CouponDetail,
    ComponentEnum.Error,
    ComponentEnum.FilterByMonthYear,
    ComponentEnum.FormBalanceByDates,
    ComponentEnum.FormBalanceSnapshot,
    ComponentEnum.FormIssuerTransactionRule,
    ComponentEnum.FormIssuerUpdateEmail,
    ComponentEnum.FormPictureUpdate,
    ComponentEnum.FormSearchByString,
    ComponentEnum.FormSearchId,
    ComponentEnum.FormUserNote,
    ComponentEnum.Header,
    ComponentEnum.IndustryCodesOverview,
    ComponentEnum.InvoiceActions,
    ComponentEnum.InvoiceHistory,
    ComponentEnum.InvoiceHistoryDetail,
    ComponentEnum.InvoiceMonthlyChart,
    ComponentEnum.InvoicesOverview,
    ComponentEnum.IssuerDetail,
    ComponentEnum.IssuerPicture,
    ComponentEnum.IssuersOverview,
    ComponentEnum.IssuerSuggestions,
    ComponentEnum.IssuerWhitelistOverview,
    ComponentEnum.LoaderButton,
    ComponentEnum.Loading,
    ComponentEnum.LoadingButton,
    ComponentEnum.ModalChangeSubscription,
    ComponentEnum.ModalDeleteAccount,
    ComponentEnum.ModalSendInvoicePush,
    ComponentEnum.ModalUpdateCustomerUId,
    ComponentEnum.MomentDate,
    ComponentEnum.OfferCategoriesOverview,
    ComponentEnum.OfferCategoryDetail,
    ComponentEnum.OfferCategoryForm,
    ComponentEnum.OfferPage,
    ComponentEnum.OfferDetail,
    ComponentEnum.OfferForm,
    ComponentEnum.OfferPreview,
    ComponentEnum.OfferServiceDetail,
    ComponentEnum.OfferServiceForm,
    ComponentEnum.OfferServiceTable,
    ComponentEnum.OffersOverview,
    ComponentEnum.SelectTranslation,
    ComponentEnum.SnapshotsTable,
    ComponentEnum.StatisticCard,
    ComponentEnum.StatisticsAccess,
    ComponentEnum.StatisticsChart,
    ComponentEnum.StatisticsInvoices,
    ComponentEnum.StatisticsIssuers,
    ComponentEnum.StatisticsLastUpdate,
    ComponentEnum.StatisticsPower,
    ComponentEnum.StatisticsSubscriptions,
    ComponentEnum.StatisticsUsers,
    ComponentEnum.Status,
    ComponentEnum.SubscriptionsManager,
    ComponentEnum.TranslationCode,
    ComponentEnum.UserActivity,
    ComponentEnum.UserDetail,
    ComponentEnum.UserGroupMemberList,
    ComponentEnum.UserGroupOverview,
    ComponentEnum.UserNoteList,
    ComponentEnum.UsersList,
    ComponentEnum.UsersOverview,
    ComponentEnum.UserSubscriptions,
    ComponentEnum.Footer,
    ComponentEnum.ZenPointsOverview,
    ComponentEnum.ZenCost,
    ComponentEnum.InvoiceRemittance,
    ComponentEnum.ZenManualCorrection,
];

LogFilter = [ComponentEnum.ALL] //if you want to log everything

let lastLogData = "";
let lastLogCount = 0;

function isInLogFilter(component: ComponentEnum): boolean {
    return LogFilter.includes(ComponentEnum.ALL) || LogFilter.includes(component);
}

const log = (level: logT, component: ComponentEnum, ...data: any[]) => {
    if (!isInLogFilter(component) && level !== logT.ALL) {
        return;
    }
    const currentLogData = data.join(" ");

    if (currentLogData === lastLogData) {
        lastLogCount++;
        if (lastLogCount % 250 === 0) {
            console.log(`${format(new Date(), 'd.M HH:mm:ss')} [${levToStr(level)}-${component}]`, "Repeated log " + lastLogCount + " times");
        }
        return;
    } else {
        lastLogData = currentLogData;
        lastLogCount = 1;
    }

    if (level >= logLevel) {
        const logFunction = level === logT.ERROR ? console.error : console.log;
        logFunction(`${format(new Date(), 'd.M HH:mm:ss')} [${levToStr(level)}-${component}]`, ...data);
    }
};


function Host(): string {
    return host;
}

log(logT.INFO, ComponentEnum.ALL, "Log level is " + levToStr(logLevel));
log(logT.INFO, ComponentEnum.ALL, "Host is " + host)

export { log, logT, Host, ComponentEnum };

