import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AdminReadUserZenHistory } from '../graphql/zenPoints';
import { Card, Table } from "react-bootstrap";
import moment from 'moment';
import { ReadUserByIdQuery, ReadUserByIdQueryVariables, UserZenPointsHistory } from "../types/graphql";
import { ComponentEnum, log, logT } from "../log";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import { ReadUserById } from "../graphql/user";
import { ZenManualCorrection } from "./ZenManualCorrection";

const tdStyle = { padding: "0 15px" };
const thStyle = { padding: "0 20px" };

interface IProps {
    userId: string;
}

interface QueryData {
    readUserZenHistory: UserZenPointsHistory[];
}

const Component = ComponentEnum.ZenPointsOverview;

export const ZenPointsOverview = ({ userId }: IProps) => {
    const history = useHistory();
    const [showZenCorrection, setShowZenCorrection] = useState<boolean>(false);
    const [lastStartId, setLastStartId] = useState<number>(0);
    const [lastIds, setLastIds] = useState<number[]>([0]);
    const [lastDate, setLastDate] = useState<string>('');
    const [firstDate, setFirstDate] = useState<string>('');
    const [filterText, setFilterText] = useState<string>('');
    const [tableData, setTableData] = useState<UserZenPointsHistory[]>([]);
    const { loading, error, data, refetch } = useQuery(AdminReadUserZenHistory, {
        variables: { startId: lastStartId, userId: userId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: dataUser
    } = useQuery<ReadUserByIdQuery, ReadUserByIdQueryVariables>(ReadUserById, {
        variables: {
            userId: userId
        },
        fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (data?.readUserZenHistory) {

            if (data.readUserZenHistory.length === 0) {
                return;
            }
            const lastDate = data.readUserZenHistory[data.readUserZenHistory.length - 1].UpdatedAt;
            setLastDate(lastDate);
            const firstDate = data.readUserZenHistory[0].UpdatedAt;
            setFirstDate(firstDate);

            if (!filterText) {
                setTableData(() => [...data.readUserZenHistory]);
            }

            let filteredTable = [...data.readUserZenHistory].filter((item) => {
                if (!filterText) return true;
                return Object.values(item).some((val) => {
                    if (typeof val === 'object' && val !== null) {
                        return Object.values(val).some((innerVal) => {
                            const strVal = String(innerVal).toLowerCase();
                            if (strVal.includes(filterText.toLowerCase())) return true;
                            const parsedDate = moment(strVal);
                            return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY HH:mm:ss").includes(filterText) : false;
                        });
                    } else {
                        const strVal = String(val).toLowerCase();
                        if (strVal.includes(filterText.toLowerCase())) return true;
                        const parsedDate = moment(strVal);
                        return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY HH:mm:ss").includes(filterText) : false;
                    }
                });
            });
            setTableData(() => filteredTable);
        }
    }, [data, filterText]);

    const nextPage = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        log(logT.INFO, Component, "Next Page");
        load('next');
    };

    const prevPage = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        log(logT.INFO, Component, "Prev Page");
        load('prev');
    };

    const load = (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            //if this has no id data.readUserZenHistory[data.readUserZenHistory.length - 1].Id return
            if (data?.readUserZenHistory.length === 0) {
                return;
            }
            // Assuming the last item in the current list is the startId for the next page
            const newStartId = data.readUserZenHistory[data.readUserZenHistory.length - 1].Id;
            log(logT.INFO, Component, "Next Page", newStartId)
            setLastIds([...lastIds, newStartId]); // Keep track of previous startIds
            setLastStartId(newStartId); // This will trigger a new query
        } else {
            // Pop the current startId and set the last one as the new current
            if (lastIds.length > 1) {
                const newLastIds = [...lastIds];
                newLastIds.pop();
                setLastIds(newLastIds);
                setLastStartId(newLastIds[newLastIds.length - 1]);
            } else {
                setLastStartId(0);
            }
        }
    };

    const onInvoiceClick = useCallback((invoiceIdentifier: string) => {
        history.push(`/invoices/${invoiceIdentifier}`)
    }, [history]);

    const onUserClick = useCallback(() => {
        history.push(`/user/${userId}`)
    }, [history]);

    useEffect(() => {
        log(logT.INFO, Component, "Loading");
        return () => {
            log(logT.INFO, Component, "UnMounting");
        }
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Card style={{ marginTop: "1rem" }}>
            <Card.Header>
                <h3 className="dark" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        User Zen Points and History: &nbsp;
                        {dataUser?.result && (
                            <>
                                <Button
                                    variant='secondary'
                                    size={'sm'}
                                    onClick={() => onUserClick()}
                                >
                                    {dataUser.result.firstname} {dataUser.result.lastname} ({dataUser.result.id}) {dataUser.result.email}
                                </Button> &nbsp;
                                From {moment(firstDate!).format("DD-MM-YYYY")} -
                                To {moment(lastDate!).format("DD-MM-YYYY")}
                            </>
                        )}
                    </div>
                    {dataUser?.result && (
                        <Button onClick={() => {
                            if (showZenCorrection) {
                                refetch();
                            }
                            setShowZenCorrection(!showZenCorrection);
                        }}>
                            {showZenCorrection ? 'Hide' : 'Show'} Manual Zen Correction
                        </Button>

                    )}
                </h3>
                {!showZenCorrection && (
                    <>
                        <Button onClick={prevPage}>Prev</Button> &nbsp; <Button onClick={nextPage}>Next</Button>
                    </>
                )}

            </Card.Header>
            {showZenCorrection && <ZenManualCorrection userId={userId}/>}
            {!showZenCorrection && (

                <Card.Body>
                    <input
                        type="text"
                        value={filterText}
                        placeholder="Filter data"
                        onChange={(e) => setFilterText(e.target.value)}
                        style={{ width: "20em;", margin: "0 1em 1em 0" }}
                    />
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr style={thStyle}>
                            {['ID', 'State', 'Friend', 'Role','ProcessDate', 'Invoice', 'Extra', 'Balance(E)', 'Basic', 'Balance(B)', 'Proposal', 'Reason', 'Status', 'Type', 'Code', 'Memo', 'Update'].map((header, index) => (
                                <th key={index} style={thStyle}>{header}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((item: any) => (
                            <tr key={item.Id}>
                                {Object.entries({
                                    Id: item.Id,
                                    ProcessState: `${item.ProcessState.Name} (${item.ProcessState.Value})`,
                                    Friend: (
                                        item.FriendId && item.FriendId > 0
                                            ? <Link to={`/user/${item.FriendId}`}
                                                    style={{ display: 'block', textAlign: 'center', verticalAlign: 'center' }}>
                                                <div style={{ fontSize: '1.4em' }}>
                                                    {item.FriendId}
                                                </div>
                                            </Link>
                                            : <div style={{ display: 'block', textAlign: 'center', verticalAlign: 'center', fontSize: '1.4em' }}>
                                                -
                                            </div>
                                    ),
                                    Role: `${item.Role.Name} (${item.Role.Value})`,
                                    ProcessDate: item.ProcessDate ? moment(item.ProcessDate).format("DD-MM-YYYY HH:mm:ss") : "[empty]",
                                    Invoice: item.Invoice ? item.InvoiceIdentifier : '-',
                                    Extra: item.Points.Extra ? item.Points.Extra : 0,
                                    ExtraBalance: item.Balance.Extra ? item.Balance.Extra : 0,
                                    Basic: item.Points.Basic ? item.Points.Basic : 0,
                                    BasicBalance: item.Balance.Basic ? item.Balance.Basic : 0,
                                    Proposal: item.Points.Proposal ? item.Points.Proposal : 0,
                                    Reason: `${item.Reason.Name} (${item.Reason.Value})`,
                                    Status: `${item.Status.Name} (${item.Status.Value})`,
                                    Type: `${item.Type.Name} (${item.Type.Value})`,
                                    Code: item.InvitationCode || '-',
                                    Memo: item.Memo || '-',
                                    UpdatedAt: moment(item.UpdatedAt!).format("DD-MM-YYYY HH:mm:ss"),
                                }).map(([key, value], index) => (
                                    <td key={index} style={tdStyle}>
                                        {key === 'Invoice' && value !== '-' ? (
                                            <Button
                                                variant='secondary'
                                                size={'sm'}
                                                onClick={() => onInvoiceClick(value as string)}
                                            >
                                                {value}
                                            </Button>
                                        ) : (
                                            value
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div>
                        <Button onClick={prevPage}>Prev</Button> &nbsp; <Button onClick={nextPage}>Next</Button>
                    </div>
                </Card.Body>)}
        </Card>
    );
};
