import React from 'react';
import {Container, Image} from "react-bootstrap";
import notfound from "../theme/image/notfound.gif";

export const NotFoundPage = () => {
    return (
        <Container>
            <h1>Not found</h1>
            <Image srcSet={notfound} alt="not found"/>
        </Container>
    );
};



