import React, {useCallback, useMemo} from 'react';
import moment from "moment";
import {Container, Card, Table, ListGroup} from "react-bootstrap";
import {Daemon, DaemonInterval, useReadDaemonsQuery} from "../types/graphql";
import {Loading} from "../component/Loading";
import {Error} from "../component/Error";

export default () => {

    const {data, loading, error} = useReadDaemonsQuery()

    const getIntervalItem = useCallback((i: DaemonInterval) => {
        if (i.value > 1) {
            return <ListGroup.Item>Every {i.value} {i.key}s</ListGroup.Item>;
        }
        return <ListGroup.Item>Every {i.key}</ListGroup.Item>;
    }, []);

    const getTimeItem = useCallback((times: Daemon['times']) => {
        let nextTimeIndex: number = -1;

        return times?.map((t, i) => {

            const time = moment.utc(t?.toString(), "HH:mm");
            let variant: "light" | "success" = "light";
            let waitingTime = null;

            // Check if item has already a next badge and if the time is after now
            if (nextTimeIndex === -1 && time.isAfter()) {
                nextTimeIndex = i;
                variant = "success";
                waitingTime = <span className="text-muted"><br/><i>{time.fromNow()}</i></span>;
            }

            return (
                <ListGroup.Item key={i} variant={variant}>
                    {time.toDate().toLocaleTimeString()}
                    {waitingTime}
                </ListGroup.Item>
            );

        });
    }, []);

    const rows = useMemo(() => {
        if (loading || error) return null;
        const daemons = data?.result || []
        return daemons.map((d, i) => {

            let item;
            if (d?.times) {
                item = getTimeItem(d?.times)
            } else if (d?.interval) {
                item = getIntervalItem(d.interval)
            }

            return (
                <tr key={i}>
                    <td>{d?.name}</td>
                    <td>{d?.description}</td>
                    <td>
                        <ListGroup>
                            {item}
                        </ListGroup>
                    </td>
                </tr>
            );

        });
    }, [data, error, getIntervalItem, getTimeItem, loading])

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h5 className="dark">Daemons</h5>
                </Card.Header>
                <Card.Body>
                    <Loading isLoading={loading}/>
                    <Error error={error?.graphQLErrors[0].message}/>
                    <Table responsive bordered striped hover size="sm">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>When</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );

};
