import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Card, Container, Row, Col, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReadTranslationCodes } from "../graphql/translations";
import {
  ReadTranslationCodesQuery,
  ReadTranslationCodesQueryVariables,
  TranslationCode,
} from "../types/graphql";
import { Loading } from "../component/Loading";
import { FormSearchByString } from "../component/FormSearchByString";
import { Error } from "../component/Error";
import TranslationCodeOverview from "../component/TranslationCode";

const TranslationsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCode, setSelectedCode] = useState<TranslationCode | null>(
    null
  );
  const [isCreationMode, setCreationMode] = useState(false);

  const { data, error, loading, fetchMore } = useQuery<
    ReadTranslationCodesQuery,
    ReadTranslationCodesQueryVariables
  >(ReadTranslationCodes, {
    variables: {
      searchTerm,
    },
    fetchPolicy: "cache-and-network",
  });

  const itemsToRender = useMemo(
    () =>
      data?.translationCodes?.edges?.map((edge) => (
        <tr
          key={edge?.node?.id}
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedCode(edge?.node as TranslationCode)}
        >
          <td>{edge?.node?.code}</td>
        </tr>
      )),
    [data]
  );

  const loadMoreOnScroll = async () => {
    await fetchMore({
      variables: {
        after: data?.translationCodes?.pageInfo?.endCursor,
      },
    });
  };

  const closeModal = () => {
    setSelectedCode(null);
    setCreationMode(false);
  };

  return (
    <Container>
      <Error error={error?.graphQLErrors[0].message} />
      <Card>
        <Card.Header>
          <h5 className="dark">Translations</h5>
        </Card.Header>
        <Card.Header>
          <Row>
            <Col>
              <FormSearchByString
                placeholder="Search by code"
                onSubmit={setSearchTerm}
              />
            </Col>
            <Col sm="auto">
              <Button onClick={() => setCreationMode(true)}>
                Create a translation
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <InfiniteScroll
            hasMore={data?.translationCodes?.pageInfo.hasNextPage ?? false}
            loader={<Loading isLoading />}
            dataLength={data?.translationCodes?.edges?.length ?? 0}
            next={loadMoreOnScroll}
          >
            <Table striped hover>
              <tbody>{itemsToRender}</tbody>
            </Table>
          </InfiniteScroll>
        </Card.Body>
      </Card>
      <TranslationCodeOverview
        code={selectedCode}
        isCreationMode={isCreationMode}
        onHide={closeModal}
      />
    </Container>
  );
};

export default TranslationsPage;
