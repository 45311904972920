import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Loading } from "./Loading";
import { useLazyQuery } from "@apollo/client";
import { Error } from "./Error";
import { AdminReadInvoiceById } from "../graphql/invoiceTool";
import { ComponentEnum, log, logT } from "../log";
import { useSendPushMessageToUserMutation } from "../types/graphql";
import { AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables } from "../types/graphql";

interface IProps {
    customerUid: string;
    pushUserId: string;
    invoiceId: string;
    userName: string;
    onHide: () => void;
}

const Component = ComponentEnum.ModalSendInvoicePush;

export const ModalSendInvoicePush = ({ customerUid, pushUserId, invoiceId, userName, onHide }: IProps) => {

    const [readInvoiceById, {
        data: invoiceData,
        loading: invoiceReadLoading,
        error: invoiceReadError,
    }] = useLazyQuery<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>(AdminReadInvoiceById);

    const [message, setMessage] = useState('');
    const [editableHeader, setEditableHeader] = useState(``);
    const [supportinfo, setSupportinfo] = useState('');

    useEffect(() => {
        readInvoiceById({ variables: { invoiceId:invoiceId } });
    }, []);

    useEffect(() => {
        if(!invoiceData?.invoice) return;
        log(logT.INFO, Component, 'invoiceData', JSON.stringify(invoiceData.invoice, null, 2));
        if (!invoiceReadError && invoiceData?.invoice) {
            let i = invoiceData?.invoice;
            setEditableHeader(`Feil KID ${i?.reference}`);
            setSupportinfo(` ${invoiceId} from ${i?.issuer?.name}.`)
            setMessage(`⚠️ Faktura pålydende kr. ${i?.lastTransaction?.amount},- stoppet i remittering pga feil/ingen kid.nr. Ta snarest kontakt med support@billkill.no med riktig kid.nr slik at beløpet kan videreføres mottaker.`);
        }
    }, [invoiceData]);

    const [SendPushMessageToUser, { loading, error }] = useSendPushMessageToUserMutation();
    const [generalError, setGeneralError] = useState<string | null>(null);

    const sendPushMessage = async () => {
        try {
            const result = await SendPushMessageToUser({
                variables: {
                    userId: pushUserId,
                    invoiceIdentifier: invoiceId,
                    message,
                    header: editableHeader
                },
            });
            if (result?.data?.result?.isOk) {
                setGeneralError(null);
                onHide();
            } else {
                setGeneralError(`Error sending push message for invoice ${invoiceId}.`);
            }
        } catch (e) {
            setGeneralError(`Error sending push message for invoice ${invoiceId}: ${e?.message || e}`);
        }
    };


    return (
        <Modal show onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Push to {userName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Error error={error?.graphQLErrors[0].message}/>
                <Error error={invoiceReadError?.graphQLErrors[0].message}/>
                <Error error={generalError || ''}/>

                <Loading isLoading={loading || invoiceReadLoading}/>
                <Form>
                    <Form.Group>
                        <Form.Label>Header</Form.Label>
                        <Form.Control type="text" value={editableHeader}
                                      onChange={(e) => setEditableHeader(e.target.value)}/>
                        <Form.Label>Content {message.length}/180</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            maxLength={180}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={sendPushMessage} disabled={!message}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
