import React from 'react';
import {Container} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {OfferCategoryDetail} from "../component/OfferCategoryDetail";

export const OfferCategoryCreationPage = () => {

    const history = useHistory();

    return (
        <Container>
            <OfferCategoryDetail createMode={true}
                onCreated={() => {
                    history.goBack();
                }}
            />
        </Container>
    );
}


