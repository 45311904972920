import React, {useMemo} from 'react';
import {Badge} from "react-bootstrap";

interface IProps {
    isOk: boolean;
    text?: string;
    textOnTrue?: string;
    textOnFalse?: string;
}

export const BadgeBool = ({isOk, text, textOnTrue, textOnFalse}: IProps) => {

    const txt = useMemo(() => {
        return (isOk) ? textOnTrue : textOnFalse;
    }, [isOk, textOnTrue, textOnFalse]);

    const variant = useMemo(() => {
        return (isOk) ? "success" : "danger";
    }, [isOk]);

    return <Badge variant={variant}>{text || txt}</Badge>;
};
