import React, { useEffect, useMemo, useState } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { StatisticCard } from "./StatisticCard";
import { getChurnData } from "../helper/system";
import { useQuery } from "@apollo/client";
import {
    ReadStatisticsChurn
} from "../graphql/systemTool";
import {
    ChurnStat,
    ReadStatisticsChurnQuery,
    ReadStatisticsChurnQueryVariables,
} from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";
import { StatisticsChart } from "./StatisticsChart";

export const StatisticsChurn = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>(ReadStatisticsChurn);

    const [last, setLast] = useState<string | null>(null);

    useEffect(() => {
        if (data?.churnStats) {
            let lastElement = data?.churnStats.length - 1;
            setLast(data?.churnStats[lastElement]?.Month + " " + data?.churnStats[lastElement]?.Year);
        }
    }, [data])

    const stats = useMemo(() => {
        if (!data?.churnStats) return null;
        let months = data?.churnStats.length;
        let totalCount = data?.churnStats.map((item) => item?.Count || 0).reduce((a, b) => a + b, 0);
        let monthCount = data?.churnStats.length;

        return (
            <>
                <Row>
                    <Col md={6}>
                        <StatisticCard value={monthCount} description={"Months Past"}/>
                    </Col>
                    <Col md={6}>
                        <StatisticCard value={totalCount} description={"Total Churn (last " + months + " months)"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject={"Churn"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getChurnData(data?.churnStats as Array<ChurnStat>)}
                        />
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Churn</h5>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
            <Card.Footer>
                {last && <>Last: {last} (one month delay)</>}
            </Card.Footer>
        </Card>
    );

}
