import React from "react";
import { IChartColorsDataTable, IStatisticsChartData } from "../helper/system";
import Chart from "react-google-charts";
import Spinner from "react-bootstrap/Spinner";

interface IProps {
    type?: "AreaChart" | "ColumnChart" | "BubbleChart"
    subject: string
    axisXLabel: string
    data: IStatisticsChartData
}

interface IColorProps {
    type?: "AreaChart" | "ColumnChart" | "BubbleChart"
    subject: string
    axisXLabel: string
    data: IChartColorsDataTable
}

export const StatisticsChart = ({data, subject, axisXLabel, type = "AreaChart"}: IProps) => {
    return (
        <Chart
            width="100%"
            height="400px"
            chartType={type}
            loader={<Spinner animation={"grow"}/>}
            data={[
                [axisXLabel, ...data.labels],
                ...data.values
            ]}
            options={{
                title: subject,
                hAxis: {title: axisXLabel, titleTextStyle: {color: '#333'}},
                vAxis: {minValue: 0},
                // For the legend to fit, we make the chart area smaller
                chartArea: {width: '60%', height: '60%'},
            }}
        />
    );
};

export const StatisticsColorChart = ({data, subject, axisXLabel, type = "AreaChart"}: IColorProps) => {
    if (!data) return null;
    return (
        <Chart
            width="100%"
            height="400px"
            chartType={type}
            loader={<Spinner animation={"grow"}/>}
            data={data}
            options={{
                title: subject,
                hAxis: {title: axisXLabel, titleTextStyle: {color: '#333'}},
                vAxis: {minValue: 0},
                // For the legend to fit, we make the chart area smaller
                chartArea: {width: '60%', height: '60%'},
                colors: ['#ff5733', 'orange', '#2952a3'], // Define colors here in the order of the series
            }}
        />
    );
};
