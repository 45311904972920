import React, { useEffect } from 'react';
import { Card } from "react-bootstrap";
import {
    CreateOfferCategoryMutation,
    CreateOfferCategoryMutationVariables,
    DeleteOfferCategoryMutation,
    DeleteOfferCategoryMutationVariables,
    OfferCategoryWithTranslations,
    ReadOfferCategoryByIdQuery,
    ReadOfferCategoryByIdQueryVariables,
    UpdateOfferCategoryMutation,
    UpdateOfferCategoryMutationVariables
} from "../types/graphql";
import { MomentDate } from "./MomentDate";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
    ReadOfferCategoryById,
    CreateOfferCategory,
    UpdateOfferCategory,
    DeleteOfferCategory, ReadOfferCategories
} from "../graphql/offer";
import { LoadingPage } from "../page/loading";
import { Error } from "./Error";
import { OfferCategoryForm } from "./OfferCategoryForm";
import { RegionFlag } from "./RegionFlag";
import { ComponentEnum, log, logT } from "../log";

interface IProps {
    categoryId?: string
    onCreated?: () => void
    onUpdated?: () => void
    onDeleted?: () => void
    createMode?: boolean
}

const Component = ComponentEnum.OfferCategoryDetail;

export const OfferCategoryDetail = ({ categoryId, onCreated, onUpdated, onDeleted, createMode = false }: IProps) => {

    useEffect(() => {
        log(logT.INFO, Component, 'Mounting');
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    const [readCategory, {
        data,
        loading: loadingReadCategory,
        error: errReadCategory
    }] = useLazyQuery<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>(ReadOfferCategoryById, {
        fetchPolicy: "network-only"
    })

    const [createCategory, {
        loading: loadingCreateCategory,
        error: errCreateCategory
    }] = useMutation<CreateOfferCategoryMutation, CreateOfferCategoryMutationVariables>(CreateOfferCategory)

    const [updateCategory, {
        loading: loadingUpdateCategory,
        error: errUpdateCategory
    }] = useMutation<UpdateOfferCategoryMutation, UpdateOfferCategoryMutationVariables>(UpdateOfferCategory)

    const [deleteCategory, {
        loading: loadingDeleteCategory,
        error: errDeleteCategory
    }] = useMutation<DeleteOfferCategoryMutation, DeleteOfferCategoryMutationVariables>(DeleteOfferCategory)

    useEffect(() => {
        if (categoryId) {
            readCategory({
                variables: {
                    offerCategoryId: categoryId
                }
            })
        }
    }, [categoryId, readCategory])

    const onCreateOffer = async (args: CreateOfferCategoryMutationVariables) => {
        await createCategory({
            variables: args,
            refetchQueries: [
                { query: ReadOfferCategories }
            ]
        })
        onCreated?.();
    }

    const onUpdateOffer = async (args: UpdateOfferCategoryMutationVariables) => {
        await updateCategory({
            variables: args,
            refetchQueries: [
                { query: ReadOfferCategoryById, variables: { offerCategoryId: categoryId } },
                { query: ReadOfferCategories }
            ]
        })
        onUpdated?.();
    }

    const onDeleteOffer = async (args: DeleteOfferCategoryMutationVariables) => {
        await deleteCategory({
            variables: args,
            refetchQueries: [
                { query: ReadOfferCategories }
            ]
        })
        onDeleted?.();
    }

    if (loadingReadCategory || loadingCreateCategory || loadingUpdateCategory || loadingDeleteCategory) {
        return <LoadingPage/>
    }

    return (
        <Card>

            <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h5 className="dark" style={{ margin: 0 }}>Category</h5>
                <div style={{ alignSelf: 'right' }}>
                    <RegionFlag region={data?.category?.region}/>
                </div>
            </Card.Header>

            <Error error={errReadCategory?.graphQLErrors[0].message}/>
            <Error error={errCreateCategory?.graphQLErrors[0].message}/>
            <Error error={errUpdateCategory?.graphQLErrors[0].message}/>
            <Error error={errDeleteCategory?.graphQLErrors[0].message}/>

            <Card.Body>
                <OfferCategoryForm createMode={createMode}
                                   data={data?.category as OfferCategoryWithTranslations}
                                   onCreate={onCreateOffer}
                                   onUpdate={onUpdateOffer}
                                   onDelete={onDeleteOffer}
                />
            </Card.Body>

            <Card.Footer>
                {
                    data && <MomentDate
                    value={data.category.createdAt}
                    format={"DD MMMM YYYY HH:mm:ss"}
                    tooltipId={"createdAt_" + data.category.id}
                    tooltipFromNow/>
                }
            </Card.Footer>

        </Card>
    );
};
