import React from 'react'
import { Container } from 'react-bootstrap'
import { IndustryCodesOverview } from '../component/IndustryCodesOverview'

export default () => {
  return (
    <Container>
      <IndustryCodesOverview/>
    </Container>
  )
}


