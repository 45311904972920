import React from 'react';
import {useParams} from 'react-router-dom';
import {Container} from "react-bootstrap";
import {InvoicesOverview} from "../component/InvoicesOverview";

interface IParams {
    userId: string;
}

export default () => {

    const {userId} = useParams<IParams>();

    return (
        <div className="InvoicesPage">
            <Container className='userContainer' >
                <InvoicesOverview userId={userId}/>
            </Container>
        </div>
    );

}

