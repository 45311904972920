import React from 'react';
import {useParams} from 'react-router-dom';
import {Container, Card} from "react-bootstrap";
import {InvoiceHistory} from "../component/InvoiceHistory";

interface IParams {
    id: string
}

export default () => {

    const {id: invoiceId} = useParams<IParams>();

    return (
        <div className="InvoicePage">
            <Container fluid>
                <h1>Invoice # {invoiceId}</h1>

                <Card>
                    <Card.Body>
                        <InvoiceHistory invoiceId={invoiceId}/>
                    </Card.Body>
                </Card>

            </Container>
        </div>
    );

};
