import React from 'react';
import {Table} from "react-bootstrap";
import {BadgeBool} from "./BadgeBool";
import {UserDetailFragment, UserStatusEnum} from "../types/graphql";
import {MomentDate} from "./MomentDate";
import {getIconFromPEPStatus} from "../helper/user";
import moment from "moment";

interface IProps {
    user: UserDetailFragment;
}

export const UserDetail = ({user}: IProps) => {

    // Address constants
    const address = user?.address?.street;
    const postalCode = user?.address?.postalCode;
    const city = user?.address?.city;
    const country = user?.address?.country?.code;

    return (
        <>
            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Birthdate</th>
                    <th>Email</th>
                    <th>EmailCheck</th>
                    <th>Mobile</th>
                    <th>MobileCheck</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{moment(user?.birthdate).format("YYYY-MM-DD")}</td>
                    <td>{user.email}</td>
                    <td><BadgeBool isOk={user.emailCheck} textOnFalse="not checked" textOnTrue="checked"/></td>
                    <td>{user.mobile}</td>
                    <td><BadgeBool isOk={user.mobileCheck} textOnFalse="not checked" textOnTrue="checked"/></td>
                    <td>
                        <BadgeBool isOk={user.status === UserStatusEnum.Enabled} text={user.status}/>
                    </td>
                </tr>
                </tbody>
            </Table>

            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>PEP</th>
                    <th>Address</th>
                    <th>Registration date</th>
                    <th>Last update date</th>
                    <th>Deletion date</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {getIconFromPEPStatus(user.permission.pep)}
                    </td>
                    <td>
                        {address !== '' && <p>{address}</p>}
                        {postalCode !== '' && city !== '' && <p>{`${postalCode}, ${city}`}</p>}
                        {country !== '' && <p>{country}</p>}
                    </td>
                    <td>
                        <MomentDate
                            value={user.createdAt}
                            format={"DD MMMM YYYY HH:mm:ss"}
                            tooltipId={"createdAt_" + user.id}
                            tooltipFromNow/>
                    </td>
                    <td>
                        <MomentDate
                            value={user.updatedAt}
                            format={"DD MMMM YYYY HH:mm:ss"}
                            tooltipId={"updatedAt_" + user.id}
                            tooltipFromNow/>
                    </td>
                    <td>
                        <MomentDate
                            value={user.deletedAt}
                            format={"DD MMMM YYYY HH:mm:ss"}
                            tooltipId={"deletedAt_" + user.id}
                            tooltipFromNow/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

