import {
    ChurnStat,
    HistogramAccess,
    HistogramInvoicesCountCumulative,
    HistogramInvoicesPaidAmount,
    HistogramIssuersRegistration, HistogramIssuersRegistrationCumulative, HistogramSubscriptionsOverview,
    HistogramUsersRegistration,
} from "../types/graphql";
import moment from "moment";

export interface IStatisticsChartData {
    labels: Array<any>
    values: Array<Array<any>>
}

// Define a type for the style object
interface StyleObject {
    role: string;
    p?: { [key: string]: any };
}

export type IChartColorsLabelsRow = Array<string | StyleObject>;
export type IChartColorsDataTable = Array<IChartColorsLabelsRow | (number | string)[]> | null;

function prepareTrippelChartData(data: Array<HistogramInvoicesPaidAmount>, labelPrefix: string, dataField: 'amount' | 'count'): IChartColorsDataTable {

    //We aim for this type of data:
    // [["Month", "Amount 2022", { "role": "style" }, "Amount 2023", { "role": "style" }, "Amount 2024", { "role": "style" }],
    //     ["Jan", 2000000, "color: #ff5733", 2200000, "color: orange", 2500000, "color: #2952a3"],
    //     ["Feb", 1800000, "color: #ff5733", 2000000, "color: orange", 2300000, "color: #2952a3"],
    //     ["Mar", 2100000, "color: #ff5733", 2300000, "color: orange", 2600000, "color: #2952a3"],
    //     ["Apr", 1600000, "color: #ff5733", 2400000, "color: orange", 2700000, "color: #2952a3"],
    // ...
    // ]

    let sortedYears = [...new Set(data.map(item => moment(item.time).year()))].sort((a, b) => a - b);
    let sortedMonths = [...new Set(data.map(item => moment(item.time).month()))].sort((a, b) => a - b);
    let monthColors = ['color: #ff5733', 'color: orange', 'color: #2952a3'];

    if (sortedYears.length !== 3) {
        return null;
    }

    let labels: IChartColorsLabelsRow = ["Month"];
    sortedYears.forEach(year => {
        labels.push(`${labelPrefix} ${year}`);
        labels.push({ role: 'style', p: { role: 'style' } });
    });

    let values = sortedMonths.map(month => {
        let row = sortedYears.flatMap((year, index) => {
            let monthYear = data.find(d => moment(d.time).month() === month && moment(d.time).year() === year);
            let value = monthYear ? monthYear[dataField] : 0;
            return [value, monthColors[index]];
        });
        //Like ["Jan", 2000000, "color: #ff5733", 2200000, "color: orange", 2500000, "color: #2952a3"]
        return [moment().month(month).format('MMM'), ...row.flat()];
    });

    return [labels, ...values];
}

export const getTripleColorTrendInvoiceAmount = (data: Array<HistogramInvoicesPaidAmount>): IChartColorsDataTable => {
    return prepareTrippelChartData(data, "Amount", "amount");
};

export const getTripleColorTrendInvoiceCount = (data: Array<HistogramInvoicesPaidAmount>): IChartColorsDataTable => {
    return prepareTrippelChartData(data, "Transactions", "count");
};

export const getTrendInvoiceWithAmount = (a: Array<HistogramInvoicesPaidAmount>): IStatisticsChartData => {
    const labels = ["Amount"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const amounts = a.map(i => i.amount)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], amounts[i]]
        })
    }
};

export const getTrendInvoiceWithCount = (a: Array<HistogramInvoicesPaidAmount>): IStatisticsChartData => {
    const labels = ["Transactions"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const counts = a.map(i => i.count)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i]]
        })
    }
};

export const getTrendInvoiceWithCountCumulative = (a: Array<HistogramInvoicesCountCumulative>): IStatisticsChartData => {
    const labels = ["Invoices", "Paid"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const counts = a.map(i => i.count)
    const paidCounts = a.map(i => i.paidCount)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i], paidCounts[i]]
        })
    }
};

// Issuers

export const getTrendIssuerRegistration = (a: Array<HistogramIssuersRegistration>): IStatisticsChartData => {
    const labels = ["Issuers"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const counts = a.map(i => i.count)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i]]
        })
    }
};

export const getTrendIssuerRegistrationCumulative = (a: Array<HistogramIssuersRegistrationCumulative>): IStatisticsChartData => {
    const labels = ["Issuers", "With logo", "With support"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const counts = a.map(i => i.count)
    const logoCounts = a.map(i => i.logoCount)
    const supportCounts = a.map(i => i.supportCount)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i], logoCounts[i], supportCounts[i]]
        })
    }
};

// Users

export const getTrendUserRegistration = (a: Array<HistogramUsersRegistration>): IStatisticsChartData => {
    const labels = ["Users"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const counts = a.map(i => i.count)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i]]
        })
    }
};

export const getTrendUserRegistrationCumulative = (a: Array<HistogramUsersRegistration>): IStatisticsChartData => {
    const labels = ["Age"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const ageAverages = a.map(i => i.ageAverage)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], ageAverages[i]]
        })
    }
};

export const getTrendUserRegistrationCumulativeMultiple = (a: Array<HistogramUsersRegistration>): IStatisticsChartData => {
    const labels = ["Total", "Active", "Inactive", "Deleted"];
    const times = a.map(i => moment(i.time).format("MMM YYYY"))
    const count = a.map(i => i.count)
    const activeCount = a.map(i => i.activeCount)
    const inactiveCount = a.map(i => i.inactiveCount)
    const deletedCount = a.map(i => i.deletedCount)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], count[i], activeCount[i], inactiveCount[i], deletedCount[i]]
        })
    }
};

// Access

export const getTrendAccess = (a: Array<HistogramAccess>): IStatisticsChartData => {
    return {
        labels: ["Total", "PIN/Biometrics", "Logouts", "Login via BankId without signature", "Login via BankId signature"],
        values: a.map((data) => {
            return [
                moment(data.time).format("MMM YYYY"),
                data.count,
                data.count - (data.countLogout + data.countBankIdSignature + data.countBankIdSession),
                data.countLogout,
                data.countBankIdSession,
                data.countBankIdSignature
            ]
        })
    }
};

export const getTrendAccessByDevices = (a: Array<HistogramAccess>): IStatisticsChartData => {
    return {
        labels: ["iOS", "Android"],
        values: a.map((data) => {
            return [
                moment(data.time).format("MMM YYYY"),
                data.countDeviceIos,
                data.countDeviceAndroid
            ]
        })
    }
};

// Subscriptions

export const getTrendSubscriptions = (a: Array<HistogramSubscriptionsOverview>): IStatisticsChartData => {
    return {
        labels: ["New", "Active", "Refunded"],
        values: a.map((data) => {
            return [
                moment(data.time).format("MMM YYYY"),
                data.count,
                data.activeCount,
                data.refundedCount,
            ]
        })
    }
};

// Churn
export const getChurnData = (a: Array<ChurnStat>): IStatisticsChartData => {
    const labels = ["Churn"];
    const times = a.map(i => i.Month + " " + i.Year)
    const counts = a.map(i => i.Count)
    return {
        labels: labels,
        values: a.map((_, i) => {
            return [times[i], counts[i]]
        })
    }
};

// Misc
export function FormatDateAsISO(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
}

export type RegionType = 'no' | 'sv';

export const GetRegionEmoji = (region?: string): string => {
    const regionMap: { [key: string]: string } = {
        'no': '🇳🇴', // Norway
        'sv': '🇸🇪', // Sweden
    };
    const key = region?.toLowerCase();
    return key && regionMap[key] ? regionMap[key] : '';
};

