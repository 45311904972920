import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Button, Card } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
    ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables, OfferCategoryNewPosition,
    OfferCategoryWithTranslations,
    ReadOfferCategoriesQuery,
    ReadOfferCategoriesQueryVariables,
} from "../types/graphql";
import { Error } from "./Error";
import { ArrangeOfferCategories, ReadOfferCategories } from "../graphql/offer";
import { LoadingPage } from "../page/loading";
import { useHistory } from "react-router-dom";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DraggingStyle,
    NotDraggingStyle
} from "react-beautiful-dnd";
import { colors } from "../theme";
import { RegionFlag } from "./RegionFlag";
import { ComponentEnum, log, logT } from "../log";

const grid = 8;

const Component = ComponentEnum.OfferCategoriesOverview;

export const OfferCategoriesOverview = () => {

    const history = useHistory();
    const [categories, setCategories] = useState<Array<OfferCategoryWithTranslations>>([]);

    useEffect(() => {
        log(logT.INFO, Component, 'Mounting');
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    const {
        data: dataCategories,
        loading: loadingCategories,
        error: errCategories
    } = useQuery<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>(ReadOfferCategories);

    const [updatePositions, {
        error: errArrange
    }] = useMutation<ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables>(ArrangeOfferCategories)

    useEffect(() => {
        if (dataCategories?.categories) {
            log(logT.INFO, Component, 'dataCategories:', dataCategories);
            setCategories(dataCategories.categories as Array<OfferCategoryWithTranslations>)
        }
    }, [dataCategories])

    const onItemClick = useCallback((id: string) => {
        history.push(`/ninja-power/categories/${id}`)
    }, [history])

    const onItemCreate = useCallback(() => {
        history.push({
            pathname: '/ninja-power/category',
            state: { createMode: true }
        });
    }, [history]);


    if (loadingCategories) {
        return <LoadingPage/>
    }

    const reorder = (list: Array<OfferCategoryWithTranslations>, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const updatedCategories = reorder(
            categories,
            result.source.index,
            result.destination.index
        )

        setCategories(updatedCategories);

        const newPositions: Array<OfferCategoryNewPosition> = updatedCategories.map((v, i) => ({
            offerCategoryId: v.id,
            position: i
        }))

        await updatePositions({
            variables: {
                positions: newPositions
            }
        })
    }

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): CSSProperties => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: colors.white,
        borderRadius: grid,
        borderWidth: isDragging ? 1 : 0,
        borderStyle: 'solid',
        borderColor: colors.info,

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <Card>
            <Card.Header>
                <h5 className="dark">Categories</h5>
            </Card.Header>
            <Card.Header>
                <Button variant={'primary'} onClick={onItemCreate}>Create new category</Button>
            </Card.Header>
            <Card.Body>

                <Error error={errCategories?.graphQLErrors[0].message}/>
                <Error error={errArrange?.graphQLErrors[0].message}/>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {categories.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                key={index}
                                                onClick={() => onItemClick(item.id)}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>

                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    {item.nameEn}
                                                    <div style={{ alignSelf: 'right' }}>
                                                        <RegionFlag region={item.region} hideText normalSize/>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </Card.Body>
        </Card>
    );
};
