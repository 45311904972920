import React, {useContext} from "react";
import {getClient} from "../client/client";
import {ApolloProvider} from "@apollo/client";
import {AuthContext} from "./auth";

interface IProps {
    children: any
}

export const CustomApolloProvider = ({children}: IProps) => {
    const {setAuthenticated} = useContext(AuthContext)
    const cli = getClient('/graphql', setAuthenticated);
    return <ApolloProvider client={cli}>
        {children}
    </ApolloProvider>
}