import React, {useMemo} from 'react';
import moment from "moment";
import Chart from 'react-google-charts';
import {useQuery} from "@apollo/client";
import {ReadBalanceForecasting} from "../graphql/invoiceTool";
import {ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables} from "../types/graphql";
import {Error} from "./Error";
import {LoadingPage} from "../page/loading";

export const BufferForecasting = () => {

    const {data, loading, error} = useQuery<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>(ReadBalanceForecasting)

    const fetchedData = useMemo(() => {
        if ((loading || error) && !data) return [];
        const forecasting = data?.result || []
        return forecasting.map((x) => {
            return [moment(x?.time).format("DD MMMM YYYY"), x?.buffer, x?.incoming, x?.outgoing];
        });
    }, [data, error, loading]);

    if (loading) return <LoadingPage variant={'secondary'}/>;
    if (error) return <Error error={error.graphQLErrors[0].message}/>;

    if (fetchedData.length === 0) {
        return <Error error="Please, add a new balance snapshot with the most recent update date (max 30 days)."/>;
    }

    const chartData = [['Time', 'Buffer', 'Incoming', 'Outgoing'], ...fetchedData];

    return (
        <Chart
            chartType="ColumnChart"
            loader={<LoadingPage/>}
            width="100%"
            height="500px"
            data={chartData}
            options={{
                title: 'Buffer forecasting',
                hAxis: {
                    title: `Next 30 days`,
                    minValue: 0,
                },
                vAxis: {
                    title: 'NOK',
                },
                colors: ["#3498DB", "#45B39D", "#E74C3C"]
            }}
        />
    );

};
