import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

export interface IProps {
  text: string;
  onSubmit: (fromDate: Date, toDate: Date) => void;
}

export const FormBalanceByDates = ({ text, onSubmit }: IProps) => {
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [validated, setValidated] = useState(false);

  const handleChangeFromDate: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    const target = event.currentTarget;
    // const value = target.type === "checkbox" ? target.checked : target.value;
    setFromDate(target.valueAsDate!);
  };

  const handleChangeToDate: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();
    const target = event.currentTarget;
    // const value = target.type === "checkbox" ? target.checked : target.value;
    setToDate(target.valueAsDate!);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isValid = form.checkValidity();

    setValidated(isValid);
    isValid && onSubmit && onSubmit(fromDate, toDate);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Label>{text}</Form.Label>
      <Form.Group as={Row} controlId="form">
        <Col sm={12} md={"auto"}>
          <Form.Control
            required
            type="date"
            placeholder="Enter starting date"
            onChange={handleChangeFromDate}
            name="fromDate"
          />
          <Form.Text className="text-muted">From date</Form.Text>
          <Form.Control.Feedback type="invalid">
            Please provide a valid date.
          </Form.Control.Feedback>
        </Col>
        <Col sm={12} md={"auto"}>
          <Form.Control
            required
            type="date"
            placeholder="Enter ending date"
            onChange={handleChangeToDate}
            name="toDate"
          />
          <Form.Text className="text-muted">To date</Form.Text>
          <Form.Control.Feedback type="invalid">
            Please provide a valid date.
          </Form.Control.Feedback>
        </Col>
        <Col sm={12} md={"auto"}>
          <Button variant="secondary" type="submit">
            Search
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
