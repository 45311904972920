import React from 'react';
import moment from "moment";

interface IProps {
    date: any
}

export const StatisticsLastUpdate = ({date}: IProps) => {

    const getLastUpdate = (d: any) => {
        return d ? moment(d).fromNow() : "N.A."
    }

    return <>Last update: {getLastUpdate(date)}</>
}