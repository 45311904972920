import gql from "graphql-tag";

export const IssuerFragments = {
    result: gql`
        fragment IssuerResult on Issuer {
            id
            name
            orgNumber
            realOrgNumber
            subMerchantMcc
            implicitSubMerchantMcc
            industryCode
            picture
            createdAt
            region
        }
    `,
    detailed: gql`
        fragment IssuerDetail on Issuer {
            id
            name
            orgNumber
            realOrgNumber
            subMerchantMcc
            implicitSubMerchantMcc
            industryCode
            brregStatusDesc
            brregForceUpdate
            picture
            email
            emailLoan
            createdAt
            updatedAt
            deletedAt
            region
            address {
                street
                postalCode
                city
                country {
                    code
                    name
                }
            }
        }
    `,
};

export const IssuerBlockCompleteFragment = gql`
    fragment IssuerBlockComplete on IssuerTransactionRule {
        issuerId
        allowDebitCard
        allowCreditCard
        allowAmexCard
        maxAmountDebitCard
        maxAmountCreditCard
        maxAmountAmexCard
        isCustom
        createdAt
        updatedAt
    }
`
