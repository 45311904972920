export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // @ts-ignore
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const checkPictureUpload = async (files: Array<File>, maxSizeKB: number) => {

    if (files.length === 0) {
        return '';
    }

    if (files.length > 1) {
        throw new Error('Only one picture at a time! 🙌🏻')
    }

    const fileToUpload = files[0];
    if (fileToUpload.type !== 'image/png' && fileToUpload.type !== 'image/jpeg') {
        throw new Error('Picture must be a PNG or a JPEG! 🙌🏻')
    }

    if (fileToUpload.size / 1000 > maxSizeKB) {
        throw new Error('Max size is 100 KB 🙌🏻')
    }

    return await toBase64(fileToUpload);
}