import React, {createContext, useState} from 'react';

export enum AuthState {
    NotAuthenticated,
    Authenticated,
    Expired
}

interface ContextValues {
    authenticated: AuthState
    setAuthenticated: (authenticated: AuthState) => void
}

export const AuthContext = createContext({
    authenticated: AuthState.NotAuthenticated,
    setAuthenticated: () => {
    }
} as ContextValues);

interface IProps {
    children: any
}

export const AuthProvider = ({children}: IProps) => {
    const [authenticated, setAuthenticated] = useState<AuthState>(AuthState.NotAuthenticated)
    return (
        <AuthContext.Provider value={{
            authenticated,
            setAuthenticated
        }}>
            {children}
        </AuthContext.Provider>
    )
}
