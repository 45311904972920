import React, { memo, useCallback, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Error } from "../component/Error";
import { Loading } from "../component/Loading";
import UserGroupOverview from "../component/UserGroupOverview";
import { Maybe, useReadUserGroupsQuery, UserGroup } from "../types/graphql";
import { RegionFlag } from "../component/RegionFlag";

interface Props {}

const UserGroupRow: React.FC<{
  userGroup: UserGroup;
  onClick: (id: UserGroup) => void;
}> = memo((props) => {
  const { userGroup, onClick } = props;
  const handleClick = () => onClick(userGroup);

  return (
    <tr onClick={handleClick} style={{ cursor: "pointer" }}>
      <td>{userGroup.name}</td>
      <td>{userGroup.description}</td>
      <td><RegionFlag region={userGroup.region} noContainerStyle normalSize hideText /></td>
    </tr>
  );
});

const UserGroupsPage = (props: Props) => {
  const { data, error, loading, refetch } = useReadUserGroupsQuery();
  const [selectedUserGroup, setSelectedUserGroup] =
    useState<Maybe<UserGroup>>(null);
  const [isCreationMode, setIsCreationMode] = useState(false);

  const handleClickUserGroup = useCallback((group: UserGroup) => {
    setSelectedUserGroup(group);
  }, []);

  const handleHide = () => {
    setIsCreationMode(false);
    setSelectedUserGroup(null);
    refetch();
  };

  if (loading) return <Loading isLoading={loading} />;

  return (
    <Container>
      <Error error={error?.graphQLErrors[0].message} />
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>User Groups</Card.Title>
            </Col>
            <Col sm="auto">
              <Button onClick={() => setIsCreationMode(true)}>
                Create a user group
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Region</th>
              </tr>
            </thead>
            <tbody>
              {data?.userGroups.length === 0 ? (
                <tr>
                  <td colSpan={2}>
                    <p>No data</p>
                  </td>
                </tr>
              ) : (
                data?.userGroups.map((userGroup) => (
                  <UserGroupRow
                    key={userGroup.id}
                    onClick={handleClickUserGroup}
                    userGroup={userGroup}
                  />
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <UserGroupOverview
        isCreationMode={isCreationMode}
        selectedUserGroup={selectedUserGroup}
        onHide={handleHide}
      />
    </Container>
  );
};

export default UserGroupsPage;
