import React, {useMemo} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import {
    getTrendSubscriptions
} from "../helper/system";
import {useQuery} from "@apollo/client";
import {
    ReadStatisticsSubscriptions
} from "../graphql/systemTool";
import {
    HistogramSubscriptionsOverview,
    ReadStatisticsSubscriptionsQuery,
    ReadStatisticsSubscriptionsQueryVariables,
} from "../types/graphql";
import {Error} from "./Error";
import {Loading} from "./Loading";
import {StatisticsLastUpdate} from "./StatisticsLastUpdate";
import {StatisticsChart} from "./StatisticsChart";
import {StatisticCard} from "./StatisticCard";
import Chart from "react-google-charts";
import {getSubscriptionName} from "../helper/subscription";

export const StatisticsSubscriptions = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>(ReadStatisticsSubscriptions);

    const stats = useMemo(() => {
        if (!data?.statistics) return null;
        const {overview, histograms} = data.statistics;

        return (
            <>
                <Row>
                    <Col md={4}>
                        <StatisticCard value={overview.totalCount} description={"Total subscriptions"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.activeCount} description={"Currently active"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.totalRefundedCount} description={"Total refunded"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject={"Overview"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getTrendSubscriptions(histograms.overview as Array<HistogramSubscriptionsOverview>)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['Subscription', 'Count'],
                                ...overview.activeDistribution?.map(s => [getSubscriptionName(s.subscription), s.count])
                            ]}
                            options={{
                                title: 'Distribution of active subscriptions'
                            }}
                            rootProps={{'data-testid': '1'}}
                        />
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Subscriptions</h5>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
            <Card.Footer>
                <StatisticsLastUpdate date={data?.statistics?.lastUpdate}/>
            </Card.Footer>
        </Card>
    );

}
