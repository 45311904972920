import { gql } from "@apollo/client";
import { TranslationFragments } from "./fragments/translation";

export const ReadLanguages = gql`
  query readLanguages {
    languages: readLanguages {
      id
      code
      name
    }
  }
`;

export const ReadTranslationCodes = gql`
  query readTranslationCodes(
    $searchTerm: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    translationCodes: readTranslationCodes(
      searchTerm: $searchTerm
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          ...TranslationCodeResult
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TranslationFragments.result}
`;

export const ReadTranslation = gql`
  query readTranslation($codeId: ID!, $languageId: ID!) {
    translation: readTranslation(codeId: $codeId, languageId: $languageId) {
      id
      codeId
      languageId
      translation
    }
  }
`;

export const ReadTranslations = gql`
  query readTranslations($codeId: ID!) {
    translations: readTranslations(codeId: $codeId) {
      code {
        id
        code
      }
      translations {
        language {
          id
          code
          name
        }
        translation {
          ...TranslationResult
        }
      }
    }
  }
  ${TranslationFragments.item}
`;

export const UpdateTranslation = gql`
  mutation setTranslation($languageId: ID! $code: String! $translation: String!) {
    translation: setTranslation(languageId: $languageId code: $code translation: $translation) {
      ...TranslationResult
    }
  }
  ${TranslationFragments.item}
`

export const DeleteTranslationsByCode = gql`
  mutation deleteTranslationsByCode($codeId: ID!) {
    translations: deleteTranslationsByCode(codeId: $codeId) {
      id
      code
    }
  }
`