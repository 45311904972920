import React from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {SnapshotsTable} from "../component/SnapshotsTable";

export default () => {

    return (
        <div className="SnapshotsPage">
            <Container>
                <h1>Snapshots</h1>

                <Card>
                    <Card.Body>
                        <h5>List</h5>
                        <SnapshotsTable/>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );

}


