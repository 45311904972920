import { gql } from "@apollo/client";

export const UserGroupFragments = {
  result: gql`
    fragment UserGroupResult on UserGroup {
      id
      name
      description
      region
    }
  `,
};

export const UserGroupMemberFragments = {
  result: gql`
    fragment UserGroupMemberResult on UserGroupMember {
      id
      name
      userId
    }
  `
}
