import { PepStatus, UserPermissionEInvoice, UserPermissionPep, UserResultFragment } from "../types/graphql";

export const getIconFromPEPStatus = (s: UserPermissionPep) => {
	switch (s.status) {
		case PepStatus.NotLiable:
			return '⚪️';
		case PepStatus.Accepted:
			return '✅';
		case PepStatus.Rejected:
			return '❌';
		case PepStatus.Pending:
			return '⏳';
	}
}

export const getIconFromEInvoice = (p: UserPermissionEInvoice) => {
	return p.acceptAllIssuers ? '✅' : '❌'
}

export const getIconFromEmailCheck = (isEmailCheck: boolean) => {
	return isEmailCheck ? '✅' : '❗'
}

export const getUserName = (user: UserResultFragment) => {
	return `${user.firstname} ${user.lastname}`
}

export const getUserNameForCheck = (user: UserResultFragment, prefix: string) => {
	return `${prefix}_${user.firstname}_${user.lastname}`.toUpperCase()
}
