import React, { useEffect, useState } from 'react';
import { Alert, Button, Card } from "react-bootstrap";
import {
    CreateOfferMutation,
    CreateOfferMutationVariables,
    DeleteOfferMutation,
    DeleteOfferMutationVariables,
    OfferWithTranslations,
    ReadOfferByIdQuery,
    ReadOfferByIdQueryVariables,
    UpdateOfferMutation,
    UpdateOfferMutationVariables
} from "../types/graphql";
import { MomentDate } from "./MomentDate";
import { OfferForm } from "./OfferForm";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
    CreateOffer,
    DeleteOffer,
    ReadOffers,
    ReadOfferById,
    UpdateOffer
} from "../graphql/offer";
import { LoadingPage } from "../page/loading";
import { Error } from "./Error";
import { OfferPreview } from "./OfferPreview";
import { ComponentEnum, log, logT } from "../log";
import { RegionFlag } from "./RegionFlag";

interface IProps {
    offerId?: string
    onCreatedOffer?: () => void
    onUpdatedOffer?: () => void
    onDeletedOffer?: () => void
}

const Component = ComponentEnum.OfferDetail;

export const OfferDetail = ({ offerId, onCreatedOffer, onUpdatedOffer, onDeletedOffer }: IProps) => {

    useEffect(() => {
        log(logT.INFO, Component, 'Mounting with offerId:', offerId);
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    const [showPreview, setShowPreview] = useState(false);

    const [readOffer, {
        data,
        loading: loadingReadOffer,
        error: errReadOffer
    }] = useLazyQuery<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>(ReadOfferById, {
        fetchPolicy: "network-only"
    })

    useEffect(() => {
        log(logT.INFO, Component, 'Offer data:', data);
    }, [data]);

    const [createOffer, {
        loading: loadingCreateOffer,
        error: errCreateOffer
    }] = useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOffer)

    const [updateOffer, {
        loading: loadingUpdateOffer,
        error: errUpdateOffer
    }] = useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(UpdateOffer)

    const [deleteOffer, {
        loading: loadingDeleteOffer,
        error: errDeleteOffer
    }] = useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOffer)

    useEffect(() => {
        if (offerId) {
            readOffer({
                variables: {
                    offerId
                }
            })
        }
    }, [offerId, readOffer])

    const onCreateOffer = async (args: CreateOfferMutationVariables) => {
        await createOffer({
            variables: args,
            refetchQueries: [
                { query: ReadOffers }
            ]
        })
        onCreatedOffer?.();
    }

    const onUpdateOffer = async (args: UpdateOfferMutationVariables) => {
        await updateOffer({
            variables: args,
            refetchQueries: [
                { query: ReadOfferById, variables: { offerId } },
                { query: ReadOffers }
            ]
        }).then((offer) => {
            console.log('Offer updated:', offer);
        })
        onUpdatedOffer?.();
    }

    const onDeleteOffer = async (args: DeleteOfferMutationVariables) => {
        await deleteOffer({
            variables: args,
            refetchQueries: [
                { query: ReadOffers }
            ]
        })
        onDeletedOffer?.();
    }

    if (loadingReadOffer || loadingCreateOffer || loadingUpdateOffer || loadingDeleteOffer) {
        return <LoadingPage/>
    }

    return (
        <Card>

            <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h5 className="dark" style={{ margin: 0 }}>Offer</h5>
                <div style={{ alignSelf: 'right' }}>
                    <RegionFlag region={data?.offer?.category?.region}/>
                </div>
            </Card.Header>

            <Error error={errReadOffer?.graphQLErrors[0].message}/>
            <Error error={errCreateOffer?.graphQLErrors[0].message}/>
            <Error error={errUpdateOffer?.graphQLErrors[0].message}/>
            <Error error={errDeleteOffer?.graphQLErrors[0].message}/>

            <Card.Header>
                {
                    data?.offer ?
                        <>
                            <p>The preview is generated using only <strong>saved information</strong>.</p>
                            <Button
                                variant={'secondary'}
                                style={{ marginBottom: '1em' }}
                                onClick={() => setShowPreview(!showPreview)}>
                                Toggle preview
                            </Button>
                            {showPreview && <OfferPreview offer={data.offer}/>}
                        </>
                        :
                        <Alert variant={'warning'}>
                            <ul>
                                <li>New offers are created as inactive in order to prevent possible errors due to a lack
                                    of
                                    revision.
                                </li>
                                <li>You can add services to an offer after its creation.</li>
                            </ul>
                        </Alert>
                }
            </Card.Header>

            <Card.Body>

                <OfferForm
                    data={data?.offer as OfferWithTranslations}
                    onCreate={onCreateOffer}
                    onUpdate={onUpdateOffer}
                    onDelete={onDeleteOffer}
                />
            </Card.Body>

            <Card.Footer>
                {
                    data && <MomentDate
                    value={data.offer.createdAt}
                    format={"DD MMMM YYYY HH:mm:ss"}
                    tooltipId={"createdAt_" + data.offer.id}
                    tooltipFromNow/>
                }
            </Card.Footer>

        </Card>
    );
};
