import React, {useMemo} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import {
    getTrendAccess, getTrendAccessByDevices
} from "../helper/system";
import {useQuery} from "@apollo/client";
import {
    ReadStatisticsAccess
} from "../graphql/systemTool";
import {
    HistogramAccess,
    ReadStatisticsAccessQuery,
    ReadStatisticsAccessQueryVariables,
} from "../types/graphql";
import {Error} from "./Error";
import {Loading} from "./Loading";
import {StatisticsLastUpdate} from "./StatisticsLastUpdate";
import {StatisticsChart} from "./StatisticsChart";

export const StatisticsAccess = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>(ReadStatisticsAccess);

    const stats = useMemo(() => {
        if (!data?.statistics) return null;
        const {histograms} = data.statistics;
        return (
            <>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject={"Access by mode"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getTrendAccess(histograms.access as Array<HistogramAccess>)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject={"Access by device"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getTrendAccessByDevices(histograms.access as Array<HistogramAccess>)}
                        />
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Access</h5>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
            <Card.Footer>
                <StatisticsLastUpdate date={data?.statistics?.lastUpdate}/>
            </Card.Footer>
        </Card>
    );

}
