import React, { useMemo } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { StatisticCard } from "./StatisticCard";
import {
    getTripleColorTrendInvoiceAmount,
    getTripleColorTrendInvoiceCount,
    getTrendInvoiceWithCountCumulative,
} from "../helper/system";
import { useQuery } from "@apollo/client";
import {
    ReadStatisticsInvoices
} from "../graphql/systemTool";
import {
    HistogramInvoicesCountCumulative,
    HistogramInvoicesPaidAmount,
    ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables
} from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";
import { StatisticsLastUpdate } from "./StatisticsLastUpdate";
import { StatisticsChart, StatisticsColorChart } from "./StatisticsChart";

export const StatisticsInvoices = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>(ReadStatisticsInvoices);

    const stats = useMemo(() => {
        if (!data?.statistics) return null;
        const { overview, histograms } = data.statistics;
        return (
            <>
                <Row>
                    <Col md={4}>
                        <StatisticCard
                            value={overview.totalCount}
                            description="Invoices traffic"/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard
                            value={overview.totalPaidCount}
                            description="Paid invoices"/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard
                            value={overview.totalAmount}
                            isMoney
                            description="Money transferred"/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={12}>
                        <StatisticsColorChart
                            subject="Paid amount per month"
                            axisXLabel="Month"
                            type={"ColumnChart"}
                            data={getTripleColorTrendInvoiceAmount(histograms.paidAmount as Array<HistogramInvoicesPaidAmount>)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <StatisticsColorChart
                            subject="Number of transactions per month"
                            axisXLabel="Month"
                            type={"ColumnChart"}
                            data={getTripleColorTrendInvoiceCount(histograms.paidAmount as Array<HistogramInvoicesPaidAmount>)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject="Cumulative number of invoices and transactions"
                            axisXLabel="Month"
                            data={getTrendInvoiceWithCountCumulative(histograms.cumulativeCount as Array<HistogramInvoicesCountCumulative>)}/>
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Invoices last 3 years</h5>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
            <Card.Footer>
                <StatisticsLastUpdate date={data?.statistics?.lastUpdate}/>
            </Card.Footer>
        </Card>
    );

}
