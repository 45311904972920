import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
    IndustryCodeResultFragment,
    ReadAllIndustryCodesQuery,
    ReadAllIndustryCodesQueryVariables,
    RegionEnum,
    useSetMerchantCategoryCodeMutation,
} from '../types/graphql'
import { ReadIndustryCodes } from '../graphql/industryCode'
import { Error } from './Error'
import { Loading } from './Loading'
import Select, { ValueType } from 'react-select'
import { RegionFlag } from "./RegionFlag";
import { FormSearchByString } from "./FormSearchByString";
import { ComponentEnum, log, logT } from "../log";

type OptionType = {
    value: 'noMcc' | RegionEnum.No | RegionEnum.Sv;
    label: string;
};

const filterOptions: OptionType[] = [
    { value: 'noMcc', label: 'No MCC' },
    { value: RegionEnum.Sv, label: 'Sweden' },
    { value: RegionEnum.No, label: 'Norway' },
]

export const IndustryCodesOverview = () => {
    const isFirstFetch = useRef(true)
    const isChangedFetch = useRef(false)
    const [items, setItems] = useState<Array<IndustryCodeResultFragment>>([])

    // API
    const [readIssuers, { data, loading, error }] = useLazyQuery<ReadAllIndustryCodesQuery,
        ReadAllIndustryCodesQueryVariables>(ReadIndustryCodes, { fetchPolicy: 'network-only' })

    // Filters
    const [filterByNoMcc, setFilterByNoMcc] = useState<boolean>()
    const [merchantCategoryCodeFilter, setMerchantCategoryCodeFilter] = useState<string | undefined>()
    const [filterByRegion, setFilterByRegion] = useState<RegionEnum>(RegionEnum.All)

    const resetFilter = () => {
        log(logT.INFO, ComponentEnum.IndustryCodesOverview, 'Resetting filters');
        setMerchantCategoryCodeFilter('');
        resetSelectFilter();
    }

    const resetSelectFilter = () => {
        log(logT.INFO, ComponentEnum.IndustryCodesOverview, 'Resetting select filters');
        setFilterByNoMcc(false);
        setFilterByRegion(RegionEnum.All);
    }

    useEffect(() => {
        if (merchantCategoryCodeFilter?.length === 4) {
            isChangedFetch.current = true
        } else if (!merchantCategoryCodeFilter) {
            isChangedFetch.current = true
        }
    }, [merchantCategoryCodeFilter]);

    const fetchData = useCallback(
        async (resetCursor?: boolean) => {
            let after = data?.result?.pageInfo?.endCursor
            if (resetCursor) {
                setItems([])
                after = null
            }
            await readIssuers({
                variables: {
                    first: 100,
                    after,
                    filterByNoMcc,
                    merchantCategoryCode: merchantCategoryCodeFilter,
                    filterByRegion
                },
            })
        },
        [
            data,
            readIssuers,
            filterByNoMcc,
            merchantCategoryCodeFilter,
            filterByRegion
        ],
    )

    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData()
            isFirstFetch.current = false
        }
    }, [fetchData])

    useEffect(() => {
        if (isChangedFetch.current) {
            fetchData(true)
            isChangedFetch.current = false
        }
    }, [fetchData, isChangedFetch])

    useEffect(() => {
        const edges = data?.result?.edges
        if (!loading && !error && edges) {
            setItems((prev) => [
                ...prev,
                ...edges.map((v) => v?.node as IndustryCodeResultFragment),
            ])
        }
    }, [loading, error, data])


    const [setMerchantCode, { loading: loadingSetMerchantCode, error: errSetMerchantCode }] =
        useSetMerchantCategoryCodeMutation({
            refetchQueries: [{ query: ReadIndustryCodes }],
        })

    const setMerchantCategoryCode = (row: IndustryCodeResultFragment, value: string) => {
        setMerchantCode({
            variables: {
                id: row.id,
                merchantCategoryCode: value,
            },
        })
        const newList = items.map((item) => {
            if (item.id === row.id) {
                const updatedItem = {
                    ...item,
                    merchantCategoryCode: value,
                }

                return updatedItem
            }
            return item
        })
        setItems(newList)
    }

    const saveItems = () => {
        alert('todo')
    }

    const loadMoreOnScroll = () => {
        setTimeout(() => {
            fetchData()
        }, 500)
    }

    const itemsToRender = useMemo(() => {
        return items.map((v) => (
            <tr
                key={v.id}
            >
                <td>
                    {v.industryCode}
                </td>
                <td>
                    {v.description}
                </td>
                <td>
                    <Form.Control
                        value={v.merchantCategoryCode}
                        onChange={(event) => {
                            setMerchantCategoryCode(v, event.currentTarget.value)
                        }}
                    ></Form.Control>
                </td>
                <td style={{ textAlign: 'center', fontSize: '1.8em' }}>
                    <RegionFlag region={v.region} normalSize hideText/>
                </td>
            </tr>
        ))
    }, [items])

    return (
        <Card>
            <Card.Header>
                <h5 className="dark">Industry codes</h5>
            </Card.Header>

            <Card.Header>
                <Row>
                    <Col sm={12} md={5}>
                        <Select
                            defaultValue={null}
                            onChange={(v: any) => {
                                // Reset filter
                                resetSelectFilter()

                                if (Array.isArray(v)) {
                                    const values = v as Array<ValueType<OptionType>>
                                    values.forEach((i) => {
                                        // @ts-ignore
                                        switch (i.value) {
                                            case 'noMcc':
                                                setFilterByNoMcc(true)
                                                log(logT.INFO, ComponentEnum.IndustryCodesOverview, 'Filter by no MCC:', true);
                                                break
                                            case 'sv':
                                                if (filterByRegion === 'no') {
                                                    setFilterByRegion(RegionEnum.All)
                                                } else {
                                                    setFilterByRegion(RegionEnum.Sv)
                                                }
                                                log(logT.INFO, ComponentEnum.IndustryCodesOverview, 'Filter by region:', 'sv');
                                                break
                                            case 'no':
                                                if (filterByRegion === 'sv') {
                                                    setFilterByRegion(RegionEnum.All)
                                                } else {
                                                    setFilterByRegion(RegionEnum.No)
                                                }
                                                log(logT.INFO, ComponentEnum.IndustryCodesOverview, 'Filter by region:', 'no');
                                                break
                                        }
                                    })
                                }
                                isChangedFetch.current = true
                            }}
                            placeholder={'Filter by'}
                            options={filterOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                            isMulti
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <FormSearchByString
                            placeholder="MCC Code"
                            defaultValue={merchantCategoryCodeFilter}
                            valueCanChange
                            onSubmit={setMerchantCategoryCodeFilter}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <div style={{ marginTop: '8px', fontStyle: 'italic' }}>Changes are saved as you input them</div>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Error error={error?.graphQLErrors[0].message}/>
                <InfiniteScroll
                    hasMore={data?.result?.pageInfo.hasNextPage || false}
                    loader={<Loading isLoading/>}
                    dataLength={items.length}
                    next={loadMoreOnScroll}
                >
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Industry code</th>
                            <th>Industry description</th>
                            <th>Merchant category code</th>
                            <th>Region</th>
                        </tr>
                        </thead>
                        <tbody>{itemsToRender}</tbody>
                    </Table>
                </InfiniteScroll>
            </Card.Body>
        </Card>
    )
}
