import React, { useCallback, useContext, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { privateRoutes, publicRoutes, RouteDefinition } from "./routes";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { LoadingPage } from "./page/loading";
import { ProfileContext } from "./context/profile";
import { NotFoundPage } from "./page/notfound";
import { NotAccessiblePage } from "./page/notaccessible";
import { AuthContext, AuthState } from "./context/auth";

export default () => {

    const { profile, loading } = useContext(ProfileContext)
    const { authenticated } = useContext(AuthContext)

    const isPrivateRouteAccessible = useCallback((r: RouteDefinition): boolean => {
        // User is not authenticated
        if (!profile) {
            return false
        }

        // Profile has no sub-roles, therefore user has full access
        if (profile.subRole === null) {
            return true
        }

        // Check if sub-role is present as tag in the route declaration
        const foundIndex = r.tags?.findIndex(v => v === profile.subRole) ?? -1
        return foundIndex !== -1
    }, [profile])

    const publicRoutesComponents = publicRoutes.map((r, i) => <Route
        key={i}
        exact
        path={r.path}
        component={r.component}
    />
    );

    const privateRoutesComponents = useMemo(() =>
        privateRoutes.map((r, i) => {
            // Redirection
            if (authenticated !== AuthState.Authenticated) {
                const currentPath = window.location.pathname
                if (currentPath !== "/" && currentPath !== "/login") {
                    const currentLocation = encodeURIComponent(window.location.pathname);
                    return <Redirect key={i} to={`/login?redirect=${currentLocation}`} />
                } else {
                    return <Redirect key={i} to={`/login`} />
                }
            }
            return <Route
                key={i}
                exact
                path={r.path}
                component={isPrivateRouteAccessible(r) ? r.component : NotAccessiblePage}
            />
        }), [authenticated, isPrivateRouteAccessible]);

    // Loading page while profile is loaded
    if (loading) {
        return <LoadingPage />
    }

    return (
        <Router>
            <Header />
            <Switch>
                {publicRoutesComponents}
                {privateRoutesComponents}
                <Route component={NotFoundPage} />
            </Switch>
            <Footer />
        </Router>
    );
}

