import React, { useMemo, useState } from 'react';
import { Button, Card, Container, FormControl, InputGroup, Table } from 'react-bootstrap';
import { useZenCostListQuery, ZenCostType } from '../types/graphql';
import { Loading } from '../component/Loading';
import { Error } from '../component/Error';
import moment from 'moment';
import { ComponentEnum, log, logT } from "../log";
import { useMutation } from '@apollo/client';
import { ZenUpdateCost } from "../graphql/zenPoints";

const Component = ComponentEnum.ZenCost;

const limitWidth = {
    minWidth: '3em',
    maxWidth: '5em',
};

export default () => {
    const { data, loading, error,refetch } = useZenCostListQuery();
    const [editableData, setEditableData] = useState<Partial<ZenCostType>[]>([]);
    const [changedRows, setChangedRows] = useState<string[]>([]);
    const [updateZenCost, { loading: mutationLoading, error: mutationError }] = useMutation(ZenUpdateCost);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

    const handleInputChange = (id: string, field: 'multiplier' | 'static', noValue: string) => {
        setShowUpdateSuccess(false);
        let numberVal = parseInt(noValue.toString());
        //go through the original data and remove it from editableData if it's the same as the original
        const originalData = data?.zenCostList.find((item) => item.id === id);
        if (originalData) {
            if (originalData[field] === numberVal) {
                setEditableData((oldData) => oldData.filter((item) => item.id !== id));
                setChangedRows((prev) => prev.filter((item) => item !== id));
                return;
            }
        }
        setChangedRows((prev) => [...new Set([...prev, id])]);
        setEditableData((oldData) => {
            let ix = oldData.findIndex((item) => item.id === id);
            if (ix === -1) {
                oldData.push({ id, [field]: numberVal });
            } else {
                oldData[ix] = { ...oldData[ix], [field]: numberVal };
            }
            return [...oldData];
        });

    };

    const SaveChanges = async (id: string) => {
        log(logT.INFO, Component, 'Save changes for id:', id);
        log(logT.INFO, Component, 'Save changes for editableData:', editableData)
        const row = editableData.find(item => item.id === id);
        if (!row) {
            log(logT.ERROR, Component, 'Row not found:', id);
            return;
        }
        //lets find the typ using the original data
        const original = data?.zenCostList.find((item) => item.id === id);
        try {
            const { data } = await updateZenCost({
                variables: {
                    typeId: original?.typ,
                    multiplier: row.multiplier,
                    static: row.static,
                },
            });
            log(logT.INFO, Component, 'Successfully updated:', data);
            await refetch();
            setShowUpdateSuccess(true);
        } catch (error) {
            log(logT.ERROR, Component, 'Mutation failed:', error)
        }
    };


    const rows = useMemo(() => {
        if (!data?.zenCostList) return null;
        const sortedData = [...data.zenCostList].sort((a, b) => a.typ - b.typ);
        return sortedData.map((item, index) => (
            <tr key={item.id}>
                <td>{moment(item.ts!).format('DD-MM-YYYY')}</td>
                <td style={{ textAlign: "center" }}>{item.typ}</td>
                <td  style={{ textAlign: "center" }}>{item.typeName}</td>
                <td  style={limitWidth}>
                    {item.multiplier && item.multiplier > 0 ? (
                        <InputGroup style={limitWidth}>
                            <FormControl
                                defaultValue={item.multiplier}
                                onChange={(e) => handleInputChange(item.id, 'multiplier', e.target.value)}
                            />
                        </InputGroup>
                    ) : (
                        //only show if it's > 0
                        item.multiplier > 0 && item.multiplier
                    )}
                </td>
                <td  style={limitWidth}>
                    {item.static && item.static > 0 ? (
                        <InputGroup style={limitWidth}>
                            <FormControl
                                defaultValue={item.static}
                                onChange={(e) => handleInputChange(item.id, 'static', e.target.value)}
                            />
                        </InputGroup>
                    ) : (
                        item.static > 0 && item.static
                    )}
                </td>
                <td style={{ textAlign: "center" }}>
                    {changedRows.includes(item.id) && (
                        <Button size="sm" onClick={() => SaveChanges(item.id)}>
                            Save
                        </Button>
                    )}
                </td>
            </tr>
        ));
    }, [data, editableData, changedRows]);

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h5 className="dark">Zen Cost</h5>
                </Card.Header>
                <Card.Body>
                    <Loading isLoading={loading}/>
                    <Table responsive bordered striped hover size="sm">
                        <thead>
                        <tr>
                            <th>Created</th>
                            <th  style={{ textAlign: "center" }}>Type</th>
                            <th  style={{ textAlign: "center" }}>Type Name</th>
                            <th style={limitWidth}>Multiplier</th>
                            <th style={limitWidth}>Static</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    {showUpdateSuccess && <div className="alert alert-success">Save was successful.</div>}
                    <Error error={error?.graphQLErrors[0]?.message}  />
                    <Error error={mutationError?.graphQLErrors[0]?.message}  />
                </Card.Body>
            </Card>
        </Container>
    );
};
