import React, {useEffect} from 'react';
import {Card} from "react-bootstrap";
import {
    CreateOfferServiceMutation,
    CreateOfferServiceMutationVariables,
    DeleteOfferServiceMutation,
    DeleteOfferServiceMutationVariables, OfferServiceWithTranslations, OfferWithTranslations,
    ReadOfferServiceByIdQuery,
    ReadOfferServiceByIdQueryVariables,
    UpdateOfferServiceMutation,
    UpdateOfferServiceMutationVariables
} from "../types/graphql";
import {MomentDate} from "./MomentDate";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    CreateOfferService,
    DeleteOfferService,
    ReadOfferServiceById,
    UpdateOfferService, ReadOfferServices
} from "../graphql/offer";
import {LoadingPage} from "../page/loading";
import {Error} from "./Error";
import {OfferServiceForm} from "./OfferServiceForm";

interface IProps {
    offerId: string
    offerServiceId?: string
    onCreated?: () => void
    onUpdated?: () => void
    onDeleted?: () => void
}

export const OfferServiceDetail = ({offerId, offerServiceId, onCreated, onUpdated, onDeleted}: IProps) => {

    const [readService, {
        data: dataService,
        loading: loadingReadService,
        error: errReadService
    }] = useLazyQuery<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>(ReadOfferServiceById)

    const [createService, {
        loading: loadingCreateService,
        error: errCreateService
    }] = useMutation<CreateOfferServiceMutation, CreateOfferServiceMutationVariables>(CreateOfferService)

    const [updateService, {
        loading: loadingUpdateService,
        error: errUpdateService
    }] = useMutation<UpdateOfferServiceMutation, UpdateOfferServiceMutationVariables>(UpdateOfferService)

    const [deleteService, {
        loading: loadingDeleteService,
        error: errDeleteService
    }] = useMutation<DeleteOfferServiceMutation, DeleteOfferServiceMutationVariables>(DeleteOfferService)

    useEffect(() => {
        if (offerServiceId) {
            readService({
                variables: {
                    offerServiceId
                }
            })
        }
    }, [offerServiceId, readService])

    const onCreate = async (args: CreateOfferServiceMutationVariables) => {
        await createService({
            variables: args,
            refetchQueries: [
                {query: ReadOfferServices, variables: {offerId}}
            ]
        })
        onCreated?.();
    }

    const onUpdate = async (args: UpdateOfferServiceMutationVariables) => {
        await updateService({
            variables: args,
            refetchQueries: [
                {query: ReadOfferServiceById, variables: {offerId, offerServiceId}},
                {query: ReadOfferServices, variables: {offerId}}
            ]
        })
        onUpdated?.();
    }

    const onDelete = async (args: DeleteOfferServiceMutationVariables) => {
        await deleteService({
            variables: args,
            refetchQueries: [
                {query: ReadOfferServices, variables: {offerId}}
            ]
        })
        onDeleted?.();
    }

    if (loadingReadService || loadingCreateService || loadingUpdateService || loadingDeleteService) {
        return <LoadingPage/>
    }

    return (
        <Card>

            <Error error={errReadService?.graphQLErrors[0].message}/>
            <Error error={errCreateService?.graphQLErrors[0].message}/>
            <Error error={errUpdateService?.graphQLErrors[0].message}/>
            <Error error={errDeleteService?.graphQLErrors[0].message}/>

            <Card.Body>
                <OfferServiceForm
                    offerId={offerId}
                    data={dataService?.service as OfferServiceWithTranslations}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            </Card.Body>

            <Card.Footer>
                {
                    dataService && <MomentDate
                        value={dataService.service.updatedAt || dataService.service.createdAt}
                        format={"DD MMMM YYYY HH:mm:ss"}
                        tooltipId={"createdAt_" + dataService.service.id}
                        tooltipFromNow/>
                }
            </Card.Footer>

        </Card>
    );
};
