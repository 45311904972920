import gql from "graphql-tag";
import {InvoiceFragments, InvoiceHistoryFragments} from "./fragments/invoice";

export const ReadInvoices = gql`
    query readAllInvoices(
        $first: Int,
        $last: Int,
        $before: String,
        $after: String,
        $kind: InvoiceKind,
        $status: TransactionStatus,
        $issuerId: ID,
        $userId: ID,
        $fromDate: DateTime,
        $toDate: DateTime,
        $searchingTerm: String
        $invoiceId: ID,
    ) {
        result: readAllInvoices(
            first: $first,
            last: $last,
            before: $before,
            after: $after,
            kind: $kind,
            status: $status,
            issuerId: $issuerId,
            userId: $userId,
            fromDate: $fromDate,
            toDate: $toDate,
            searchingTerm: $searchingTerm
            invoiceId: $invoiceId,
        ) {
            edges{
                node {
                    ...InvoiceResult
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }
    ${InvoiceFragments.result}
`;

export const ReadInvoiceHistory = gql`
    query readInvoiceHistory($invoiceId: ID!) {
        result: readInvoiceHistory(invoiceId: $invoiceId) {
            ...InvoiceHistoryResult
        }
    }
    ${InvoiceHistoryFragments.result}
`;

export const AdminReadInvoiceById = gql`
    query AdminReadInvoiceById($invoiceId: ID!) {
        invoice: adminReadInvoiceByIdentifier(invoiceId: $invoiceId) {
            id
            reference
            referenceProvider
            amount
            currency {
                code
                name
                symbol
            }
            dueDate
            customerNumber
            invoiceDate
            issuer {
                id
                orgNumber
                picture
                name
            }
            vat
            user {
                id
                sid
                firstname
                lastname
                address {
                    street
                    postalCode
                    city
                    country {
                        name
                    }
                }
            }
            reference
            lastTransaction {
                event
                amount
                status
                paymentStatus
                orderRef
                transactionRef
            }
            net
            kind
            customerUId
            support {
                type
                requestedAt
            }
        }
    }
`;

export const ReadBalanceHistory = gql`
    query readBalanceHistory($fromDate: DateTime!, $toDate: DateTime!) {
        result: readBalanceHistory(fromDate: $fromDate, toDate: $toDate) {
            sumIncoming
            sumIncomingWithoutFees
            sumOutgoing
            sumFees
            sumZenFeeNOK
            sumGrossAmount
            balance
            transactions {
                lastTransactionId,
                lastTransactionTime,
                invoiceId,
                invoiceReference,
                invoiceReferenceProvider,
                invoiceKind,
                invoiceIdentifier,
                invoiceStatus,
                invoiceStatusDate,
                issuerId,
                issuerName,
                userId,
                userName,
                userEmail,
                balanceInvoiceAmount,
                balanceCardAmount,
                balanceRemitAmount,
                balanceFeeAmount,
                balanceZenFee,
                balanceGross,
                balanceFeePercentage,
                balanceFeeReason,
                paymentCardId,
                paymentAttempt,
                paymentStatus,
                paymentId,
                paymentTime,
                remitBatchId,
                remitAttempt,
                remitStatus,
                remitStatusDescription,
                remitTime,
            }
            fees {
                reason
                total
            }
        }
    }`;

export const ReadBalanceHistoryAsExcel = gql`
    query readBalanceHistoryAsExcel($fromDate: DateTime!, $toDate: DateTime!) {
        result: readBalanceHistoryAsExcel(fromDate: $fromDate, toDate: $toDate) {
            data
        }
    }`;

export const ReadZenExtraBalanceAsExcel = gql`
    query readZenExtraBalanceAsExcel($fromDate: DateTime!, $toDate: DateTime!) {
        result: readZenExtraBalanceAsExcel(fromDate: $fromDate, toDate: $toDate) {
            data
        }
    }`;

export const ReadBalanceForecasting = gql`
    query readBalanceForecasting {
        result: readBalanceForecasting {
            time
            buffer
            incoming
            outgoing
        }
    }`;

export const StopRemittance = gql`
    mutation stopRemittance($invoiceId: ID!) {
        result: stopRemittance(invoiceId: $invoiceId) {
            isOk
        }
    }`;

export const RestartRemittance = gql`
    mutation restartRemittance($invoiceId: ID!) {
        result: restartRemittance(invoiceId: $invoiceId) {
            isOk
        }
    }`;

export const ReadBalanceSnapshots = gql`
    query readBalanceSnapshots($first: Int, $last: Int, $before: String, $after: String) {
        result: readBalanceSnapshots(first: $first, last: $last, before: $before, after: $after) {
            edges{
                node {
                    id
                    amount
                    time
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }`;

export const CreateBalanceSnapshot = gql`
    mutation createBalanceSnapshot($amount: Float!, $time: DateTime!) {
        result: createBalanceSnapshot(amount: $amount, time: $time) {
            isOk
        }
    }`;

export const RemoveBalanceSnapshot = gql`
    mutation removeBalanceSnapshot($snapshotId: ID!) {
        result: removeBalanceSnapshot(snapshotId: $snapshotId) {
            isOk
        }
    }`;
