import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Card, Col, Image, Row, Table} from "react-bootstrap";
import {useLazyQuery} from "@apollo/client";
import {IssuerResultFragment, ReadAllIssuersQuery, ReadAllIssuersQueryVariables} from "../types/graphql";
import {ReadIssuers} from "../graphql/issuer";
import {FormSearchByString} from "./FormSearchByString";
import {Error} from "./Error";
import {IssuerPicture} from "./IssuerPicture";

export interface IProps {
    defaultFilterByName: string
    onClick: (issuerId: string) => any
}

export const IssuersSuggestions = ({defaultFilterByName, onClick}: IProps) => {

    const isFirstFetch = useRef(true);
    const isChangedFetch = useRef(false);
    const [items, setItems] = useState<Array<IssuerResultFragment>>([]);
    const [readIssuers, {data, loading, error}] = useLazyQuery<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>(ReadIssuers);

    // Filters
    const [filterByName, setFilterByName] = useState<string>(defaultFilterByName);

    const fetchData = useCallback(async (resetCursor?: boolean) => {
        let after = data?.result?.pageInfo?.endCursor;
        if (resetCursor) {
            setItems([])
            after = null
        }
        await readIssuers({
            variables: {
                first: 25,
                after,
                filterByName
            }
        })
    }, [data, filterByName, readIssuers]);

    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData()
            isFirstFetch.current = false
        }
    }, [fetchData])

    useEffect(() => {
        if (isChangedFetch.current) {
            fetchData(true)
            isChangedFetch.current = false
        }
    }, [fetchData, isChangedFetch])

    useEffect(() => {
        const edges = data?.result?.edges
        if (!loading && !error && data && edges) {
            setItems(prev => [
                ...prev,
                ...edges.map(v => v?.node as IssuerResultFragment)
            ])
        }
    }, [loading, error, data])

    const itemsToRender = useMemo(() => {
        return items.map(v => (
                <tr
                    key={v.id}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        onClick(v.id)
                    }}
                >
                    <td>
                        <IssuerPicture picture={v.picture}/>
                    </td>
                    <td>{v.name}</td>
                    <td>{v.orgNumber}</td>
                </tr>
            )
        )
    }, [items, onClick])

    return (
        <Card>

            <Card.Header>
                <Row>
                    <Col sm={12}>
                        <FormSearchByString
                            placeholder="Search issuer by name / org. number"
                            text={`You should look for matches related to ${defaultFilterByName} or similar`}
                            defaultValue={defaultFilterByName}
                            onSubmit={async (name) => {
                                setFilterByName(name)
                                isChangedFetch.current = true
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Error error={error?.graphQLErrors[0].message}/>
                </Row>
            </Card.Header>

            <Card.Body style={{height: '300px', display: 'block', overflowY: 'scroll'}}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Organisation number</th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemsToRender}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );

};
