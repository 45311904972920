import React, {useEffect, useState} from 'react';
import {Col, Form, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {
	ReadIssuerTransactionRuleQuery,
	ReadIssuerTransactionRuleQueryVariables,
	SetIssuerTransactionRuleMutation,
	SetIssuerTransactionRuleMutationVariables,
	UnsetIssuerTransactionRuleMutation,
	UnsetIssuerTransactionRuleMutationVariables
} from "../types/graphql";
import Button from "react-bootstrap/Button";
import {useMutation, useQuery} from "@apollo/client";
import {ReadIssuerTransactionRule, SetIssuerTransactionRule, UnsetIssuerTransactionRule} from "../graphql/issuerTool";
import {LoadingPage} from "../page/loading";

interface IProps {
	issuerId: string
}

export const FormIssuerTransactionRule = ({issuerId}: IProps) => {

	const {
		data,
		loading: loadingRule,
		error: errRule
	} = useQuery<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>(ReadIssuerTransactionRule, {
		variables: {
			issuerId
		}
	});

	const [setRule, {
		loading: loadingSetRule,
		error: errSetRule
	}] = useMutation<SetIssuerTransactionRuleMutation, SetIssuerTransactionRuleMutationVariables>(SetIssuerTransactionRule, {
		refetchQueries: [{query: ReadIssuerTransactionRule, variables: {issuerId}}]
	});

	const [unsetRule, {
		loading: loadingUnsetRule,
		error: errUnsetRule
	}] = useMutation<UnsetIssuerTransactionRuleMutation, UnsetIssuerTransactionRuleMutationVariables>(UnsetIssuerTransactionRule, {
		refetchQueries: [{query: ReadIssuerTransactionRule, variables: {issuerId}}]
	});

	useEffect(() => {
		if (errRule) alert(errRule)
	}, [errRule]);

	useEffect(() => {
		if (errSetRule) alert(errSetRule)
	}, [errSetRule]);

	useEffect(() => {
		if (errUnsetRule) alert(errUnsetRule)
	}, [errUnsetRule]);

	const [allowDebitCard, setAllowDebitCard] = useState<boolean>(false);
	const [maxAmountDebitCard, setMaxAmountDebitCard] = useState<number | null>(null);
	const [allowCreditCard, setAllowCreditCard] = useState<boolean>(false);
	const [maxAmountCreditCard, setMaxAmountCreditCard] = useState<number | null>(null);
	const [allowAmexCard, setAllowAmexCard] = useState<boolean>(false);
	const [maxAmountAmexCard, setMaxAmountAmexCard] = useState<number | null>(null);

	useEffect(() => {
		if (data) {
			setAllowDebitCard(data.rule.allowDebitCard);
			setMaxAmountDebitCard(data.rule.maxAmountDebitCard || null);
			setAllowCreditCard(data.rule.allowCreditCard);
			setMaxAmountCreditCard(data.rule.maxAmountCreditCard || null);
			setAllowAmexCard(data.rule.allowAmexCard);
			setMaxAmountAmexCard(data.rule.maxAmountAmexCard || null);
		}
	}, [data]);

	if (loadingRule || loadingSetRule || loadingUnsetRule) {
		return <LoadingPage />
	}

	// Enforce the amount to be null if not defined.
	// Zero (0) is not a valid value for max. amount.
	// Backend returns an error in that case.
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setRule({
			variables: {
				issuerId,
				allowDebitCard,
				allowCreditCard,
				allowAmexCard,
				maxAmountDebitCard,
				maxAmountCreditCard,
				maxAmountAmexCard
			}
		}).catch(e => {
			console.debug(e)
		})
	};

	const resetRule = () => {
		unsetRule({
			variables: {
				issuerId
			}
		}).catch(e => {
			console.debug(e)
		})
	};

	return (
		<Form noValidate onSubmit={handleSubmit}>

			<ListGroup>
				<ListGroupItem>
					<h6>Debit cards</h6>
					<RowRule
						allowCard={allowDebitCard}
						maxAmount={maxAmountDebitCard || undefined}
						onChangeAllowCard={setAllowDebitCard}
						onChangeMaxAmount={setMaxAmountDebitCard}
					/>
				</ListGroupItem>
				<ListGroupItem>
					<h6>Credit cards</h6>
					<RowRule
						allowCard={allowCreditCard}
						maxAmount={maxAmountCreditCard || undefined}
						onChangeAllowCard={setAllowCreditCard}
						onChangeMaxAmount={setMaxAmountCreditCard}
					/>
				</ListGroupItem>
				<ListGroupItem>
					<h6>Amex cards</h6>
					<RowRule
						allowCard={allowAmexCard}
						maxAmount={maxAmountAmexCard || undefined}
						onChangeAllowCard={setAllowAmexCard}
						onChangeMaxAmount={setMaxAmountAmexCard}
					/>
				</ListGroupItem>
			</ListGroup>

			<Row style={{marginTop: "1em"}}>
				<Col>
					<Button variant="secondary" type="submit">
						Update rules
					</Button>
				</Col>
				<Col>
					{data?.rule.isCustom && (
						<Button variant="danger" onClick={resetRule}>
							Reset rules (to default)
						</Button>
					)}
				</Col>
			</Row>

		</Form>
	);

};


interface IRowRuleProps {
	allowCard: boolean
	maxAmount?: number
	onChangeAllowCard: (allowCard: boolean) => void
	onChangeMaxAmount: (maxAmount: number | null) => void
}

const RowRule = ({
					 allowCard,
					 maxAmount,
					 onChangeAllowCard,
					 onChangeMaxAmount
				 }: IRowRuleProps) => {

	const [hasLimit, setHasLimit] = useState<boolean>(false);

	useEffect(() => {
		setHasLimit(!!maxAmount);
	}, [maxAmount]);

	return (
		<Row>

			<Col>
				<Form.Check
					required
					checked={allowCard}
					onChange={(e: any) => {
						onChangeAllowCard(e.target.checked);
					}}
				/>
				<Form.Text className="text-muted">
					Allow cards
				</Form.Text>
			</Col>

			<Col>
				{allowCard && (
					<>
						<Form.Check
							required
							checked={hasLimit}
							disabled={!allowCard}
							onChange={(e: any) => {
								const hasLimit = e.target.checked;
								setHasLimit(hasLimit);
								if (!hasLimit) {
									onChangeMaxAmount(null);
								}
							}}
						/>
						<Form.Text className="text-muted">
							Set amount limit
						</Form.Text>
					</>
				)}
			</Col>

			<Col>
				{allowCard && hasLimit && (
					<>
						<Form.Control
							placeholder={'kr'}
							type="text"
							required
							value={maxAmount}
							disabled={!hasLimit}
							onChange={(e: any) => {
								const value = e.target.value;
								onChangeMaxAmount(value || null);
							}}
						/>
						<Form.Text className="text-muted">
							Max. transaction amount
						</Form.Text>
					</>
				)}
			</Col>

		</Row>
	);

};
