import React, {useMemo} from 'react';
import {Card, Row, Col, Table} from "react-bootstrap";
import {StatisticCard} from "./StatisticCard";
import {useLazyQuery} from "@apollo/client";
import {
    OfferLeadStatisticsOffer, OfferLeadStatisticsService,
    ReadOfferLeadsStatisticsQuery,
    ReadOfferLeadsStatisticsQueryVariables,
} from "../types/graphql";
import {Error} from "./Error";
import {Loading} from "./Loading";
import {ReadOfferLeadsStatistics} from "../graphql/offer";
import {FilterByMonthYear} from "./FilterByMonthYear";

interface StatisticsPowerOfferItemProps {
    data: OfferLeadStatisticsOffer
}

const StatisticsPowerOfferItem = ({data}: StatisticsPowerOfferItemProps) => {
    const services = data.services?.map(s => <StatisticsPowerServiceItem key={s.id} data={s}/>)
    return (
        <>
            <tr>
                <td>{data.name}</td>
                <td/>
                <td>{data.viewCount}</td>
                <td>{data.clickCount}</td>
                <td>{data.emailSubmitCount}</td>
            </tr>
            {services}
        </>
    )
}

interface StatisticsPowerServiceItemProps {
    data: OfferLeadStatisticsService
}

const StatisticsPowerServiceItem = ({data}: StatisticsPowerServiceItemProps) => {
    return (
        <tr>
            <td/>
            <td>{data.name}</td>
            <td/>
            <td>{data.clickCount}</td>
            <td>{data.emailSubmitCount}</td>
        </tr>
    )
}

export const StatisticsPower = () => {

    const [readStats, {
        data,
        loading,
        error
    }] = useLazyQuery<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>(ReadOfferLeadsStatistics);

    const onFilterByMonthYearUpdate = (fromDate: string, toDate: string) => {
        readStats({
            variables: {
                fromDate,
                toDate
            }
        })
    }

    const stats = useMemo(() => {
        if (!data?.stats) return null;
        const {offers, totalViewCount, totalClickCount, totalEmailSubmitCount} = data?.stats;
        const offersList = offers?.map(o => <StatisticsPowerOfferItem key={o.id} data={o}/>)

        return (
            <Card.Body>
                <Row>
                    <Col md={3}>
                        <StatisticCard value={offers?.length} description={"Number of offers"}/>
                    </Col>
                    <Col md={3}>
                        <StatisticCard value={totalViewCount} description={"Total views"}/>
                    </Col>
                    <Col md={3}>
                        <StatisticCard value={totalClickCount} description={"Total clicks"}/>
                    </Col>
                    <Col md={3}>
                        <StatisticCard value={totalEmailSubmitCount} description={"Total email submits"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th>Offer</th>
                            <th>Service</th>
                            <th>Views</th>
                            <th>Clicks</th>
                            <th>Email submits</th>
                        </tr>
                        </thead>
                        <tbody>
                        {offersList}
                        </tbody>
                    </Table>
                </Row>
            </Card.Body>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Ninja Power</h5>
            </Card.Header>
            <Card.Header>
                <FilterByMonthYear onChange={onFilterByMonthYearUpdate}/>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
        </Card>
    );

}
