import React from 'react';
import { Container } from "react-bootstrap";
import { InvoicesOverview } from "../component/InvoicesOverview";

export default () => {
    return (
        <Container>
            <InvoicesOverview />
        </Container>
    );
}
