import React, { CSSProperties } from "react";
import { Image, Row, Col } from "react-bootstrap";
import {
  OfferServiceWithTranslations,
  OfferWithTranslations,
} from "../types/graphql";
import { colors } from "../theme";
import {IssuerPicture} from "./IssuerPicture";

interface IProps {
  offer: OfferWithTranslations;
}

type Lang = 'En' | 'No' | 'Sv';

type ServiceComponentProps = {
  s: OfferServiceWithTranslations;
  lang: Lang;
};

const ServiceComponent = ({ s, lang }: ServiceComponentProps) => {
  const nameKey = `name${lang}` as keyof OfferServiceWithTranslations;
  const descriptionKey = `description${lang}` as keyof OfferServiceWithTranslations;

  return (
      <a
          href={s.url || ""}
          rel={"noopener noreferrer"}
          target={"_blank"}
          aria-label={`Click to navigate the service URL in ${lang}`}
      >
        <div style={ServiceContainerStyle}>
          <span style={ServiceNameStyle}>{s[nameKey]}</span>
          <br />
          <span style={ServiceDescriptionStyle}>{s[descriptionKey]}</span>
        </div>
      </a>
  );
};

export const OfferPreview = ({ offer }: IProps) => {
  const servicesNo = offer.services?.map((service) => (
      <ServiceComponent s={service} lang="No" key={service.id} />
  ));

  const servicesEn = offer.services?.map((service) => (
      <ServiceComponent s={service} lang="En" key={service.id} />
  ));

  const servicesSv = offer.services?.map((service) => (
      <ServiceComponent s={service} lang="Sv" key={service.id} />
  ));

  return (
    <>
      <Row>
        <Col>
          <div style={LogoContainerStyle}>
            <IssuerPicture picture={offer.picture} />
            <div style={LogoTextStyle}>
              <span
                role={"img"}
                aria-label={"English version"}
                style={{ fontWeight: 800 }}
              >
                {offer.name} 🇬🇧
              </span>
              <br />
              <span>{offer.category.nameEn}</span>
            </div>
          </div>
        </Col>
        <Col>
          <div style={LogoContainerStyle}>
            <IssuerPicture picture={offer.picture} />
            <div style={LogoTextStyle}>
              <span
                role={"img"}
                aria-label={"Norwegian version"}
                style={{ fontWeight: 800 }}
              >
                {offer.name} 🇳🇴
              </span>
              <br />
              <span>{offer.category.nameNo}</span>
            </div>
          </div>
        </Col>
        <Col>
          <div style={LogoContainerStyle}>
            <IssuerPicture picture={offer.picture} />
            <div style={LogoTextStyle}>
              <span
                  role={"img"}
                  aria-label={"Swedish version"}
                  style={{ fontWeight: 800 }}
              >
                {offer.name} 🇸🇪
              </span>
              <br />
              <span>{offer.category.nameSv}</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={DescriptionContainerStyle}>
        <Col>
          <p>{offer.descriptionEn}</p>
        </Col>
        <Col>
          <p>{offer.descriptionNo}</p>
        </Col>
        <Col>
          <p>{offer.descriptionSv}</p>
        </Col>
      </Row>
      <Row>
        <Col>{servicesEn}</Col>
        <Col>{servicesNo}</Col>
        <Col>{servicesSv}</Col>
      </Row>
    </>
  );
};

const LogoContainerStyle: CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
};

const LogoTextStyle: CSSProperties = {
  flex: 1,
  flexDirection: "column",
};

const DescriptionContainerStyle: CSSProperties = {
  paddingTop: "0.5em",
};

const ServiceContainerStyle: CSSProperties = {
  borderRadius: "0.25em",
  backgroundColor: colors.black,
  padding: "0.5em",
  marginBottom: "0.5em",
};

const ServiceNameStyle: CSSProperties = {
  fontWeight: 500,
  color: colors.white,
};

const ServiceDescriptionStyle: CSSProperties = {
  fontWeight: 400,
  color: colors.white,
};
