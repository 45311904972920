import React, { useMemo } from "react";
import Moment from "react-moment";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
  ReadUserNotesQuery,
  ReadUserNotesQueryVariables,
  UserNote,
} from "../types/graphql";
import { Error } from "./Error";
import { ReadUserNotes } from "../graphql/userNote";

interface IProps {
  userId: string;
  onClick: (note: UserNote) => void;
}

export const UserNoteList = ({ userId, onClick }: IProps) => {
  const { data, error } = useQuery<
    ReadUserNotesQuery,
    ReadUserNotesQueryVariables
  >(ReadUserNotes, {
    variables: {
      userId,
    },
  });

  const rows = useMemo(() => {
    const edges = data?.notes?.edges;
    if (!edges) return [];
    return edges.map((e, i) => {
      const v = e?.node;
      return (
        <tr key={i} onClick={() => onClick(v as UserNote)}>
          <td>
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={
                <Tooltip id={i.toString()}>
                  <Moment fromNow={true}>{v?.noteTime}</Moment>
                </Tooltip>
              }
            >
              <Moment format="DD MMMM YYYY">{v?.noteTime}</Moment>
            </OverlayTrigger>
          </td>
          <td>
            <p>{v?.content}</p>
          </td>
        </tr>
      );
    });
  }, [data, onClick]);

  return (
    <>
      <Error error={error?.graphQLErrors[0].message} />
      <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Time</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
};
