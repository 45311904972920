interface FileToGenerate {
    content: string
    fileName: string
    fileType: string
}

export const generateFile = ({content, fileName, fileType}: FileToGenerate) => {
    const a = document.createElement("a");

    a.href = "data:" + fileType + ";base64," + btoa(content);
    a['download'] = fileName;
    const e = document.createEvent("MouseEvents");

    // Use of deprecated function to satisfy TypeScript.
    e.initMouseEvent("click", true, false,
        <Window>document.defaultView, 0, 0, 0, 0, 0,
        false, false, false, false, 0, null);
    a.dispatchEvent(e);
    a.remove();
}