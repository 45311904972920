import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {colors} from "../theme";
import {AuthContext, AuthState} from "../context/auth";
import {ProfileContext} from "../context/profile";
import {LoadingPage} from "./loading";
import {Loading} from "../component/Loading";
import {useLogin} from "../hooks/auth";
import {Error} from "../component/Error";

export const LoginPage = () => {

    const location = useLocation();
    const history = useHistory();
    const [mobile, setMobile] = useState<string>(localStorage.getItem('bk-portal-mobile') || '')
    const {authenticated, setAuthenticated} = useContext(AuthContext)
    const {refresh, loading: refreshLoading} = useContext(ProfileContext)

    const {login, success, sessionUrl, loading: loginLoading, error} = useLogin()

    useEffect(() => {
        console.debug("Login page loaded")
        return () => {
            console.debug("Login page unloaded")
        }
    }, []);

    // When session URL is available
    useEffect(() => {
        if (sessionUrl) {
            console.debug("Redirecting to BankID")
            window.location.replace(sessionUrl);
        }
    }, [sessionUrl])

    // When access is created
    useEffect(() => {
        console.debug("BankID authentication Check ✅")
        if (success) {
            console.debug("BankID authentication Success ✅")
            setAuthenticated(AuthState.Authenticated);
            console.debug("Redirecting to home")
            history.replace("/");
        }
    }, [success, history, setAuthenticated])

    useEffect(() => {
        console.debug("Authenticated state", authenticated)
        if (authenticated === AuthState.Authenticated) {
            let params = queryString.parse(location.search);
            const redirection = params.redirect;
            if (redirection) {
                console.debug("Redirecting to", redirection)
                history.replace(redirection.toString());
            } else {
                console.debug("Redirecting to home")
                history.replace("/");
            }
        } else if (authenticated === AuthState.Expired && !refreshLoading) {
            refresh().then(() => {
                console.debug("Refreshed tokens on login")
            }).catch(e => {
                console.debug("Error refreshing token on login", e)
            })
        }
    }, [authenticated, history, location.search, refresh, refreshLoading])

    if (refreshLoading || loginLoading) {
        return <LoadingPage />
    }

    return (
        <div className="Login">
            <Container>
                <h1>Login</h1>
                <Error error={error?.graphQLErrors[0]?.message}/>
                <Row style={{alignItems: 'center'}}>
                    <Col md={6}>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{color: colors.white}}>International phone number</Form.Label>
                                <Form.Control
                                    type="phone"
                                    placeholder="+47xxxx" value={mobile}
                                    onChange={(event: any) => {
                                        localStorage.setItem('bk-portal-mobile', event.target.value)
                                        setMobile(event.target.value)
                                    }}
                                />
                                <Form.Text className="text-muted">
                                    International phone number without spaces or other symbols except "+".
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Button
                            variant="primary"
                            disabled={loginLoading}
                            onClick={async () => {
                                await login(mobile)
                            }}>
                            <Loading
                                isLoading={loginLoading}
                                type={"spinner-button"}
                                variant={"light"}
                            />
                            Login via BankId
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
