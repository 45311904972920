interface ILocalStorage {
    get(): Promise<string | null>

    set(value: string): Promise<void>

    isSet(): Promise<boolean>

    reset(): Promise<void>
}

class LocalStorage implements ILocalStorage {

    key = "";

    get(): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            const v = localStorage.getItem(this.key);
            resolve(v);
        });
    }

    set(value: string): Promise<void> {
        return new Promise<void>((resolve) => {
            localStorage.setItem(this.key, value);
            resolve();
        });
    }

    isSet = async () => {
        const value = await this.get();
        return typeof value === "string" && value !== ""
    }

    reset(): Promise<void> {
        return new Promise<void>((resolve) => {
            localStorage.removeItem(this.key);
            resolve();
        });
    }
}

export class AccessTokenStorage extends LocalStorage {
    key = "token";
}

export class RefreshTokenStorage extends LocalStorage {
    key = "refreshToken";
}

export class BankIdSessionStorage extends LocalStorage {
    key = "session";
}
