import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./context/auth";
import {CustomApolloProvider} from "./context/apollo";
import {ProfileProvider} from "./context/profile";

ReactDOM.render((
    <BrowserRouter>
        <AuthProvider>
            <CustomApolloProvider>
                <ProfileProvider>
                    <App/>
                </ProfileProvider>
            </CustomApolloProvider>
        </AuthProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about client workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
