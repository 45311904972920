import gql from "graphql-tag";

export const UserFragments = {
    result: gql`
        fragment UserResult on User {
            id
            firstname
            lastname
            email
            emailCheck
            mobileCheck
            createdAt
            region
            permission {
                einvoice {
                    acceptAllIssuers
                }
                pep {
                    status
                }
            }
        }
    `,
    detailed: gql`
        fragment UserDetail on User {
            id
            status
            firstname
            lastname
            birthdate
            email
            emailCheck
            mobile
            mobileCheck
            createdAt
            updatedAt
            deletedAt
            region
            address{ street postalCode city country{code} }
            permission {
                einvoice {
                    acceptAllIssuers
                }
                pep {
                    status
                }
            }
        }
    `,
};
