import gql from "graphql-tag";

export const ReadAdminProfile = gql`
    query readAdminProfile {
        profile: readAdminProfile {
            id
            firstname
            lastname
            subRole
        }
    }
`;

export const RemoveAccount = gql`
    mutation removeAccount (
        $userId: ID!
        $message: String
    ){
        user: removeAccount(
            userId: $userId
            message: $message
        ) {
            id
            sid
            firstname
            lastname
            birthdate
            email
            emailCheck
            mobile
            mobileCheck
            address{
                street
                postalCode
                city
                country {
                    name
                }
            }
        }
    }
`