import gql from 'graphql-tag'
import { IndustryCodeFragments } from './fragments/industryCode'

export const ReadIndustryCodes = gql`
    query readAllIndustryCodes(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $filterByNoMcc: Boolean
        $merchantCategoryCode: String
        $filterByRegion: RegionEnum
    ) {
        result: readAllIndustryCodes(
            first: $first
            last: $last
            before: $before
            after: $after
            filterByNoMcc: $filterByNoMcc
            merchantCategoryCode: $merchantCategoryCode
            filterByRegion: $filterByRegion
        ) {
            edges{
                node {
                    ...IndustryCodeResult
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }
    ${IndustryCodeFragments.result}
`

export const SetMerchantCategoryCode = gql`
    mutation setMerchantCategoryCode (
        $id: ID!
        $merchantCategoryCode: String!
    ) {
        result: setMerchantCategoryCode(
            id: $id
            merchantCategoryCode: $merchantCategoryCode
        ) {
           id
           industryCode
           description
           merchantCategoryCode
           country
           createdAt
        }
    }
`
