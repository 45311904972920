import React from 'react';
import {Container} from "react-bootstrap";
import {IssuersOverview} from "../component/IssuersOverview";

export default () => {
    return (
        <Container>
            <IssuersOverview/>
        </Container>
    );
}


