import React from "react";

// TODO: Related to issue https://github.com/mohitgupta8888/react-currency-format/issues/1
// If a better library can be found, replace it!
const CurrencyFormat: any = require('react-currency-format');

interface IStatisticCardProps {
    value: any;
    isMoney?: boolean;
    description: string;
}

export const StatisticCard = ({value, isMoney, description}: IStatisticCardProps) => {
    return (
        <>
            <h2 className="text-center dark">
                {
                    isMoney ?
                        <CurrencyFormat
                            value={value}
                            displayType={'text'}
                            thousandSeparator
                            suffix={" kr"}
                        />
                        : value
                }
            </h2>
            <p className="text-center text-muted">{description}</p>
        </>
    );
};
