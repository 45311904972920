import gql from "graphql-tag";

export const ReadOffers = gql`
    query readOffers {
        offers: readOffers {
            id
            name
            picture
            status
            category {
                nameEn
                descriptionEn
                region
            }
        }
    }
`;

export const ReadOfferServices = gql`
    query readOfferServices(
        $offerId: ID!
    ) {
        offerServices: readOfferServices(
            offerId: $offerId
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            url
            email
            emailDescriptionEn
            emailDescriptionNo
            emailDescriptionSv
            phone
            createdAt
            updatedAt
        }
    }
`;

export const ReadOfferById = gql`
    query readOfferById($offerId: ID!) {
        offer: readOfferById(offerId: $offerId) {
            id
            name
            descriptionEn
            descriptionNo
            descriptionSv
            picture
            status
            url
            createdAt
            updatedAt
            category {
                id
                nameEn
                nameNo
                nameSv
                descriptionEn
                descriptionNo
                descriptionSv
                position
                createdAt
                updatedAt
                region
            }
            services {
                id
                nameEn
                nameNo
                nameSv
                descriptionEn
                descriptionNo
                descriptionSv
                url
                email
                emailDescriptionEn
                emailDescriptionNo
                phone
                createdAt
                updatedAt
                region
            }
        }
    }
`;

export const ReadOfferServiceById = gql`
    query readOfferServiceById($offerServiceId: ID!) {
        service: readOfferServiceById(offerServiceId: $offerServiceId) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            url
            email
            emailDescriptionEn
            emailDescriptionNo
            emailDescriptionSv
            phone
            createdAt
            updatedAt
            region
        }
    }
`;

export const ReadOfferCategories = gql`
    query readOfferCategories{
        categories: readOfferCategories {
            id
            nameEn
            nameNo
            nameSv
            region
            descriptionEn
            descriptionNo
            descriptionSv
            createdAt
            updatedAt
        }
    }
`;

export const ReadOfferCategoryById = gql`
    query readOfferCategoryById($offerCategoryId: ID!) {
        category: readOfferCategoryById(offerCategoryId: $offerCategoryId) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            position
            region
            createdAt
            updatedAt
        }
    }
`

export const CreateOfferCategory = gql`
    mutation createOfferCategory(
        $nameEn: String!
        $nameNo: String!
        $nameSv: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
        $region: String!
    ){
        category: createOfferCategory(
            nameEn: $nameEn
            nameNo: $nameNo
            nameSv: $nameSv
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
            region: $region
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            region
            position
            createdAt
            updatedAt
        }
    }
`;

export const UpdateOfferCategory = gql`
    mutation updateOfferCategory(
        $offerCategoryId: ID!
        $nameEn: String!
        $nameNo: String!
        $nameSv: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
    ){
        offer: updateOfferCategory(
            offerCategoryId: $offerCategoryId
            nameEn: $nameEn
            nameNo: $nameNo
            nameSv: $nameSv
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            position
            region
            createdAt
            updatedAt
        }
    }
`;

export const ArrangeOfferCategories = gql`
    mutation arrangeOfferCategories(
        $positions: [OfferCategoryNewPosition!]
    ){
        categories: arrangeOfferCategories(
            positions: $positions
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            position
            region
            createdAt
            updatedAt
        }
    }
`;

export const DeleteOfferCategory = gql`
    mutation deleteOfferCategory(
        $offerCategoryId: ID!
    ) {
        remainingCategories: deleteOfferCategory(
            offerCategoryId: $offerCategoryId
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            position
            region
            createdAt
            updatedAt
        }
    }
`;

export const CreateOffer = gql`
    mutation createOffer(
        $name: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
        $pictureBase64: String!
        $url: String!
        $categoryId: ID!
    ){
        offer: createOffer(
            name: $name
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
            pictureBase64: $pictureBase64
            url: $url
            categoryId: $categoryId
        ) {
            category {
                id
                nameEn
                nameNo
                nameSv
                descriptionEn
                descriptionNo
                descriptionSv
                createdAt
                updatedAt
            }
            id
            name
            descriptionEn
            descriptionNo
            descriptionSv
            picture
            status
            url
            createdAt
            updatedAt
        }
    }
`;

export const CreateOfferService = gql`
    mutation createOfferService(
        $offerId: ID!
        $nameEn: String!
        $nameNo: String!
        $nameSv: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
        $url: String
        $email: String
        $emailDescriptionEn: String
        $emailDescriptionNo: String
        $emailDescriptionSv: String
        $phone: String
    ){
        offerService: createOfferService(
            offerId: $offerId
            nameEn: $nameEn
            nameNo: $nameNo
            nameSv: $nameSv
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
            url: $url
            email: $email
            emailDescriptionEn: $emailDescriptionEn
            emailDescriptionNo: $emailDescriptionNo
            emailDescriptionSv: $emailDescriptionSv
            phone: $phone
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            url
            email
            emailDescriptionEn
            emailDescriptionNo
            emailDescriptionSv
            phone
            createdAt
            updatedAt
        }
    }
`;

export const UpdateOffer = gql`
    mutation updateOffer(
        $offerId: ID!
        $categoryId: ID!
        $name: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
        $status: OfferStatus!
        $pictureBase64: String
        $url: String!
    ) {
        result: updateOffer(
            offerId: $offerId
            categoryId: $categoryId
            name: $name
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
            status: $status
            pictureBase64: $pictureBase64
            url: $url
        ) {
            category {
                id
                nameEn
                nameNo
                descriptionEn
                descriptionNo
                descriptionSv
                createdAt
                updatedAt
            }
            id
            name
            descriptionEn
            descriptionNo
            descriptionSv
            picture
            status
            url
            createdAt
            updatedAt
        }
    }`;

export const UpdateOfferService = gql`
    mutation updateOfferService(
        $offerId: ID!
        $offerServiceId: ID!
        $nameEn: String!
        $nameNo: String!
        $nameSv: String!
        $descriptionEn: String!
        $descriptionNo: String!
        $descriptionSv: String!
        $url: String
        $email: String
        $emailDescriptionEn: String
        $emailDescriptionNo: String
        $emailDescriptionSv: String
        $phone: String
    ){
        offerService: updateOfferService(
            offerId: $offerId
            offerServiceId: $offerServiceId
            nameEn: $nameEn
            nameNo: $nameNo
            nameSv: $nameSv
            descriptionEn: $descriptionEn
            descriptionNo: $descriptionNo
            descriptionSv: $descriptionSv
            url: $url
            email: $email
            emailDescriptionEn: $emailDescriptionEn
            emailDescriptionNo: $emailDescriptionNo
            emailDescriptionSv: $emailDescriptionSv
            phone: $phone
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            url
            email
            emailDescriptionEn
            emailDescriptionNo
            emailDescriptionSv
            phone
            createdAt
            updatedAt
        }
    }
`;

export const DeleteOffer = gql`
    mutation deleteOffer($offerId: ID!, $categoryId: ID!) {
        offers: deleteOffer(offerId: $offerId, categoryId: $categoryId) {
            category {
                id
                nameEn
                nameNo
                nameSv
                descriptionEn
                descriptionNo
                descriptionSv
                createdAt
                updatedAt
            }
            id
            name
            descriptionEn
            descriptionNo
            descriptionSv
            picture
            status
            url
            createdAt
            updatedAt
        }
    }
`;

export const DeleteOfferService = gql`
    mutation deleteOfferService(
        $offerId: ID!
        $offerServiceId: ID!
    ) {
        remainingOfferServices: deleteOfferService(
            offerId: $offerId
            offerServiceId: $offerServiceId
        ) {
            id
            nameEn
            nameNo
            nameSv
            descriptionEn
            descriptionNo
            descriptionSv
            url
            email
            phone
            createdAt
            updatedAt
        }
    }
`;

export const ReadOfferLeadsStatistics = gql`
    query readOfferLeadsStatistics (
        $fromDate: DateTime!
        $toDate: DateTime!
    ){
        stats: readOfferLeadsStatistics (
            fromDate: $fromDate
            toDate: $toDate
        ) {
            offers {
                id
                name
                viewCount
                clickCount
                emailSubmitCount
                services {
                    id
                    name
                    clickCount
                    emailSubmitCount
                }
            }
            totalViewCount
            totalClickCount
            totalEmailSubmitCount
        }
    }
`;
