import React from 'react';
import {Container} from "react-bootstrap";
import {UsersOverview} from "../component/UsersOverview";
import '../index.css';

export default () => {
    return (
        <Container className='userContainer' >
            <UsersOverview/>
        </Container>
    );
}

