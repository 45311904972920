import React, {useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import {toBase64} from "../core/utils";
import {colors} from "../theme";

const DragAndDropStyle = {
    borderStyle: "dashed",
    borderSpacing: "5px",
    borderColor: colors.grey,
    color: colors.black,
    cursor: "pointer",
    margin: 0,
    padding: "1em"
};

interface IProps {
    onSubmit: (pictureBase64: string) => void;
}

export const FormPictureUpdate = ({onSubmit}: IProps) => {

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            return;
        } else if (acceptedFiles.length > 1) {
            alert("Only one picture at a time! 🙌🏻");
            return;
        }

        try {
            const pictureBase64 = await toBase64(acceptedFiles[0]);
            onSubmit(pictureBase64);
        } catch (e) {
            alert(e);
        }
    }, [onSubmit]);

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <>
            <div {...getRootProps()} style={DragAndDropStyle}>
                <input {...getInputProps()} />
                <p style={{margin: 0, textAlign: "center"}}>Drag and drop the picture here.
                    <br/>
                    (100x100 jpeg/png)
                </p>
            </div>
        </>
    );
};
