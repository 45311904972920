import React from 'react';
import {Container} from "react-bootstrap";
import {OfferDetail} from "../component/OfferDetail";
import {useHistory} from "react-router-dom";

export const OfferCreationPage = () => {

    const history = useHistory();

    return (
        <Container>
            <OfferDetail
                onCreatedOffer={() => {
                    history.goBack();
                }}
            />
        </Container>
    );
}


