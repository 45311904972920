import gql from "graphql-tag";

export const HandlePEPRequest = gql`
    mutation handlePEPRequest($userId: ID!, $decision: PEPDecision!) {
        result: handlePEPRequest(userId: $userId, decision: $decision) {
            einvoice {
                acceptAllIssuers
            }
            pep {
                status
            }
        }
    }`;
