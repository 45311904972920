import React from 'react';
import {Container, Image} from "react-bootstrap";
import unauthorised from "../theme/image/unauthorised.gif";

export const NotAccessiblePage = () => {

    // const location = useLocation();
    // const {authenticated} = useContext(AuthContext)
    //
    // if (!authenticated) {
    //     const currentLocation = encodeURIComponent(location.pathname);
    //     const options = location.pathname !== '/' ? `?redirect=${currentLocation}` : ''
    //     return <Redirect to={`/login${options}`}/>
    // }

    return (
        <Container>
            <h1>Unauthorised</h1>
            <Image srcSet={unauthorised} alt="not found"/>
        </Container>
    );
};



