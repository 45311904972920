import React, {FormEvent, useMemo} from 'react';
import Moment from "react-moment";
import {Table, Button} from "react-bootstrap";
import {Error} from "./Error";
import {FormBalanceSnapshot} from "./FormBalanceSnapshot";
import {useMutation, useQuery} from "@apollo/client";
import {CreateBalanceSnapshot, ReadBalanceSnapshots, RemoveBalanceSnapshot} from "../graphql/invoiceTool";
import {
    BalanceSnapshot,
    CreateBalanceSnapshotMutation,
    CreateBalanceSnapshotMutationVariables,
    ReadBalanceSnapshotsQuery,
    ReadBalanceSnapshotsQueryVariables,
    RemoveBalanceSnapshotMutation,
    RemoveBalanceSnapshotMutationVariables,
} from "../types/graphql";
import {LoadingPage} from "../page/loading";

export const SnapshotsTable = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>(ReadBalanceSnapshots, {
        variables: {
            first: 30
        }
    });

    const [createBalanceSnapshot, {
        loading: loadingCreation,
        error: errorCreation
    }] = useMutation<CreateBalanceSnapshotMutation, CreateBalanceSnapshotMutationVariables>(CreateBalanceSnapshot, {
        refetchQueries: [
            {query: ReadBalanceSnapshots}
        ]
    })

    const [deleteBalanceSnapshot, {
        loading: loadingDeletion,
        error: errorDeletion
    }] = useMutation<RemoveBalanceSnapshotMutation, RemoveBalanceSnapshotMutationVariables>(RemoveBalanceSnapshot, {
        refetchQueries: [
            {query: ReadBalanceSnapshots}
        ]
    })

    const rows = useMemo(() => {
        if (!data?.result?.edges) return null;
        return data?.result.edges.map((vv, i) => {
            const v = vv?.node as BalanceSnapshot;
            return (
                <tr key={i}>
                    <td>
                        <Moment format="DD MMMM YYYY">{v.time}</Moment>
                    </td>
                    <td>{v.amount} kr</td>
                    <td>
                        <Button variant="danger" onClick={async (e: FormEvent) => {
                            e.preventDefault();
                            await deleteBalanceSnapshot({
                                variables: {
                                    snapshotId: v.id.toString()
                                }
                            })
                        }}>
                            Remove
                        </Button>
                    </td>
                </tr>
            );
        });
    }, [data, deleteBalanceSnapshot]);

    if (loading || loadingCreation || loadingDeletion) {
        return <LoadingPage/>;
    }

    return (
        <>
            <Error error={error?.graphQLErrors[0].message}/>
            <Error error={errorCreation?.graphQLErrors[0].message}/>
            <Error error={errorDeletion?.graphQLErrors[0].message}/>

            <Table responsive bordered hover>
                <thead>
                <tr>
                    <th>Time</th>
                    <th>Amount</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>

            <FormBalanceSnapshot onSubmit={async (amount, time) => {
                await createBalanceSnapshot({
                    variables: {
                        amount,
                        time: time.toISOString()
                    }
                })
            }}/>

        </>
    );
}

