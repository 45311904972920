import gql from "graphql-tag";
import {UserFragments} from "./fragments/user";

export const ReadUsers = gql`
    query readAllUsers(
        $first: Int,
        $last: Int,
        $before: String,
        $after: String,
        $filterByName: String,
        $filterByCheckedEmail: Boolean,
        $filterByCheckedMobile: Boolean,
        $filterByActivity: UserActivityEnum,
        $filterByRegion: RegionEnum,
        $descId: Boolean,
        $acceptEfaktura: Boolean
    ) {
        result: readAllUsers(
            first: $first,
            last: $last, before: $before,
            after: $after,
            filterByName: $filterByName,
            filterByCheckedEmail: $filterByCheckedEmail,
            filterByCheckedMobile: $filterByCheckedMobile,
            filterByActivity: $filterByActivity,
            filterByRegion: $filterByRegion,
            descId: $descId,
            acceptEfaktura: $acceptEfaktura
        ) {
            edges{
                cursor
                node {
                    ...UserResult
                    region
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }
    ${UserFragments.result}
`;

export const ReadUserById = gql`
    query readUserById($userId: ID!) {
        result: readUserById(userId: $userId) {
            ...UserDetail
        }
    }
    ${UserFragments.detailed}
`;

export const ReadUserActivities = gql`
    query readUserActivities($userId: ID!, $first: Int, $last: Int, $before: String, $after: String) {
        result: readUserActivities(userId: $userId, first: $first, last: $last, before: $before, after: $after) {
            edges{
                node {
                    time
                    component
                    event
                    status
                    error
                    details {context, stackTrace}
                    userId
                    issuerId
                    cardId
                    invoiceId
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }`;

export const ReadEmailsForNewsletter = gql`
    query readEmailsForNewsletter(
        $acceptEInvoice: Boolean,
        $filterByActivity: UserActivityEnum,
        $filterByCheckedMobile: Boolean,
        $filterByName: String,
        $descId: Boolean,
        $isDeleted: Boolean
    ) {
        readEmailsForNewsletter(
            filterByName: $filterByName,
            filterByCheckedMobile: $filterByCheckedMobile,
            filterByActivity: $filterByActivity,
            acceptEInvoice: $acceptEInvoice
            descId: $descId
            isDeleted: $isDeleted
        ) {
            emails
        }
    }
`;


export const UpdateUserStatus = gql`
    mutation updateUserStatus ($userId: ID!, $status: UserStatusEnum!) {
        user: updateUserStatus(userId: $userId, status: $status) {
            ...UserDetail
        }
    }
    ${UserFragments.detailed}
`
