import React, { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactJson from 'react-json-view';
import { log, logT, ComponentEnum } from "../log";

const Component = ComponentEnum.InvoiceHistoryDetail

interface IProps {
    children: any;
}
const searchJson = (obj: { [key: string]: any }, query: string): { [key: string]: any } => {
    let result: { [key: string]: any } = {};
    const loweredQuery = query.toLowerCase();

    Object.keys(obj).forEach(key => {
        const loweredKey = key.toLowerCase();
        const value = obj[key];
        const stringValue = String(value);
        const loweredValue = stringValue.toLowerCase();

        if (loweredKey.includes(loweredQuery) || loweredValue.includes(loweredQuery)) {
            result[key] = value;
        } else if (typeof value === "object" && value && !Array.isArray(value)) {
            const nestedResult = searchJson(value, loweredQuery);
            if (Object.keys(nestedResult).length > 0) {
                result[key] = nestedResult;
            }
        }
    });
    return result;
};




export const InvoiceHistoryDetail = ({ children }: IProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchResult, setSearchResult] = useState<{ [key: string]: any } | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (typeof details.json === 'object' && details.json) {
            setSearchResult(searchJson(details.json, query));
        }
    };

    const details = useMemo(() => {
        if (children == null)
            return {
                str: "[empty - no details]",
                json: {},
                isJson: false,
                disableButton: true
            };
        try {
            let root = JSON.parse(children?.toString());
            let contextStr = root.context;
            let j = JSON.parse(contextStr);
            return {
                str: JSON.stringify(j),
                json: j,
                isJson: true,
                disableButton: false
            };
        } catch (e) {
            let value = children?.toString();
            return {
                str: value || "[empty - no details]",
                json: {},
                isJson: false,
                disableButton: !value || value.length === 0 || value === "null"
            };
        }
    }, [children]);

    const copyToClipboard: React.MouseEventHandler<HTMLElement> = (event) => {
        event.preventDefault();
        if (!navigator.clipboard) {
            log(logT.ERROR, Component, "Navigator clipboard is not supported");
            return;
        }
        navigator.clipboard.writeText(details.isJson ? JSON.stringify(details.json, null, 2) : details.str).then(
            function () {
                log(logT.INFO, Component, "Async: Copying to clipboard was successful!");
            },
            function (err) {
                log(logT.ERROR, Component, "Async: Could not copy text: ", err)
                return;
            }
        );

        setShow(false);
    };

    return (
        <>
            <Button variant="primary" onClick={() => setShow(true)} disabled={details.disableButton}
                    hidden={details.disableButton}>
                Open details
            </Button>

            <Modal show={show} size="xl" onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transaction Detail (Context)</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', maxHeight: '80vh' }}>
                    <div style={{ marginTop: '10px', marginBottom: '15px'}}>
                        <input
                            type="text"
                            id="searchInput"
                            placeholder="Filter on value or property"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    {details.isJson ? (
                        searchResult ? <ReactJson src={searchResult} theme="monokai"/> :
                            <ReactJson src={details.json} theme="monokai"/>
                    ) : (
                        <code>
                            {details.str}
                        </code>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={copyToClipboard}>
                        Copy to clipboard
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
