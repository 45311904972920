import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { AdminChangeSubscription, ReadUserSubscriptionsByUserId } from "../graphql/subscription";
import {
    AdminChangeSubscriptionMutation,
    AdminChangeSubscriptionMutationVariables,
    UserSubscription
} from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";

interface IProps {
    userSubscription: UserSubscription | null
    onHide: () => void
    onChanged: () => void
}

export const ModalChangeSubscription = ({ userSubscription, onHide, onChanged }: IProps) => {

    const [endsAt, setEndsAt] = useState<string>('');
    const [autoRenewal, setAutoRenewal] = useState<boolean>(false);

    useEffect(() => {
        setEndsAt(userSubscription?.endsAt ? userSubscription?.endsAt.split('T')[0] : '')
        setAutoRenewal(userSubscription?.autoRenewal === 'on')
    }, [userSubscription])

    const [changeSubscription, { loading: loadingChange, error: errorChange }] =
        useMutation<
          AdminChangeSubscriptionMutation,
          AdminChangeSubscriptionMutationVariables
        >(AdminChangeSubscription);

    return (
        <Modal show={!!userSubscription} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Modify subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Error error={errorChange?.graphQLErrors[0].message} />
                <Loading isLoading={loadingChange} />

                <p>
                    You may stop or reactivate a the subscription period by disabling or enabling auto-renewal,
                    and you may lengthen or shorten the subscription period by modifying the end date.
                </p>

                <p>
                    But note that modifying the subscription period length will <strong>NOT have any financial impact</strong>,
                    i.e. the user has still paid what the user has paid, and no extra charge or refund will be made.
                </p>

                <Form noValidate>
                    <Form.Label>End date:</Form.Label>
                    <Form.Group as={Row} controlId="form">
                        <Col sm={12} md={"auto"}>
                            <Form.Control
                                required
                                type="string"
                                placeholder="YYYY-MM-DD"
                                value={endsAt}
                                onChange={e => {
                                    setEndsAt(e.currentTarget.value)
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Label>Auto renewal:</Form.Label>
                    <Form.Group as={Row} controlId="form2">
                        <Col sm={12} md={"auto"}>
                            <Form.Check
                              required
                              label={"Auto renewal"}
                              checked={autoRenewal}
                              onChange={e => {
                                  setAutoRenewal(e.currentTarget.checked)
                              }}
                            />
                        </Col>
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={async () => {
                        if (userSubscription) {
                            try {
                                await changeSubscription({
                                    variables: {
                                        userId: userSubscription.userId,
                                        userSubscriptionId: userSubscription.id,
                                        endsAt: endsAt,
                                        autoRenewal: autoRenewal
                                    }
                                });
                                onHide();
                                onChanged();
                            } catch (e) {
                                console.error('error changing subscription:', e)
                            }
                        }
                    }}>
                    Change subscription
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
