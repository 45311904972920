import React from 'react';
import {Container} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {OfferCategoryDetail} from "../component/OfferCategoryDetail";

interface IParams {
    categoryId: string
}

export const OfferCategoryDetailPage = () => {

    const history = useHistory();
    const {categoryId} = useParams<IParams>();

    return (
        <Container>
            <OfferCategoryDetail
                createMode={false}
                categoryId={categoryId}
                onUpdated={() => {
                    history.goBack();
                }}
                onDeleted={() => {
                    history.goBack();
                }}
            />
        </Container>
    );
}


