import React, { useEffect, useState } from 'react';
import { Container, Card, Form } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { ReadSystemZenExtraBalance } from "../graphql/zenPoints";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Error } from "../component/Error";
import { useApolloClient } from '@apollo/client';
import { IStatisticsChartData } from "../helper/system";
import Chart from "react-google-charts";
import Spinner from "react-bootstrap/Spinner";

export default () => {
    const client = useApolloClient();
    const [date, setDate] = useState<Date>(new Date());
    const [xDays, setXDays] = useState<number>(10);
    const [chartData, setChartData] = useState<IStatisticsChartData>();

    useEffect(() => {
        fetchLastXDays(xDays).then(data => {
            setChartData(data);
        });
    }, [date]);

    const { loading, error, data } = useQuery(ReadSystemZenExtraBalance, {
        variables: { date },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    },);

    const FetchZenExtraBalance = async (queryDate: Date) => {
        try {
            const result = await client.query({
                query: ReadSystemZenExtraBalance,
                variables: { date: queryDate },
                fetchPolicy: 'network-only',
            });
            return result.data?.readSystemZenExtraBalance;
        } catch (error) {
            console.error('Error fetching Zen Extra Balance:', error);
        }
    };

    const formatDateToDDMM = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0'); // Gets the day and pads with zero if necessary
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Gets the month (0-11), adds 1, and pads with zero
        return `${day}.${month}`; // Constructs the string in dd.MM format
    };

    const fetchLastXDays = async (x:number) => {
        let labels = ["Date", "Balance"];
        let values = [];
        for (let i = x; i >= 0; i--) { // Fetching data from 6 days ago to today
            let queryDate = new Date(date.getTime());
            queryDate.setDate(queryDate.getDate() - i);
            let formattedDate = formatDateToDDMM(queryDate);
            try {
                let extra = await FetchZenExtraBalance(queryDate);
                values.push([formattedDate, extra || 0]);
            } catch (error) {
                values.push([formattedDate, 0]);
            }
        }
        return { labels, values };
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <Error error={error.graphQLErrors[0].message}/>;

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h1 className="dark">Extra Balance</h1>
                </Card.Header>
                <Card.Body>
                    <DatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={date}
                        onChange={(d: Date) => setDate(d as Date)}
                    />
                    <Form.Control
                        style={{ maxWidth: "6em", fontSize: "1.4em", margin: "1em 0 1em 0" }}
                        type="text"
                        readOnly
                        value={data?.readSystemZenExtraBalance ?? "0"}
                    />
                    {chartData &&
                      <Chart
                        width="100%"
                        height="400px"
                        chartType={"ColumnChart"}
                        loader={<Spinner animation={"grow"}/>}
                        data={[
                            chartData.labels,
                            ...chartData.values
                        ]}
                        options={{
                            title: "Balance Last " + xDays + " Days By " + formatDateToDDMM(date),
                            hAxis: { title: "Given Date", titleTextStyle: { color: '#333' } },
                            vAxis: { minValue: 0 },
                            chartArea: { width: '60%', height: '60%' },
                        }}
                      />
                    }
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>

        </Container>
    );

};


