import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'
import Select, { ValueType } from 'react-select'

import { IssuerResultFragment, ReadAllIssuersQuery, ReadAllIssuersQueryVariables } from '../types/graphql'
import { ReadIssuers } from '../graphql/issuer'
import { FormSearchByString } from './FormSearchByString'
import { Error } from './Error'
import { Loading } from './Loading'
import { IssuerPicture } from './IssuerPicture'
import { RegionFlag } from "./RegionFlag";

type OptionType = {
    value: 'noEmail' | 'noCreditorEmailLoan' | 'noPicture' | 'existingAccount' | 'allowingAmex' | 'allowingCreditCards' | 'noRealOrgNo' | 'noMcc' | 'sweden' | 'norway';
    label: string;
};

type SearchByOptionType = {
    value: 'name' | 'orgNumber' | 'mccNumber' | 'accountNumber';
    label: string;
};

const searchByOptions: SearchByOptionType[] = [
    { value: 'name', label: 'By Name' },
    { value: 'orgNumber', label: 'By Org.Number' },
    { value: 'mccNumber', label: 'By MCC Number' },
    { value: 'accountNumber', label: 'By Account number' },
];

const defaultOption: SearchByOptionType = { value: 'name', label: 'By Name' };


const filterOptions: OptionType[] = [
    { value: 'noEmail', label: 'Without email' },
    { value: 'noCreditorEmailLoan', label: 'Creditors without email for loans' },
    { value: 'noPicture', label: 'Without picture' },
    { value: 'existingAccount', label: 'With account' },
    { value: 'allowingAmex', label: 'Allowing Amex' },
    { value: 'allowingCreditCards', label: 'Allowing Credit Cards' },
    { value: 'noRealOrgNo', label: 'No real org. no.' },
    { value: 'noMcc', label: 'No MCC' },
    { value: 'sweden', label: 'Swedish' },
    { value: 'norway', label: 'Norwegians' },
]

export const IssuersOverview = () => {
    const isFirstFetch = useRef(true)
    const isChangedFetch = useRef(false)
    const [items, setItems] = useState<Array<IssuerResultFragment>>([])
    const [searchType, setSearchType] = useState<string>("name");
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

    // API
    const [readIssuers, { data, loading, error }] = useLazyQuery<ReadAllIssuersQuery,
        ReadAllIssuersQueryVariables>(ReadIssuers, { fetchPolicy: 'network-only' })

    // Filters
    const [filterByName, setFilterByName] = useState<string>()
    const [filterByNoEmail, setFilterByNoEmail] = useState<boolean>()
    const [filterByNoCreditorEmailLoan, setFilterByNoCreditorEmailLoan] = useState<boolean>()
    const [filterByNoPicture, setFilterByNoPicture] = useState<boolean>()
    const [filterByExistingAccount, setFilterByExistingAccount] = useState<boolean>()
    const [filterByAllowingAmex, setFilterByAllowingAmex] = useState<boolean>()
    const [filterByAllowingCreditCards, setFilterByAllowingCreditCards] = useState<boolean>()
    const [filterByNoRealOrgNo, setFilterByNoRealOrgNo] = useState<boolean>()
    const [filterByNoMcc, setFilterByNoMcc] = useState<boolean>()
    const [filterByRegion, setFilterByRegion] = useState<string>('')

    const fetchData = useCallback(
        async (resetCursor?: boolean) => {
            let after = data?.result?.pageInfo?.endCursor
            if (resetCursor) {
                setItems([])
                after = null
            }
            await readIssuers({
                variables: {
                    first: 25,
                    after,
                    searchType,
                    filterByName,
                    filterByNoEmail,
                    filterByNoCreditorEmailLoan,
                    filterByNoPicture,
                    filterByExistingAccount,
                    filterByAllowingAmex,
                    filterByAllowingCreditCards,
                    filterByNoRealOrgNo,
                    filterByNoMcc,
                    filterByRegion,
                },
            })
        },
        [
            data,
            searchType,
            filterByName,
            filterByNoEmail,
            filterByNoCreditorEmailLoan,
            filterByNoPicture,
            filterByNoRealOrgNo,
            filterByNoMcc,
            filterByRegion,
            readIssuers,
        ],
    )

    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData()
            isFirstFetch.current = false
        }
    }, [fetchData])

    useEffect(() => {
        if (isChangedFetch.current) {
            fetchData(true)
            console.debug('Fetching data')
            isChangedFetch.current = false
        }
    }, [fetchData, isChangedFetch])

    useEffect(() => {
        const edges = data?.result?.edges
        if (!loading && !error && edges) {
            setItems((prev) => [
                ...prev,
                ...edges.map((v) => v?.node as IssuerResultFragment),
            ])
        }
    }, [loading, error, data])

    const loadMoreOnScroll = () => {
        setTimeout(() => {
            fetchData()
        }, 500)
    }

    const resetFilter = () => {
        setFilterByNoEmail(false)
        setFilterByNoCreditorEmailLoan(false)
        setFilterByNoPicture(false)
        setFilterByExistingAccount(false)
        setFilterByAllowingAmex(false)
        setFilterByAllowingCreditCards(false)
        setFilterByNoRealOrgNo(false)
        setFilterByNoMcc(false)
        setFilterByRegion('')
    }

    const itemsToRender = useMemo(() => {
        return items.map((v) => (
            <tr
                key={v.id}
            >
                <td>
                    <a href={`/issuers/${v.id}`} target="_blank">
                        {v.id}
                    </a>
                </td>
                <td>
                    <IssuerPicture picture={v.picture}/>
                </td>
                <td>
                    <a href={`/issuers/${v.id}`} target="_blank">
                        {v.name}
                    </a>
                </td>
                <td>
                    <a href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${v.orgNumber}`} target="_blank">
                        {v.orgNumber}
                    </a>
                </td>
                <td>
                    <a href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${v.realOrgNumber}`} target="_blank">
                        {v.realOrgNumber}
                    </a>
                </td>
                <td>
                    <span
                        style={v.subMerchantMcc ? { color: '#ff00ff' } : { display: 'none' }}>{v.subMerchantMcc}</span>
                    <span
                        style={v.implicitSubMerchantMcc ? { color: '#00aa22' } : { display: 'none' }}>{v.implicitSubMerchantMcc}</span>
                </td>
                <td style={{ textAlign: 'center', fontSize: '1.8em' }}>
                    <RegionFlag region={v.region} normalSize hideText />
                </td>
            </tr>
        ))
    }, [items])

    return (
        <Card>
            <Card.Header>
                <h5 className="dark">Issuers</h5>
            </Card.Header>
            <Card.Header>
                <Row>
                    <Col sm={12} md={6} lg={4}>
                        <FormSearchByString
                            placeholder="Search value"
                            onSubmit={async (name) => {
                                setFilterByName(name)
                                isChangedFetch.current = true
                            }}
                        />
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <Select
                            defaultValue={defaultOption}
                            onChange={(v: ValueType<SearchByOptionType>) => {
                                setSearchType((v as SearchByOptionType).value);
                                console.log("Triggering search by", v)
                                isChangedFetch.current = true
                            }}
                            placeholder={"Search by"}
                            options={searchByOptions}
                            className="basic-single-select"
                            classNamePrefix="select"
                        />
                    </Col>

                    <Col sm={12} md={6} lg={4}>
                        <Select
                            defaultValue={null}
                            // TODO: See issue: https://github.com/JedWatson/react-select/issues/3632
                            onChange={(v: any) => {
                                resetFilter();

                                const selectValues = Array.isArray(v) ? v.map(option => option.value) : [];

                                const selectedOptions = filterOptions.filter(option => selectValues.includes(option.value));
                                setSelectedOptions(selectedOptions);

                                setFilterByNoEmail(selectValues.includes('noEmail'));
                                setFilterByNoRealOrgNo(selectValues.includes('noRealOrgNo'));
                                setFilterByNoMcc(selectValues.includes('noMcc'));
                                setFilterByNoCreditorEmailLoan(selectValues.includes('noCreditorEmailLoan'));
                                setFilterByNoPicture(selectValues.includes('noPicture'));
                                setFilterByExistingAccount(selectValues.includes('existingAccount'));
                                setFilterByAllowingAmex(selectValues.includes('allowingAmex'));
                                setFilterByAllowingCreditCards(selectValues.includes('allowingCreditCards'));
                                if (selectValues.includes('sweden') && selectValues.includes('norway')) {
                                    setFilterByRegion('');
                                } else if (selectValues.includes('sweden')) {
                                    setFilterByRegion('sv');
                                } else if (selectValues.includes('norway')) {
                                    setFilterByRegion('no');
                                } else {
                                    setFilterByRegion('');
                                }
                                isChangedFetch.current = true;
                            }}

                            value={selectedOptions}
                            placeholder={'Filter by'}
                            options={filterOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                            isMulti
                        />
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Error error={error?.graphQLErrors[0].message}/>
                <InfiniteScroll
                    hasMore={data?.result?.pageInfo.hasNextPage || false}
                    loader={<Loading isLoading/>}
                    dataLength={items.length}
                    next={loadMoreOnScroll}
                >
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Org. no.</th>
                            <th>Real org. no.</th>
                            <th>MCC</th>
                            <th>Region</th>
                        </tr>
                        </thead>
                        <tbody>{itemsToRender}</tbody>
                    </Table>
                </InfiniteScroll>
            </Card.Body>
        </Card>
    )
}
