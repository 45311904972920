import React from 'react';
import {Container} from "react-bootstrap";
import {OffersOverview} from "../component/OffersOverview";

export default () => {
    return (
        <Container>
            <OffersOverview/>
        </Container>
    );
}


