import React, { useMemo } from 'react';
import { Button, Alert, ButtonGroup } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { RestartRemittance, StopRemittance } from "../graphql/invoiceTool";
import {
    RemitStatus,
    RestartRemittanceMutation, RestartRemittanceMutationVariables,
    StopRemittanceMutation,
    StopRemittanceMutationVariables, TransactionStatus
} from "../types/graphql";
import { Loading } from "./Loading";
import { Error } from "./Error";

interface IProps {
    invoiceId: string;
    status: TransactionStatus;
    remitStatus: RemitStatus;
}

export const InvoiceActions = ({ invoiceId, status, remitStatus }: IProps) => {

    const [stopRemittance, {
        data: dataStopRemittance,
        loading: loadingStopRemittance,
        error: errorStopRemittance
    }] = useMutation<StopRemittanceMutation, StopRemittanceMutationVariables>(StopRemittance);

    const [restartRemittance, {
        data: dataRestartRemittance,
        loading: loadingRestartRemittance,
        error: errorRestartRemittance
    }] = useMutation<RestartRemittanceMutation, RestartRemittanceMutationVariables>(RestartRemittance);

    const alerts = useMemo(() => {
        if (dataStopRemittance?.result?.isOk)
            return <Alert variant="success">Invoice remittance has been stopped!</Alert>;
        if (dataRestartRemittance?.result?.isOk)
            return <Alert variant="success">Invoice remittance has been restarted!</Alert>;
    }, [dataStopRemittance, dataRestartRemittance]);

    const isPaid = status === "paid";
    const isRemittanceStoppable = isPaid && (remitStatus === RemitStatus.New || remitStatus === RemitStatus.Rejected);
    const isRemittanceRestartable = isPaid && (remitStatus === RemitStatus.Stopped);

    return (
        <>

            <Loading isLoading={loadingStopRemittance || loadingRestartRemittance} />

            <Error error={
                errorStopRemittance?.graphQLErrors[0].message
                ||
                errorRestartRemittance?.graphQLErrors[0].message}
            />

            {alerts}

            <ButtonGroup>
                <Button
                    variant={isRemittanceStoppable ? "primary" : "light"}
                    disabled={!isRemittanceStoppable}
                    onClick={async () => {
                        await stopRemittance({
                            variables: {
                                invoiceId
                            }
                        })
                    }}
                >
                    Stop remittance
                </Button>

                <Button
                    variant={isRemittanceRestartable ? "primary" : "light"}
                    disabled={!isRemittanceRestartable}
                    onClick={async () => {
                        await restartRemittance({
                            variables: {
                                invoiceId
                            }
                        })
                    }}
                >
                    Restart remittance
                </Button>
            </ButtonGroup>
        </>
    );

}
