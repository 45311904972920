import React from "react";
import Alert from "react-bootstrap/Alert";

interface OnErrorProps {
    error?: string
}

export const Error = ({error}: OnErrorProps) => {
    if (!error) return null;
    return (
        <Alert key="graphqlError" variant="danger">
            {error}
        </Alert>
    );
};


