import React, { useMemo, useState, useEffect } from "react";
import Moment from "react-moment";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ReadUserSubscriptionsByUserIdQuery,
  ReadUserSubscriptionsByUserIdQueryVariables,
  RefundSubscriptionMutation,
  RefundSubscriptionMutationVariables,
  SubscriptionAutoRenewal,
  UserSubscription,
} from "../types/graphql";
import { Error } from "./Error";
import {
  ReadUserSubscriptionsByUserId,
  RefundSubscription,
} from "../graphql/subscription";
import { BadgeBool } from "./BadgeBool";
import { Loading } from "./Loading";
import { ModalChangeSubscription } from "./ModalChangeSubscription";

interface IProps {
  userId: string;
}

export const UserSubscriptions = ({ userId }: IProps) => {
  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>();
  const [userSubscriptionToModify, setUserSubscriptionToModify] = useState<UserSubscription | null>(null);
  const [amount, setAmount] = useState<string>('');

  useEffect(() => {
    setAmount(userSubscription?.subscription?.amount ? '' + userSubscription?.subscription?.amount : '')
  }, [userSubscription])


  const hideRefundModal = () => {
    setUserSubscription(null);
  };

  const hideModifyModal = () => {
    setUserSubscriptionToModify(null);
  }

  const [loadUserSubscriptions, { data, loading, error }] = useLazyQuery<
    ReadUserSubscriptionsByUserIdQuery,
    ReadUserSubscriptionsByUserIdQueryVariables
  >(ReadUserSubscriptionsByUserId, {
    variables: {
      userId,
      first: 5
    }
  });

  const [refundSubscription, { loading: loadingRefund, error: errorRefund }] =
    useMutation<
      RefundSubscriptionMutation,
      RefundSubscriptionMutationVariables
    >(RefundSubscription, {
      refetchQueries: [
        {
          query: ReadUserSubscriptionsByUserId,
          variables: {
            userId
          },
        },
      ],
    });

  useEffect(() => {
    loadUserSubscriptions()
  }, [])

  // TODO: Add coupon aggregation (waiting for backend)
  const rows = useMemo(() => {
    if (!data?.userSubscriptions?.edges) return null;
    return data?.userSubscriptions?.edges.map((v, i) => {

      const u = v!.node as UserSubscription
      const s = u.subscription
      const c = u.coupon

      return (
        <tr key={i}>
          <td>
            {s.type} {s.period} month(s)
          </td>
          <td>{s.amount} kr</td>
          <td>
            {c && (
              <p>-{c.amount} kr ({c.code})</p>
            )}
          </td>
          <td>{s.welcomePoints}</td>
          <td>
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={
                <Tooltip id={i.toString()}>
                  <Moment fromNow={true}>{u.startsAt}</Moment>
                </Tooltip>
              }
            >
              <Moment format="DD MMMM YYYY HH:mm:ss">
                {u.startsAt}
              </Moment>
            </OverlayTrigger>
          </td>
          <td>
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={
                <Tooltip id={i.toString()}>
                  <Moment fromNow={true}>{u.endsAt}</Moment>
                </Tooltip>
              }
            >
              <Moment format="DD MMMM YYYY HH:mm:ss">{u.endsAt}</Moment>
            </OverlayTrigger>
          </td>
          <td>
            <BadgeBool
              isOk={u.autoRenewal === SubscriptionAutoRenewal.On}
              textOnTrue={"yes"}
              textOnFalse={"no"}
            />
          </td>
          <td>
            <BadgeBool
              isOk={u.isFreeTrial || false}
              textOnTrue={"yes"}
              textOnFalse={"no"}
            />
          </td>
          <td>
            <BadgeBool
              isOk={u.isRefunded || false}
              textOnTrue={"yes"}
              textOnFalse={"no"}
            />
          </td>
          <td>
            {u.paymentCard.brand} {u.paymentCard.name}
          </td>
          <td>
            {u.renewalCard.brand} {u.renewalCard.name}
          </td>
          <td>
            <Button
              onClick={() => {
                setUserSubscriptionToModify(u);
              }}>
              Modify
            </Button>
            {!u.isRefunded && (
              <Button
                disabled={u.isFreeTrial}
                onClick={() => {
                  setUserSubscription(u);
                }}>
                Refund
              </Button>
            )}
            <br />
          </td>
        </tr>
      );
    });
  }, [data]);

  return (
    <>
      <Error error={error?.graphQLErrors[0].message} />

      <Loading isLoading={loading || loadingRefund} />

      <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Subscription</th>
            <th>Amount</th>
            <th>Coupon</th>
            <th>Points</th>
            <th>Starts on</th>
            <th>Ends on</th>
            <th>Renewal</th>
            <th>Trial</th>
            <th>Refunded</th>
            <th>Card</th>
            <th>Card (renewal)</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {/* TODO: Export as component */}
      <Modal show={!!userSubscription} onHide={hideRefundModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={errorRefund?.graphQLErrors[0].message} />

          <Form noValidate>
            <Form.Label>Amount:</Form.Label>
            <Form.Group as={Row} controlId="form">
              <Col sm={12} md={"auto"}>
                <Form.Control
                  required
                  type="string"
                  placeholder=""
                  value={amount}
                  onChange={e => {
                    setAmount(e.currentTarget.value)
                  }}
                />
              </Col>
            </Form.Group>
          </Form>

          <div>
            If subscription is eligible to refund, the amount of{" "}
            {amount} kr will immediately be
            refunded to the customer to the card{" "}
            {userSubscription?.paymentCard.name}.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideRefundModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              if (userSubscription) {
                await refundSubscription({
                  variables: {
                    userId,
                    userSubscriptionId: userSubscription.id,
                    amount: amount,
                  },
                });
                hideRefundModal();
              }
            }}
          >
            Refund {amount} kr
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalChangeSubscription
        userSubscription={userSubscriptionToModify}
        onHide={hideModifyModal}
        onChanged={() => {
          loadUserSubscriptions()
        }}
      />

    </>
  );
};
