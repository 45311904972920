import gql from "graphql-tag";

export const ReadPspTransactions = gql`
  query readPspTransactions(
    $fromDate: DateTime!
    $toDate: DateTime!
    $isConnected: Int!
    $statusType: String!
    $status: String!
    $type: String!
    $provider: String!
    $search: String!
  ) {
    result: readPspTransactions(
      fromDate: $fromDate
      toDate: $toDate
      isConnected: $isConnected
      statusType: $statusType
      status: $status
      type: $type
      provider: $provider
      search: $search
    ) {
      transactions {
        id,
        remoteCreatedAt,
        localCreatedAt,
        bkInvoiceTransactionId,
        bkUserSubscriptionId,
        bkInvoiceId,
        bkUserId,
        bkType,
        bkStatus,
        bkAction,
        bkProvider,
        transactionNumber,
        paymentMethod,
        type,
        amount,
        vatAmount,
        currency,
        paymentId,
        userEmail,
        userName,
        issuerName,
        subscriptionDuration,
        userSubscriptionStartsAt,
        userSubscriptionEndsAt,
      }
    }
  }
`;

export const ReadPspTransactionsAsExcel = gql`
    query ReadPspTransactionsAsExcel(
      $fromDate: DateTime!, 
      $toDate: DateTime!, 
      $isConnected: Int!, 
      $statusType: String!
      $status: String!
      $type: String!
      $provider: String!
      $search: String!
    ) {
        result: readPspTransactionsAsExcel(
          fromDate: $fromDate, 
          toDate: $toDate, 
          isConnected: $isConnected, 
          statusType: $statusType
          status: $status
          type: $type
          provider: $provider
          search: $search
        ) {
            data
        }
    }`;

export const ReadPspTransactionById = gql`
  query readPspTransactionById(
    $id: Int!
  ) {
    result: readPspTransactionById(
      id: $id
    ) {
        id,
        remoteCreatedAt,
        localCreatedAt,
        bkInvoiceTransactionId,
        bkUserSubscriptionId,
        bkInvoiceId,
        bkUserId,
        bkType,
        bkStatus,
        bkAction,
        bkProvider,
        transactionNumber,
        paymentMethod,
        type,
        amount,
        vatAmount,
        currency,
        paymentId,
        userEmail,
        userName,
        issuerName,
        subscriptionDuration,
        userSubscriptionStartsAt,
        userSubscriptionEndsAt,
    }
  }
`;

export const ReadPspTransactionLogsById = gql`
  query readPspTransactionLogsById(
    $id: Int!
  ) {
    result: readPspTransactionLogsById(
      id: $id
    ) {
      logs {
        time,
        event,
        ipAddress,
        url,
        data,
      }
    }
  }
`;

export const GetPspTransactionIdByInvoiceId = gql`
    query getPspTransactionIdByInvoiceId($invoiceId: String!) {
        pspTransactionId: GetPspTransactionIdByInvoiceId(invoiceId: $invoiceId)
    }
`;
