import React from "react";
import {Code, List} from "react-content-loader";
import Spinner from "react-bootstrap/Spinner";

interface OnLoadingProps {
    isLoading: boolean,
    type?: "spinner" | "spinner-button" | "code" | "list",
    variant?: "light" | "dark",
}

export const Loading = ({isLoading, type, variant}: OnLoadingProps) => {
    let Loader = <Code/>;

    switch (type) {
        case "list":
            Loader = <List/>;
            break;
        case "spinner":
            Loader = <Spinner animation={"grow"} variant={variant}/>;
            break;
        case "spinner-button":
            Loader = <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant={variant}
            />;
            break;
    }

    return (isLoading) ? Loader : null;
}
