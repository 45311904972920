import React, { useCallback, useMemo, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Select, { ValueType } from "react-select";
import { getIconFromEmailCheck, getIconFromPEPStatus } from "../helper/user";
import { RegionEnum, UserActivityEnum, useReadAllUsersQuery } from "../types/graphql";
import { FormSearchByString } from "./FormSearchByString";
import { Loading } from "./Loading";
import { RegionFlag } from "./RegionFlag";

type OptionType = {
    value: boolean;
    label: string;
};

type OptionActivityType = {
    value: UserActivityEnum;
    label: string;
};

const emailOptions: OptionType[] = [
    { value: true, label: "Email checked" },
    { value: false, label: "Email not checked" },
];

const mobileOptions: OptionType[] = [
    { value: true, label: "Mobile checked" },
    { value: false, label: "Mobile not checked" },
];

const activityOptions: OptionActivityType[] = [
    { value: UserActivityEnum.Present, label: "Total Available" },
    { value: UserActivityEnum.Active, label: "Active" },
    { value: UserActivityEnum.Inactive, label: "Inactive" },
    { value: UserActivityEnum.Deleted, label: "Deleted" }
];

interface Props {
    onClickUser: (userId: string) => void;
    region: RegionEnum;
}

const UsersList = (props: Props) => {
    // Filters
    const [filterByName, setFilterByName] = useState<string>();
    const [filterByCheckedEmail, setFilterByCheckedEmail] = useState<
        boolean | undefined
    >();
    const [filterByCheckedMobile, setFilterByCheckedMobile] = useState<
        boolean | undefined
    >();
    const [filterByActivity, setFilterByActivity] = useState<
        UserActivityEnum | undefined
    >();

    const [filterByRegion, setFilterByRegion] = useState<RegionEnum>(props.region);

    const { data, loading, error, fetchMore } = useReadAllUsersQuery({
        variables: {
            first: 10,
            filterByName,
            filterByCheckedEmail,
            filterByCheckedMobile,
            filterByActivity,
            filterByRegion,
        },
        fetchPolicy: "cache-and-network",
    });

    const loadMoreOnScroll = useCallback(async () => {
        fetchMore({
            variables: {
                after: data?.result?.pageInfo.endCursor,
            },
        });
    }, [data, fetchMore]);

    const handleClickUser = useCallback(
        (userId: string) => {
            props.onClickUser(userId);
        },
        [props]
    );

    const items = data?.result?.edges?.map((edge) => edge?.node) || [];
    const itemsToRender = useMemo(
        () =>
            items.map((item) => (
                <tr
                    key={item?.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickUser(item?.id!)}
                >
                    <td>{item?.id}</td>
                    <td>
                        {item?.firstname} {item?.lastname}
                    </td>
                    <td>{item?.email}</td>
                    <td>{getIconFromEmailCheck(item?.emailCheck!)}</td>
                    <td>{getIconFromEmailCheck(item?.mobileCheck!)}</td>
                    <td>
                        <RegionFlag region={item?.region} normalSize noContainerStyle hideText />
                    </td>
                </tr>
            )),
        [items, handleClickUser]
    );

    return (
        <Card style={{ marginTop: "1rem" }}>
            <Card.Header>Search and add users to the group</Card.Header>
            <Card.Header>
                <Row>
                    <Col sm={12} md={4}>
                        <FormSearchByString
                            placeholder="Search user by name or email"
                            onSubmit={setFilterByName}
                        />
                    </Col>
                    <Col>
                        <Select<OptionType>
                            defaultValue={null}
                            onChange={async (v) => {
                                setFilterByCheckedEmail(
                                    // @ts-ignore
                                    typeof v?.value === "boolean" ? v.value : undefined
                                );
                            }}
                            placeholder={"Filter by email check"}
                            options={emailOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={(v: ValueType<OptionType>) => {
                                setFilterByCheckedMobile(
                                    // @ts-ignore
                                    typeof v?.value === "boolean" ? v.value : undefined
                                );
                            }}
                            placeholder={"Filter by mobile check"}
                            options={mobileOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={(v: ValueType<OptionActivityType>) => {
                                // @ts-ignore
                                setFilterByActivity(v?.value);
                            }}
                            placeholder={"Filter by activity"}
                            options={activityOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div
                    style={{ maxHeight: 200, overflow: "auto" }}
                    id="infinite-container"
                >
                    <InfiniteScroll
                        hasMore={data?.result?.pageInfo.hasNextPage || false}
                        loader={<Loading isLoading={loading}/>}
                        dataLength={items.length}
                        next={loadMoreOnScroll}
                        scrollableTarget={"infinite-container"}
                    >
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Email check</th>
                                <th>Mobile check</th>
                                <th>Region</th>
                            </tr>
                            </thead>
                            <tbody>{itemsToRender}</tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </Card.Body>
        </Card>
    );
};

export default UsersList;
