import React from 'react';
import './footer.scss';

export default () => {
    return (
        <div className="Footer">
        </div>
    );
};


