import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

interface Props extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: React.FC<Props> = ({ isLoading, children, ...rest }) => {
  return (
    <Button disabled={isLoading} {...rest}>
      {isLoading ? "Loading…" : children}
    </Button>
  );
};

export default LoadingButton;
