import { gql } from '@apollo/client'

export const TranslationFragments = {
  result: gql`
    fragment TranslationCodeResult on TranslationCode {
      id
      code
    }
  `,
  item: gql`
    fragment TranslationResult on Translation {
      id
      codeId
      languageId
      translation
    }
  `
}