import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import moment from "moment";

interface IProps {
    onChange: (fromDate: string, toDate: string) => void
}

export const FilterByMonthYear = ({onChange}: IProps) => {

    const [year, setYear] = useState(moment().format("YYYY"))
    const [month, setMonth] = useState(moment().format("MMMM"))

    const [fromDate, setFromDate] = useState<string>("")
    const [toDate, setToDate] = useState<string>("")

    const months = moment.months()
    const currentYear = new Date().getUTCFullYear()
    const years = Array(currentYear - (currentYear - 5)).fill('').map((v, idx) => currentYear - idx) as Array<number>

    useEffect(() => {
        const d = moment(year + month, "YYYYMMMM")
        const newFromDate = moment(d).startOf('month').toISOString()
        const newToDate = moment(d).endOf('month').toISOString()
        if (newFromDate !== fromDate || newToDate !== toDate) {
            setFromDate(newFromDate)
            setToDate(newToDate)
            onChange(newFromDate, newToDate)
        }
    }, [year, month, fromDate, toDate, onChange])

    return (
        <Row>
            <Col>
                <Form.Group controlId="form.month">
                    <Form.Label>Month</Form.Label>
                    <Form.Control as="select" value={month} onChange={(e) => setMonth(e.currentTarget.value)}>
                        {months.map((m, i) => <option key={i} value={m}>{m}</option>)}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="form.year">
                    <Form.Label>Year</Form.Label>
                    <Form.Control as="select" value={year} onChange={(e) => setYear(e.currentTarget.value)}>
                        {years.map((y, i) => <option key={i} value={y}>{y}</option>)}
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>
    )
}