import gql from "graphql-tag";

export const IndustryCodeFragments = {
    result: gql`
        fragment IndustryCodeResult on IndustryCode {
            id
            createdAt
            country
            industryCode
            description
            merchantCategoryCode
            region
        }
    `,
};
