import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { ReadRejectedInvoicesFromRemittance } from "../graphql/invoice";
import { CustomerUidType, useUpdateRemittedInvoiceCustomerUIdMutation } from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";

interface IProps {
    invoiceId: string
    userId: string
    currentCustomerUid: string
    currentCustomerUidType: CustomerUidType
    visible: boolean
    onHide: () => void
}

export const ModalUpdateCustomerUid = ({
    invoiceId,
    userId,
    currentCustomerUid,
    currentCustomerUidType,
    visible,
    onHide
}: IProps) => {

    const [customerUId, setCustomerUId] = useState<string>(currentCustomerUid);
    const [customerUIdType, setCustomerUIdType] = useState(currentCustomerUidType);

    const [updateCid, {
        loading: loadingUpdateCid,
        error: errUpdateCid
    }] = useUpdateRemittedInvoiceCustomerUIdMutation({
        variables: {
            invoiceId,
            customerUId,
            customerUIdType,
            userId
        },
        refetchQueries: [{
            query: ReadRejectedInvoicesFromRemittance
        }]
    })

    return (
        <Modal show={visible} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Update reference</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Error error={errUpdateCid?.graphQLErrors[0].message} />
                <Loading isLoading={loadingUpdateCid} />

                <p>
                    If the remittance for this invoice has been rejected or stopped because of a bad KID/note reference, you can update the KID.
                    Once the KID is updated, backend will try to remit again this invoice with the new info.
                    In case the info is rejected again, you will get an email and you will still find the invoice in the rejected/stopped list.
                </p>

                <Form noValidate>
                    <Form.Group as={Row}>
                        <Col sm={12} md={"auto"}>
                            <Form.Control
                                required
                                type="text"
                                placeholder="New KID/Note"
                                value={customerUId}
                                onChange={e => {
                                    setCustomerUId(e.currentTarget.value)
                                }}
                            />
                            <Form.Text className="text-muted">KID/Note</Form.Text>
                        </Col>
                        <Col sm={12} md={"auto"}>
                            <Form.Check
                                label="As note"
                                type={"checkbox"}
                                checked={customerUIdType === CustomerUidType.Note}
                                onChange={e => {
                                    setCustomerUIdType(customerUIdType === CustomerUidType.Note ? CustomerUidType.Kid : CustomerUidType.Note)
                                }}
                            />
                            <Form.Text className="text-muted">Check if you want to set a note</Form.Text>
                        </Col>
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    disabled={!customerUId || !customerUIdType}
                    onClick={async () => {
                        try {
                            await updateCid();
                            onHide();
                        } catch (e) {
                            console.debug(e)
                        }
                    }}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
}