import gql from "graphql-tag";
import { IssuerFragments } from "./fragments/issuer";

export const ReadIssuers = gql`
    query readAllIssuers(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchType: String
        $filterByName: String
        $filterByNoEmail: Boolean
        $filterByNoCreditorEmailLoan: Boolean
        $filterByNoPicture: Boolean
        $filterByExistingAccount: Boolean
        $filterByAllowingAmex: Boolean
        $filterByAllowingCreditCards: Boolean
        $filterByNoRealOrgNo: Boolean
        $filterByNoMcc: Boolean
        $filterByRegion: String
    ) {
        result: readAllIssuers(
            first: $first
            last: $last
            before: $before
            after: $after
            searchType: $searchType
            filterByName: $filterByName
            filterByNoEmail: $filterByNoEmail
            filterByNoCreditorEmailLoan: $filterByNoCreditorEmailLoan
            filterByNoPicture: $filterByNoPicture
            filterByExistingAccount: $filterByExistingAccount
            filterByAllowingAmex: $filterByAllowingAmex
            filterByAllowingCreditCards: $filterByAllowingCreditCards
            filterByNoRealOrgNo: $filterByNoRealOrgNo
            filterByNoMcc: $filterByNoMcc
            filterByRegion: $filterByRegion
        ) {
            edges{
                node {
                    ...IssuerResult
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }
    ${IssuerFragments.result}
`;

export const ReadIssuerById = gql`
    query readIssuerById($issuerId: ID!) {
        result: readIssuerById(issuerId: $issuerId) {
            ...IssuerDetail
        }
    }
    ${IssuerFragments.detailed}
`;

export const UpdateIssuerIdentifiers = gql`
    mutation updateIssuerIdentifiers (
        $issuerId: ID!
        $realOrgNumber: String!
        $subMerchantMcc: String!
    ) {
        result: updateIssuerIdentifiers(
            issuerId: $issuerId
            realOrgNumber: $realOrgNumber
            subMerchantMcc: $subMerchantMcc
        ) {
            ...IssuerResult
        }
    }
    ${IssuerFragments.detailed}
`

export const ReadIssuerWhitelist = gql`
    query readIssuerWhitelist (
        $issuerId: ID!
    ) {
        whitelist: readIssuerWhitelist(issuerId: $issuerId) {
            id
            issuerId
            origin
            accountNumber
        }
    }
`

export const CreateIssuerWhitelist = gql`
    mutation createIssuerWhitelist (
        $issuerId: ID!
        $accountNumber: String!
    ) {
        whitelist: createIssuerWhitelist(
            issuerId: $issuerId
            accountNumber: $accountNumber
        ) {
            id
            issuerId
            origin
            accountNumber
        }
    }
`

export const RemoveIssuerWhitelist = gql`
    mutation removeIssuerWhitelist (
        $issuerId: ID!
        $accountNumber: String!
    ) {
        whitelist: removeIssuerWhitelist(
            issuerId: $issuerId
            accountNumber: $accountNumber
        ) {
            id
            issuerId
            origin
            accountNumber
        }
    }
`

