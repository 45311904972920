import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export interface IProps {
    text?: string;
    defaultValue?: string;
    placeholder?: string;
    onSubmit: (value: string) => void;
    valueCanChange?: boolean;
}

export const FormSearchByString = ({
                                       text,
                                       defaultValue = "",
                                       placeholder,
                                       onSubmit,
                                       valueCanChange = false,
                                   }: IProps) => {
    const [value, setValue] = useState<string>(defaultValue);
    const timeout = useRef<number>();

    useEffect(() => {
        if (valueCanChange) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const v = event.currentTarget.value;
        setValue(v);

        if (timeout.current) {
            clearInterval(timeout.current);
        }

        timeout.current = window.setTimeout(() => {
            onSubmit(v);
        }, 500);
    };

    return (
        <Form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <Form.Group as={Row} controlId="formGroup">
                <Col>
                    <Form.Control
                        required
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                    />
                    <Form.Text className="text-muted">{text}</Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid value.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        </Form>
    );
};
