import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

import { Coupon, useReadCouponsQuery } from "../types/graphql";
import { Error } from "../component/Error";
import { FormSearchByString } from "../component/FormSearchByString";
import { Loading } from "../component/Loading";
import CouponDetail from "../component/CouponDetail";
import { RegionFlag } from "../component/RegionFlag";

const CouponRow: React.FC<{ coupon: Coupon; onClick: (id: string) => void }> =
    memo(({ coupon, onClick }) => {
        const handleClick = () => {
            onClick(coupon.id);
        };
        return (
            <tr style={{ cursor: "pointer" }} onClick={handleClick}>
                <td>{coupon?.id}</td>
                <td>{coupon?.code}</td>
                <td>{coupon?.description}</td>
                <td>
                    {moment(coupon?.startsAt).format("D MMM YYYY")} -{" "}
                    {coupon.endsAt
                        ? moment(coupon?.endsAt).format("D MMM YYYY")
                        : "No expiration"}
                </td>
                <td>{coupon?.product}</td>
                <td>
                    {coupon.amount} kr every period for {coupon.period} period(s)
                </td>
                <td>
                    <RegionFlag region={coupon.region} normalSize hideText/>
                </td>
                <td>
                    {coupon.recipientGroup && (
                        <RegionFlag region={coupon.recipientGroup.region} normalSize hideText/>
                    )}
                </td>
            </tr>
        );
    });

const Coupons = () => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCouponId, setSelectedCouponId] = useState<string | null>(null);
    const { data, loading, error, fetchMore } = useReadCouponsQuery({
        variables: { searchTerm },
        fetchPolicy: "cache-and-network",
    });

    const loadMoreOnScroll = useCallback(() => {
        fetchMore({
            variables: {
                after: data?.coupons?.pageInfo.endCursor,
            },
        });
    }, [data, fetchMore]);

    const handleCreate = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedCouponId(null);
    };

    const handleClick = useCallback(
        (id: string) => {
            setSelectedCouponId(id);
            setOpen(true);
        },
        [setSelectedCouponId]
    );

    const items = data?.coupons?.edges?.map((edge) => edge?.node) || [];
    const itemsToRender = useMemo(
        () =>
            items.map((item) => (
                <CouponRow key={item?.id} coupon={item!} onClick={handleClick}/>
            )),
        [handleClick, items]
    );

    return (
        <Container>
            <Error error={error?.graphQLErrors[0].message}/>

            <Card style={CardStyle}>
                <Card.Header>
                    <h5 className="dark">Discount codes</h5>
                </Card.Header>
                <Card.Header>
                    <Row>
                        <Col>
                            <FormSearchByString
                                placeholder="Search by name"
                                onSubmit={setSearchTerm}
                            />
                        </Col>
                        <Col sm="auto">
                            <Button onClick={handleCreate}>Create</Button>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <div
                        id="infinite-container"
                        style={{ maxHeight: 600, overflow: "auto" }}
                    >
                        <InfiniteScroll
                            hasMore={data?.coupons?.pageInfo.hasNextPage || false}
                            loader={<Loading isLoading={loading}/>}
                            dataLength={items.length}
                            scrollableTarget={"infinite-container"}
                            next={loadMoreOnScroll}
                        >
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Validity</th>
                                    <th>Product</th>
                                    <th>Value</th>
                                    <th>Region</th>
                                    <th>Group</th>
                                </tr>
                                </thead>
                                <tbody>{itemsToRender}</tbody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                </Card.Body>
            </Card>
            <CouponDetail
                open={open}
                onHide={handleClose}
                couponId={selectedCouponId}
            />
        </Container>
    );
};

const CardStyle = {
    marginTop: "2.5%",
    marginBottom: "2.5%",
};

export default Coupons;
