import gql from "graphql-tag";

export const ReadSubscriptionIncomeOverview = gql`
  query readSubscriptionIncomeOverview(
    $startsAt: DateTime!
    $endsAt: DateTime!
  ) {
    result: readSubscriptionIncomeOverview(
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      total
      totalCoupon
      totalEffective
      subtotals {
        subscription {
          id
          name
        }
        amount
        count
        amountCoupon
        countCoupon
      }
      subtotalsCoupons {
        code
        count
      }
    }
  }
`;

export const ReadUserSubscriptionsByUserId = gql`
  query readUserSubscriptionsByUserId($userId: ID!, $first: Int) {
    userSubscriptions: readUserSubscriptionsByUserId(
      userId: $userId
      first: $first
    ) {
      edges {
        node {
          id
          userId
          startsAt
          endsAt
          autoRenewal
          renewalStatus
          isFreeTrial
          isRefunded
          transactionRef
          paymentCard {
            id
            name
            customName
            brand
            expiration
            isDefault
          }
          renewalCard {
            id
            name
            customName
            brand
            expiration
            isDefault
          }
          subscription {
            id
            type
            amount
            period
            welcomePoints
          }
          coupon {
            id
            code
            amount
          }
        }
      }
    }
  }
`;

export const RefundSubscription = gql`
  mutation refundSubscription($userId: ID!, $userSubscriptionId: ID!, $amount: String!) {
    subscription: refundSubscription(
      userId: $userId
      userSubscriptionId: $userSubscriptionId
      amount: $amount
    ) {
      id
      userId
      startsAt
      endsAt
      autoRenewal
      renewalStatus
      isFreeTrial
      isRefunded
      transactionRef
      renewalCard {
        id
        name
        customName
        brand
        expiration
        isDefault
      }
      subscription {
        id
        type
        amount
        period
        welcomePoints
      }
    }
  }
`;

export const AdminChangeSubscription = gql`
  mutation adminChangeSubscription($userId: ID!, $userSubscriptionId: ID!, $autoRenewal: Boolean, $endsAt: String) {
    subscription: adminChangeSubscription(
      userId: $userId
      userSubscriptionId: $userSubscriptionId
      autoRenewal: $autoRenewal,
      endsAt: $endsAt
    ) {
      id
      userId
      startsAt
      endsAt
      autoRenewal
      renewalStatus
      isFreeTrial
      isRefunded
      transactionRef
      renewalCard {
        id
        name
        customName
        brand
        expiration
        isDefault
      }
      subscription {
        id
        type
        amount
        period
        welcomePoints
      }
    }
  }
`;

export const ReadSubscriptions = gql`
  query readSubscriptions($subscriptionType: SubscriptionType!) {
    subscriptions: readSubscriptions(subscriptionType: $subscriptionType) {
      id
      type
      amount
      period
      welcomePoints
      couponProduct
    }
  }
`;

export const CreateSubscription = gql`
  mutation createSubscription(
    $subscriptionIdToReplace: ID!
    $welcomePoints: Int!
    $amount: Float!
    $replaceMode: SubscriptionReplaceMode!
  ) {
    subscription: createSubscription(
      subscriptionIdToReplace: $subscriptionIdToReplace
      welcomePoints: $welcomePoints
      amount: $amount
      replaceMode: $replaceMode
    ) {
      id
      type
      amount
      period
      welcomePoints
    }
  }
`;
