import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { CreateSubscription, ReadSubscriptions } from "../graphql/subscription";
import {
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables,
    ReadSubscriptionsQuery,
    ReadSubscriptionsQueryVariables,
    Subscription,
    SubscriptionReplaceMode,
    SubscriptionType,
} from "../types/graphql";
import { getSubscriptionName } from "../helper/subscription";
import { Error } from "./Error";
import { Loading } from "./Loading";

// Replace modes as options
const replaceModesOptions = [
    {
        name: "1. ❌ Don't apply the new price when subscription is renewed.",
        value: SubscriptionReplaceMode.KeepSubscription,
    },
    {
        name: "2. ♻️ Apply new price when subscription is renewed.️",
        value: SubscriptionReplaceMode.RenewSubscription,
    },
].map((v) => (
    <option key={v.value.toString()} value={v.value.toString()} label={v.name} title={v.name}/>
));


export const SubscriptionsManager = () => {
    const [visibleModalDetails, setVisibleModalDetails] =
        useState<boolean>(false);
    const [subscriptionToUpdate, setSubscriptionToUpdate] =
        useState<Subscription | null>(null);
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

    // Form validation
    const [validated, setValidated] = useState(false);
    const [price, setPrice] = useState<number>(0);
    const [welcomePoints, setWelcomePoints] = useState<number>(0);
    const [replaceMode, setReplaceMode] =
        useState<SubscriptionReplaceMode | null>(null);
    const [confirmationCode, setConfirmationCode] = useState<string>("");

    const {
        data: dataSubscriptions,
        loading: loadingSubscriptions,
        error: errSubscriptions,
        refetch: refetchSubscriptions,
    } = useQuery<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>(
        ReadSubscriptions,
        {
            variables: {
                subscriptionType: SubscriptionType.Master,
            },
        }
    );

    const handleReplaceModeChange = (value: string) => {
        console.log("handleReplaceModeChange", value)
        switch (value) {
            case SubscriptionReplaceMode.KeepSubscription.toString():
                setReplaceMode(SubscriptionReplaceMode.KeepSubscription);
                break;
            case SubscriptionReplaceMode.RenewSubscription.toString():
                setReplaceMode(SubscriptionReplaceMode.RenewSubscription);
                break;
            default:
                setReplaceMode(null);
        }
    };


    const [
        createSubscription,
        { loading: loadingCreateSubscription, error: errCreateSubscription },
    ] = useMutation<
        CreateSubscriptionMutation,
        CreateSubscriptionMutationVariables
    >(CreateSubscription);

    const subscriptions = useMemo(() => {
        return dataSubscriptions?.subscriptions?.map((sub) => (
            <ListGroup.Item key={sub.id}>
                <Row>
                    <Col md={8}>{getSubscriptionName(sub)}</Col>
                    <Col md={4}>
                        <Button
                            variant={"primary"}
                            onClick={() => {
                                showModalDetails(sub);
                            }}
                        >
                            Edit
                        </Button>
                    </Col>
                </Row>
            </ListGroup.Item>
        ));
    }, [dataSubscriptions]);

    const codeForConfirmation = useMemo(() => {
        return `${subscriptionToUpdate?.period}M-${price}`;
    }, [subscriptionToUpdate, price]);

    useEffect(() => {
        setEnableSubmit((price === subscriptionToUpdate?.amount || confirmationCode === codeForConfirmation) && replaceMode != null);
    }, [subscriptionToUpdate, price, confirmationCode, codeForConfirmation, replaceMode]);

    const hideModalDetails = () => {
        setVisibleModalDetails(false);
        setSubscriptionToUpdate(null);
        setPrice(0);
        setWelcomePoints(0);
        setReplaceMode(null);
    };

    const showModalDetails = (s: Subscription) => {
        setSubscriptionToUpdate(s);
        setPrice(s.amount);
        setWelcomePoints(s.welcomePoints);
        setReplaceMode(null);
        setVisibleModalDetails(true);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        const isFormValid = form.checkValidity();
        setValidated(true);

        // Create subscription
        if (isFormValid && subscriptionToUpdate?.id && replaceMode) {
            try {
                await createSubscription({
                    variables: {
                        subscriptionIdToReplace: subscriptionToUpdate?.id,
                        amount: price,
                        welcomePoints,
                        replaceMode,
                    },
                });
                hideModalDetails();
                await refetchSubscriptions();
            } catch (e) {
                console.debug(e);
            }
        }
    };

    return (
        <>
            <Loading isLoading={loadingSubscriptions}/>
            <Error error={errSubscriptions?.graphQLErrors[0].message}/>

            <ListGroup variant="flush">{subscriptions}</ListGroup>

            <Modal show={visibleModalDetails} onHide={hideModalDetails}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {subscriptionToUpdate && getSubscriptionName(subscriptionToUpdate)}
                    </Modal.Title>
                </Modal.Header>

                <Loading isLoading={loadingCreateSubscription}/>
                <Error error={errCreateSubscription?.graphQLErrors[0].message}/>

                <Form noValidate validated={validated} onSubmit={onSubmit}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="subscription.price">
                                <Form.Label>Price 💰</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Price in NOK"
                                    value={price}
                                    onChange={(e) => setPrice(parseInt(e.currentTarget.value))}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid subscription price in NOK.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="6"
                                controlId="subscription.welcomePoints"
                            >
                                <Form.Label>Welcome points 💯</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Welcome points"
                                    value={welcomePoints}
                                    onChange={(e) =>
                                        setWelcomePoints(parseInt(e.currentTarget.value))
                                    }
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid number for welcome points.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="subscription.replaceMode">
                                <Form.Label>Mode</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={replaceMode?.toString() || ""}
                                    onChange={(e) => handleReplaceModeChange(e.currentTarget.value)}
                                    required
                                >
                                    <option value="">Choose a creation mode ...</option>
                                    {replaceModesOptions}
                                </Form.Control>

                                <Form.Text muted>
                                    <ul>
                                        <li>
                                            1. ❌: The user can keep and renew the old subscription
                                            until it decides to stop it.
                                        </li>
                                        <li>
                                            2. ♻️: The user gets the new subscription automatically at
                                            the renewal.
                                        </li>
                                    </ul>
                                    In both cases, if the subscription expires (no renewal), the
                                    user can subscribe again to the same period-subscription, but
                                    with the new/updated price. The old subscription is no longer
                                    available. Once the new subscription is updated, it will
                                    immediately be available in the app while the old one
                                    disappears.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid creation mode.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="12"
                                controlId="subscription.welcomePoints"
                            >
                                <Form.Label>Confirmation code️</Form.Label>
                                <Form.Text>
                                    Insert the following code <code>{codeForConfirmation}</code>{" "}
                                    to confirm the operation.
                                </Form.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Confirmation code"
                                    value={confirmationCode}
                                    onChange={(e) => {
                                        setConfirmationCode(e.currentTarget.value)
                                    }}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide the confirmation code{" "}
                                    <code>{codeForConfirmation}</code>.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={hideModalDetails}>
                            Exit
                        </Button>
                        <Button type="submit" variant="primary" disabled={!enableSubmit}>
                            Update Subscription
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
