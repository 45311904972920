import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import logo from './logo-white.png';
import './header.scss';
import {Button, Container, Image} from "react-bootstrap";
import {colors} from "../../theme";
import {ProfileContext} from "../../context/profile";

export default () => {
    const {profile, logout} = useContext(ProfileContext)
    return (
        <div className="Header">

            <Link to="/">
                <Image srcSet={logo} alt="billkill-logo"/>
            </Link>

            {profile && (
                <Container style={{marginTop: '1em', marginBottom: '2em'}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <h5 style={{
                            margin: 0,
                            color: colors.white,
                            paddingRight: '1em'
                        }}>{profile?.firstname} {profile?.lastname}</h5>
                        <Button
                            style={{display: 'block'}}
                            onClick={logout}
                            variant={'outline-light'}
                            size={'sm'}>
                            Logout
                        </Button>
                    </div>
                </Container>
            )}

        </div>
    );
};


