import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  ButtonGroup,
  Button,
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { FormBalanceByDates } from "../component/FormBalanceByDates";

export default () => {
  const history = useHistory();

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>
              <h5 className="dark">Invoices</h5>
            </Card.Header>
            <Card.Body>
              <ButtonGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() => history.push("/invoices")}
                >
                  List
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => history.push("/remittance/invoices")}
                >
                  Rejected invoices
                </Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h5 className="dark">Users</h5>
            </Card.Header>
            <Card.Body>
              <ButtonGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() => history.push("/users")}
                >
                  List
                </Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h5 className="dark">Issuers</h5>
            </Card.Header>
            <Card.Body>
              <ButtonGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() => history.push("/issuers")}
                >
                  List
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => history.push("/industry-codes")}
                >
                  Industry codes
                </Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <BalanceSection />

      <Row>
        <Col className="mt-4">
          <SystemSection />
        </Col>
        <Col className="mt-4">
          <OfferSection />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <SubscriptionSection />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PspTransactionsSection />
        </Col>
      </Row>
    </Container>
  );
};

const BalanceSection = () => {
  const history = useHistory();

  const goToBalanceBetweenDates = (fromDate: Date, toDate: Date) => {
    const fromDateString = moment(fromDate).format("YYYY-MM-DD");
    const toDateString = moment(toDate).format("YYYY-MM-DD");
    history.push(`/balance/from/${fromDateString}/to/${toDateString}`);
  };

  const goToZenBalanceBetweenDates = (fromDate: Date, toDate: Date) => {
    const fromDateString = moment(fromDate).format("YYYY-MM-DD");
    const toDateString = moment(toDate).format("YYYY-MM-DD");
    history.push(`/zenbalance/from/${fromDateString}/to/${toDateString}`);
  };

  const goToBalanceForCurrentMonth = () => {
    const fromDate = moment().startOf("month").toDate();
    const toDate = moment().endOf("month").toDate();
    goToBalanceBetweenDates(fromDate, toDate);
  };

  const goToZenBalanceForCurrentMonth = () => {
    const fromDate = moment().startOf("month").toDate();
    const toDate = moment().endOf("month").toDate();
    goToZenBalanceBetweenDates(fromDate, toDate);
  };

  const goToZenBalanceLastMonth = () => {
    const fromDate = moment().subtract(1, "months").startOf("month").toDate();
    const toDate = moment().subtract(1, "months").endOf("month").toDate();
    goToZenBalanceBetweenDates(fromDate, toDate);
  };

  const goToBalanceForLastMonth = () => {
    const fromDate = moment().subtract(1, "months").startOf("month").toDate();
    const toDate = moment().subtract(1, "months").endOf("month").toDate();
    goToBalanceBetweenDates(fromDate, toDate);
  };

  return (
    <Card>
      <Card.Header>
        <h5 className="dark">Balance</h5>
      </Card.Header>
      <Card.Body>
        <ButtonGroup>
          <Button variant="outline-secondary" onClick={goToBalanceForLastMonth}>
            Last month
          </Button>
          <Button
            variant="outline-secondary"
            onClick={goToBalanceForCurrentMonth}
          >
            Current month
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push(`/balance/forecasting`)}
          >
            Forecasting
          </Button>
          <Button
              variant="outline-secondary"
              onClick={() => history.push(`/balance/points`)}
          >
            Points
          </Button>
          <Button
              variant="outline-secondary"
              onClick={goToZenBalanceLastMonth}
          >
            Zen Last Month
          </Button>
          <Button
              variant="outline-secondary"
              onClick={goToZenBalanceForCurrentMonth}
          >
            Zen Current Month
          </Button>
        </ButtonGroup>
      </Card.Body>
      <Card.Body>
        <h5>Between two dates</h5>
        <FormBalanceByDates
          text="Get balance between two dates"
          onSubmit={(fromDate, toDate) =>
            goToBalanceBetweenDates(fromDate, toDate)
          }
        />
      </Card.Body>
    </Card>
  );
};

const SystemSection = () => {
  const history = useHistory();
  return (
    <Card>
      <Card.Header>
        <h5 className="dark">System</h5>
      </Card.Header>
      <Card.Body>
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/daemons")}
          >
            Daemons
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/statistics")}
          >
            Statistics
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/translations")}
          >
            Translations
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/usergroups")}
          >
            User groups
          </Button>
          <Button
              variant="outline-secondary"
              onClick={() => history.push("/system/zencost")}
          >
            Zen Cost
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
};

const OfferSection = () => {
  const history = useHistory();
  return (
    <Card>
      <Card.Header>
        <h5 className="dark">Ninja power</h5>
      </Card.Header>
      <Card.Body>
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/ninja-power/offers")}
          >
            Offers
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/ninja-power/categories")}
          >
            Categories
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
};

const SubscriptionSection = () => {
  const history = useHistory();
  return (
    <Card>
      <Card.Header>
        <h5 className="dark">Subscriptions</h5>
      </Card.Header>
      <Card.Body>
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/subscriptions")}
          >
            Subscriptions
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/coupons")}
          >
            Discount codes
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
};

const PspTransactionsSection = () => {
  const history = useHistory();
  return (
    <Card>
      <Card.Header>
        <h5 className="dark">PSP Transactions</h5>
      </Card.Header>
      <Card.Body>
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            onClick={() => history.push("/system/psp-transactions")}
          >
            Transactions
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card>
  );
};
