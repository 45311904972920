import React, { useCallback, useMemo } from "react";
import { OptionTypeBase } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { useReadTranslationCodesQuery } from "../types/graphql";

interface Props {
  value: string | null;
  onChange: (value: OptionTypeBase) => void;
  disabled?: boolean;
}

const SelectTranslation = (props: Props) => {
  const { data, loading, error, fetchMore } = useReadTranslationCodesQuery({
    variables: { first: 10 },
  });

  const loadMore = useCallback(
    async (searchTerm: string, loadedOptions, { after }: { after: string }) => {
      const res = await fetchMore({
        variables: {
          searchTerm,
          after,
        },
      });
      return {
        options: res.data.translationCodes?.edges
          ?.map((edge) => edge?.node)
          .map((code) => ({ value: code?.id, label: code?.code })),
        hasMore: res.data.translationCodes?.pageInfo.hasNextPage,
        additional: {
          after: res.data.translationCodes?.pageInfo.endCursor,
        },
      };
    },
    [fetchMore]
  );

  const options = useMemo(
    () =>
      data?.translationCodes?.edges
        ?.map((edge) => edge?.node)
        .map((code) => ({ value: code?.id, label: code?.code })),
    [data]
  );

  return (
    <AsyncPaginate
      loadOptions={loadMore}
      debounceTimeout={200}
      additional={{
        after: null,
      }}
      defaultOptions
      value={options?.find((option) => option.value === props.value)}
      onChange={props.onChange}
      isDisabled={props.disabled}
    />
  );
};

export default SelectTranslation;
