import gql from "graphql-tag";

export const ReadZenExtraBalance = gql`
    query ReadZenExtraBalance($fromDate: DateTime!, $toDate: DateTime!) {
        readZenExtraBalance(fromDate: $fromDate, toDate: $toDate) {
            TotalEarnedExtra
            TotalUsedExtra
            TotalUnusedExtra
            TotalEarnedExtraByInvoice
            TotalEarnedExtraByInvitation
            TotalEarnedExtraStandaloneSubscription
            ZenPointExt {
                ZenPoint {
                    Id
                    UserId
                    RefId
                    InviterId
                    FriendId
                    InvoiceId
                    Points {
                        Basic
                        Extra
                        Proposal
                    }
                    Balance {
                        Basic
                        Extra
                    }
                    ProcessState {
                        Name
                        Value
                    }
                    Status {
                        Name
                        Value
                    }
                    Type {
                        Name
                        Value
                    }
                    Reason {
                        Name
                        Value
                    }
                    Role {
                        Name
                        Value
                    }
                    InvitationCode
                    Memo
                    BeltNo
                    ProcessDate
                    CreatedAt
                    UpdatedAt
                }
                UserName
                UserEmail
                InvoiceReference
                IssuerId
                IssuerName
                Reference
                Amount
                CurrencyCode
                RemitDate
                ZenFee
                FeeAmount
            }
        }
    }
`;

export const AdminReadUserZenHistory = gql`
    query AdminReadUserZenHistory($startId: Int!, $userId: ID!) {
        readUserZenHistory(startId: $startId, userId: $userId) {
            Id
            FriendId
            Points {
                Proposal
                Basic
                Extra
            }
            Balance {
                Basic
                Extra
            }
            Status {
                Value
                Name
            }
            ProcessState {
                Value
                Name
            }
            Type {
                Value
                Name
            }
            Role {
                Value
                Name
            }
            Reason {
                Value
                Name
            }
            Invoice {
                id
                reference
            }
            InvoiceIdentifier
            InvitationCode
            Memo
            UpdatedAt
            ProcessDate
        }
    }
`;

export const ReadSystemZenExtraBalance = gql`
    query ReadSystemZenExtraBalance($date: DateTime!) {
        readSystemZenExtraBalance(date: $date)
    }
`;

export const ZenCostListQuery = gql`
    query ZenCostList {
        zenCostList {
            id
            typ
            typeName
            static
            multiplier
            ts
        }
    }
`;

export const ZenUpdateCost = gql`
    mutation UpdateZenCost($typeId: Int!, $multiplier: Int, $static: Int) {
        updateZenCost(typeId: $typeId, multiplier: $multiplier, static: $static) {
            id
            typ
            typeName
            static
            multiplier
            ts
        }
    }
`;

export const DoManualZenCorrection = gql`
    mutation ManualZenCorrection($userId: ID!, $extra: Int,$basic: Int, $memo: String!) {
        manualZenCorrection(userId: $userId, extra: $extra,basic: $basic, memo: $memo) {
            isOk
        }
    }
`;
