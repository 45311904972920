import React, {useMemo} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import {StatisticCard} from "./StatisticCard";
import {getTrendIssuerRegistration, getTrendIssuerRegistrationCumulative} from "../helper/system";
import {useQuery} from "@apollo/client";
import {
    ReadStatisticsIssuers
} from "../graphql/systemTool";
import {
    HistogramIssuersRegistration, HistogramIssuersRegistrationCumulative,
    ReadStatisticsIssuersQuery,
    ReadStatisticsIssuersQueryVariables,
} from "../types/graphql";
import {Error} from "./Error";
import {Loading} from "./Loading";
import {StatisticsLastUpdate} from "./StatisticsLastUpdate";
import {StatisticsChart} from "./StatisticsChart";

export const StatisticsIssuers = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>(ReadStatisticsIssuers);

    const stats = useMemo(() => {
        if (!data?.statistics) return null;
        const {overview, histograms} = data?.statistics;
        return (
            <>
                <Row>
                    <Col md={4}>
                        <StatisticCard value={overview.totalCount} description={"Registered issuers"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.totalLogoCount} description={"Issuers with logo"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.totalSupportCount} description={"Issuers with support"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={6}>
                        <StatisticsChart
                            subject={"Cumulative issuers registration"}
                            axisXLabel={"Month"}
                            data={getTrendIssuerRegistrationCumulative(histograms.cumulativeRegistration as Array<HistogramIssuersRegistrationCumulative>)}
                        />
                    </Col>
                    <Col md={6}>
                        <StatisticsChart
                            subject={"Issuers registration"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getTrendIssuerRegistration(histograms.registration as Array<HistogramIssuersRegistration>)}
                        />
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <Card className='mb-4'>
            <Card.Header>
                <h5 className="dark">Issuers</h5>
            </Card.Header>
            <Card.Body>
                <Loading isLoading={loading}/>
                <Error error={error?.graphQLErrors[0].message}/>
                {stats}
            </Card.Body>
            <Card.Footer>
                <StatisticsLastUpdate date={data?.statistics?.lastUpdate}/>
            </Card.Footer>
        </Card>
    );

}