import React, { useCallback, useMemo } from "react";
import { Card, CloseButton, Table } from "react-bootstrap";
import { ReadUserGroupMembers } from "../graphql/userGroup";
import {
  useDeleteUserGroupMemberMutation,
  useReadUserGroupMembersQuery,
} from "../types/graphql";
import { Error } from "./Error";

interface Props {
  groupId: string;
}

const UserGroupMemberList = (props: Props) => {
  const { data, loading, error } = useReadUserGroupMembersQuery({
    variables: { groupId: props.groupId! },
    fetchPolicy: "cache-and-network",
  });
  const [deleteUserGroupMember] = useDeleteUserGroupMemberMutation({
    refetchQueries: [
      {
        query: ReadUserGroupMembers,
        variables: { groupId: props.groupId },
      },
    ],
  });

  const handleClickUser = useCallback(
    (memberId: string) => {
      deleteUserGroupMember({
        variables: {
          memberId,
          groupId: props.groupId,
        },
      });
    },
    [deleteUserGroupMember, props.groupId]
  );

  const itemsRender = useMemo(
    () =>
      data?.userGroupMembers.map((member) => (
        <tr key={member.id} style={{ cursor: "pointer" }}>
          <td>{member.userId}</td>
          <td>{member.name}</td>
          <td>
            <CloseButton onClick={() => handleClickUser(member.id)} />
          </td>
        </tr>
      )),
    [data, handleClickUser]
  );

  if (error) return <Error error={error.graphQLErrors[0].message} />;

  return (
    <Card>
      <Card.Header>Members</Card.Header>
      <Card.Body>
        {data?.userGroupMembers.length === 0 ? (
          <p>No group members</p>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{itemsRender}</tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserGroupMemberList;
