import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  DeleteTranslationsByCode,
  ReadLanguages,
  ReadTranslationCodes,
  ReadTranslations,
  UpdateTranslation,
} from "../graphql/translations";
import {
  DeleteTranslationsByCodeMutation,
  DeleteTranslationsByCodeMutationVariables,
  ReadLanguagesQuery,
  ReadLanguagesQueryVariables,
  ReadTranslationsQuery,
  ReadTranslationsQueryVariables,
  SetTranslationMutation,
  SetTranslationMutationVariables,
  TranslationCode,
} from "../types/graphql";
import { Error } from "./Error";

interface Props {
  code: TranslationCode | null;
  isCreationMode: boolean;
  onHide: () => void;
}

const TranslationCodeOverview = (props: Props) => {
  const { code, isCreationMode, onHide } = props;

  const { data } = useQuery<
    ReadTranslationsQuery,
    ReadTranslationsQueryVariables
  >(ReadTranslations, { variables: { codeId: code?.id! }, skip: !code });

  const { data: languagesData } = useQuery<
    ReadLanguagesQuery,
    ReadLanguagesQueryVariables
  >(ReadLanguages, { skip: !isCreationMode });

  const [updateTranslation, { error: errorUpdate }] = useMutation<
    SetTranslationMutation,
    SetTranslationMutationVariables
  >(UpdateTranslation, { refetchQueries: [{ query: ReadTranslationCodes }] });
  const [deleteTranslations, { error: errorDelete }] = useMutation<
    DeleteTranslationsByCodeMutation,
    DeleteTranslationsByCodeMutationVariables
  >(DeleteTranslationsByCode, {
    variables: { codeId: code?.id! },
    refetchQueries: [{ query: ReadTranslationCodes }],
  });

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (isCreationMode) {
      const code = formData.get('code') as string;
      formData.delete('code')
      await Promise.all(
        Object.entries(Object.fromEntries(formData.entries())).map(
          ([languageId, translation]) =>
            updateTranslation({
              variables: {
                code,
                languageId,
                translation: translation as string,
              },
            })
        )
      );
    } else {
      await Promise.all(
        Object.entries(Object.fromEntries(formData.entries())).map(
          ([languageId, translation]) =>
            updateTranslation({
              variables: {
                languageId,
                code: code?.code!,
                translation: translation as string,
              },
            })
        )
      );
    }
    onHide();
  };

  const handleDelete = async () => {
    await deleteTranslations();
    onHide();
  };

  return (
    <Modal show={!!code || isCreationMode} scrollable centered onHide={onHide}>
      <Form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isCreationMode ? "New translation" : code?.code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={errorUpdate?.graphQLErrors[0].message} />
          <Error error={errorDelete?.graphQLErrors[0].message} />
          {isCreationMode ? (
            <>
              <Form.Group controlId="code">
                <Form.Label>Code</Form.Label>
                <Form.Control name="code" defaultValue="" />
              </Form.Group>
              {languagesData?.languages.map((language) => (
                <Form.Group key={language.id}>
                  <Form.Label>{language.name}</Form.Label>
                  <Form.Control name={language.id} />
                </Form.Group>
              ))}
            </>
          ) : (
            data?.translations.translations.map((translation) => (
              <Form.Group
                key={translation.translation.id}
                controlId={translation.translation.id}
              >
                <Form.Label>{translation.language.name}</Form.Label>
                <Form.Control
                  name={translation.language.id}
                  placeholder={translation.language.name}
                  defaultValue={translation.translation.translation}
                />
              </Form.Group>
            ))
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">{isCreationMode ? "Create" : "Save"}</Button>
          {!isCreationMode && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TranslationCodeOverview;
