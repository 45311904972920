import React from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import Moment from "react-moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

interface IProps {
    value: string | null | undefined,
    format: string,
    tooltipId?: string,
    tooltipFromNow?: boolean,
    tooltipToNow?: boolean,
    parse?: string | null | undefined,
}

export const MomentDate = (props: IProps) => {

    const {value, format, tooltipId, tooltipFromNow, tooltipToNow, parse} = props;

    // Added null handler because of backend issue about nullable time
    if (!value || value === "" || value === "null") {
        return <>-</>;
    }

    if (!format || format === "") {
        return <>Missing date format</>;
    }

    let p = parse || ''

    const mainValue = <Moment format={format} parse={p}>{value}</Moment>;

    if (tooltipId) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={tooltipId}>
                        <Moment fromNow={tooltipFromNow} toNow={tooltipToNow}>{value}</Moment>
                    </Tooltip>
                }
            >
                {mainValue}
            </OverlayTrigger>
        );
    } else {
        return mainValue;
    }

};
