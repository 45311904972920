import React, {useMemo} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import {StatisticCard} from "./StatisticCard";
import {
    getTrendUserRegistration, getTrendUserRegistrationCumulative, getTrendUserRegistrationCumulativeMultiple,
} from "../helper/system";
import {useQuery} from "@apollo/client";
import {
    ReadStatisticsUsers
} from "../graphql/systemTool";
import {
    HistogramUsersRegistration,
    ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables
} from "../types/graphql";
import {Error} from "./Error";
import {Loading} from "./Loading";
import {StatisticsLastUpdate} from "./StatisticsLastUpdate";
import {StatisticsChart} from "./StatisticsChart";

export const StatisticsUsers = () => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>(ReadStatisticsUsers);

    const stats = useMemo(() => {
        if (!data?.statistics) return null;
        const {overview, histograms} = data?.statistics;
        return (
            <>
                <Row>
                    <Col md={6}>
                        <StatisticCard value={overview.totalCount} description={"Registered users"}/>
                    </Col>
                    <Col md={6}>
                        <StatisticCard value={`${overview.ageAverage} years`} description={"Age average"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={4}>
                        <StatisticCard value={overview.totalActiveCount} description={"Active users"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.totalInactiveCount} description={"Inactive users"}/>
                    </Col>
                    <Col md={4}>
                        <StatisticCard value={overview.totalDeletedCount} description={"Deleted users"}/>
                    </Col>
                </Row>
                <Row as={'hr'}/>
                <Row>
                    <Col md={12}>
                        <StatisticsChart
                            subject={"Users"}
                            axisXLabel={"Month"}
                            data={getTrendUserRegistrationCumulativeMultiple(histograms.cumulativeRegistration as Array<HistogramUsersRegistration>)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <StatisticsChart
                            subject={"Age average"}
                            axisXLabel={"Month"}
                            data={getTrendUserRegistrationCumulative(histograms.cumulativeRegistration as Array<HistogramUsersRegistration>)}
                        />
                    </Col>
                    <Col md={6}>
                        <StatisticsChart
                            subject={"Users registration"}
                            axisXLabel={"Month"}
                            type={"ColumnChart"}
                            data={getTrendUserRegistration(histograms.registration as Array<HistogramUsersRegistration>)}
                        />
                    </Col>
                </Row>
            </>
        );
    }, [data]);

    return (
        <>
            <Card className='mb-4'>
                <Card.Header>
                    <h5 className="dark">Users</h5>
                </Card.Header>
                <Card.Body>
                    <Loading isLoading={loading}/>
                    <Error error={error?.graphQLErrors[0].message}/>
                    {stats}
                </Card.Body>
                <Card.Footer>
                    <StatisticsLastUpdate date={data?.statistics?.lastUpdate}/>
                </Card.Footer>
            </Card>
        </>
    );

}
