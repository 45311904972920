import React from 'react';
import flagSe from "../theme/image/flagSe.png";
import flagNo from "../theme/image/flagNo.png";
import globe from "../theme/image/globe.png";
import { Image } from "react-bootstrap";
import { RegionEnum } from "../types/graphql";

const styles = {
    flagContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    image: {
        width: '32px',
        height: '24px',
        marginLeft: '8px',
    }
};

interface FlagProps {
    region: string | undefined | null;
    normalSize?: boolean;
    hideText?: boolean;
    headerLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    noContainerStyle?: boolean;
}

export const RegionFlag = ({
                               region,
                               normalSize,
                               hideText,
                               headerLevel = 'h5', // Default set to 'h5' if not specified
                               noContainerStyle = false,
                           }: FlagProps) => {

    let flagPic = region === RegionEnum.Sv ? flagSe : flagNo;
    let imgStyle = styles.image;
    if (region === RegionEnum.All) {
        flagPic = globe;
        imgStyle = { ...styles.image, width: '32px', height: '32px', marginLeft: '6px' };
    }

    const textContent = hideText ? '' : 'Region ';

    const Tag = normalSize ? 'span' : headerLevel;

    if (region) {
        return (
            <Tag aria-label={`${region.toUpperCase()} Flag`} style={noContainerStyle ? {} : styles.flagContainer}>
                {textContent}
                <Image srcSet={flagPic} aria-label={`${region.toUpperCase()}`} alt={`${region.toUpperCase()}`}
                       style={imgStyle}/>
            </Tag>
        );
    }

    return null;
};
