import gql from "graphql-tag";

export const CreateBankIdSession = gql`
    mutation createBankIdSession(
        $mobile: String!
        $cbSuccess: String
        $cbFailure: String
        $cbAbort: String
    ) {
        result: createBankIdSession(
            mobile: $mobile
            cbSuccess: $cbSuccess
            cbFailure: $cbFailure
            cbAbort: $cbAbort
        ) {
            id
            url
            callbacks {
                abort
                failure
                success
            }
        }
    }
`;

export const CreateAccessFromSession = gql`
    mutation createAccessFromSession($sessionId: String!, $role: UserRoleEnum!) {
        result: createAccessFromSession(sessionId: $sessionId, role: $role) {
            token
            refreshToken
        }
    }
`;

export const RefreshToken = gql`
    mutation refreshToken($refreshToken: String!) {
        result: refreshToken(refreshToken: $refreshToken) {
            token
            refreshToken
        }
    }
`;
