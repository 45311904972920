import React from 'react';
import {Container} from "react-bootstrap";
import {OfferCategoriesOverview} from "../component/OfferCategoriesOverview";

export const OfferCategoriesList = () => {
    return (
        <Container>
            <OfferCategoriesOverview/>
        </Container>
    );
}


