import gql from "graphql-tag";
import {IssuerBlockCompleteFragment, IssuerFragments} from "./fragments/issuer";

export const UpdateIssuerEmail = gql`
    mutation updateIssuerEmail($issuerId: ID!, $email: String!) {
        result: updateIssuerEmail(issuerId: $issuerId, email: $email) {
            ...IssuerDetail
        }
    }
    ${IssuerFragments.detailed}
`;

export const UpdateIssuerLoanEmail = gql`
    mutation updateIssuerLoanEmail($issuerId: ID!, $emailLoan: String!) {
        result: updateIssuerLoanEmail(issuerId: $issuerId, emailLoan: $emailLoan) {
            ...IssuerDetail
        }
    }
    ${IssuerFragments.detailed}
`;

export const UpdateIssuerPicture = gql`
    mutation updateIssuerPicture($issuerId: ID!, $pictureBase64: String!) {
        result: updateIssuerPicture(issuerId: $issuerId, pictureBase64: $pictureBase64) {
            ...IssuerDetail
        }
    }
    ${IssuerFragments.detailed}
`;

export const CopyIssuerInfo = gql`
    mutation copyIssuerInfo($issuerIdSource: ID!, $issuerIdDestination: ID!) {
        result: copyIssuerInfo(issuerIdSource: $issuerIdSource, issuerIdDestination: $issuerIdDestination) {
            ...IssuerDetail
        }
    }
    ${IssuerFragments.detailed}
`;

export const SetIssuerTransactionRule = gql`
    mutation setIssuerTransactionRule(
        $issuerId: ID!
        $allowDebitCard: Boolean!
        $allowCreditCard: Boolean!
        $allowAmexCard: Boolean!
        $maxAmountDebitCard: Int
        $maxAmountCreditCard: Int
        $maxAmountAmexCard: Int
    ) {
        rule: setIssuerTransactionRule(
            issuerId: $issuerId
            allowDebitCard: $allowDebitCard
            allowCreditCard: $allowCreditCard
            allowAmexCard: $allowAmexCard
            maxAmountDebitCard: $maxAmountDebitCard
            maxAmountCreditCard: $maxAmountCreditCard
            maxAmountAmexCard: $maxAmountAmexCard
        ){
            ...IssuerBlockComplete
        }
    }
    ${IssuerBlockCompleteFragment}
`;

export const UnsetIssuerTransactionRule = gql`
    mutation unsetIssuerTransactionRule(
        $issuerId: ID!
    ) {
        rule: unsetIssuerTransactionRule(
            issuerId: $issuerId
        ){
            ...IssuerBlockComplete
        }
    }
    ${IssuerBlockCompleteFragment}
`;


export const ReadIssuerTransactionRule = gql`
    query ReadIssuerTransactionRule($issuerId: ID!) {
        rule: readIssuerTransactionRule(issuerId: $issuerId){
            ...IssuerBlockComplete
        }
    }
    ${IssuerBlockCompleteFragment}
`;
