import gql from "graphql-tag";

export const CreateUserNote = gql`
    mutation createUserNote(
        $userId: ID!
        $content: String!
        $noteTime: DateTime!
    ) {
        note: createUserNote(
            userId: $userId
            content: $content
            noteTime: $noteTime
        ) {
            id
            content
            noteTime
        }
    }
`

export const UpdateUserNote = gql`
    mutation updateUserNote (
        $id: ID!
        $userId: ID!
        $content: String
        $noteTime: DateTime
    ) {
        note: updateUserNote(
            id: $id
            userId: $userId
            content: $content
            noteTime: $noteTime
        ) {
            id
            content
            noteTime
        }
    }
`

export const ReadUserNotes = gql`
    query readUserNotes(
        $first: Int
        $after: String
        $userId: ID!
    ) {
        notes: readUserNotes(
            first: $first
            after: $after
            userId: $userId
        ) {
            edges {
                node {
                    id
                    content
                    noteTime
                }
            }
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

export const DeleteUserNote = gql`
    mutation deleteUserNote(
        $id: ID!
        $userId: ID!
    ) {
        note: deleteUserNote(
            id: $id
            userId: $userId
        ) {
            id
            content
            noteTime
        }
    }
`
