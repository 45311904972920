import React, { useCallback, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { ReadPspTransactions, ReadPspTransactionsAsExcel } from '../graphql/pspTransaction'
import {
  ReadPspTransactionsAsExcelQuery,
  ReadPspTransactionsAsExcelQueryVariables,
  ReadPspTransactionsQuery,
  ReadPspTransactionsQueryVariables,
} from '../types/graphql'
import { Error } from '../component/Error'
import Table from 'react-bootstrap/Table'
import { Loading } from '../component/Loading'
import moment from 'moment'

export default () => {

  const now = moment().format('YYYY-MM') + '-01'
  const initialFromDate = moment(now, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD')
  // const initialToDate = moment(now, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')
  const initialToDate = moment().format('YYYY-MM-DD')

  const [excelIndex1, setExcelIndex1] = useState<number>(0)
  const [excelIndex2, setExcelIndex2] = useState<number>(0)
  const [fromDate, setFromDate] = useState<string>(initialFromDate)
  const [toDate, setToDate] = useState<string>(initialToDate)
  const [isConnected, setIsConnected] = useState<string>('2')
  const [statusType, setStatusType] = useState<string>('financial')
  const [provider, setProvider] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const [readOverview, {
    data,
    loading,
    error,
  }] = useLazyQuery<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>(ReadPspTransactions)

  const [getAsExcel, {
    loading: getAsExcelLoading,
    error: getAsExcelError,
    data: getAsExcelData,
  }] = useLazyQuery<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>(ReadPspTransactionsAsExcel, {
    variables: {
      fromDate: fromDate + 'T00:00:00Z',
      toDate: toDate + 'T23:59:59Z',
      isConnected: parseInt(isConnected),
      statusType: statusType,
      status,
      type,
      provider,
      search,
    },
  })

  useMemo(() => {
    if (excelIndex1 === excelIndex2 && getAsExcelData && getAsExcelData.result && getAsExcelData.result.data) {
      let a = document.createElement('a')
      a.href = 'data:image/png;base64,' + getAsExcelData.result.data
      a.download = 'BillKill_Transactions_' + fromDate + '_' + toDate + '.xlsx'
      a.click()
      setExcelIndex1(excelIndex1+1)
    }
  }, [getAsExcelError, getAsExcelData, fromDate, toDate])

  const downloadAsExcel = useCallback(() => {
    setExcelIndex1(excelIndex1+1)
    setExcelIndex2(excelIndex1+1)
    getAsExcel()
  }, [getAsExcel, getAsExcelData, getAsExcelLoading, getAsExcelError])

  const onClickReset = () => {
    setFromDate(initialFromDate)
    setToDate(initialToDate)
    setIsConnected('2')
    setStatusType('financial')
    setProvider('')
    setStatus('')
    setType('')
    setSearch('')
  }

  const onButtonClick = () => {
    if (fromDate && toDate) {
      readOverview({
        variables: {
          fromDate: fromDate + 'T00:00:00Z',
          toDate: toDate + 'T23:59:59Z',
          isConnected: parseInt(isConnected),
          statusType: statusType,
          status,
          type,
          provider,
          search,
        },
      })
    }
  }

  return (
    <Container fluid>
      <Error error={error?.graphQLErrors[0].message}/>

      <Card style={CardStyle}>

        <Card.Header>
          <h5 className="dark">PSP Transactions</h5>
        </Card.Header>
        <Card.Body>

          <Row>
            <Col>
              <Form.Group controlId="form.fromDate">
                <Form.Label>
                  From date
                  <span style={{fontSize: '12px', fontStyle: 'italic', marginLeft: '5px', color: '#888'}}>*including</span>
                </Form.Label>
                <Form.Control as="input" value={fromDate} onChange={(e) => setFromDate(e.currentTarget.value)}>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.toDate">
                <Form.Label>
                  To date
                  <span style={{fontSize: '12px', fontStyle: 'italic', marginLeft: '5px', color: '#888'}}>*including</span>
                </Form.Label>
                <Form.Control as="input" value={toDate} onChange={(e) => setToDate(e.currentTarget.value)}>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.isConnected">
                <Form.Label>IsConnected</Form.Label>
                <Form.Control as="select" value={isConnected} onChange={(e) => setIsConnected(e.currentTarget.value)}>
                  {[{ id: '0', name: 'No' }, { id: '1', name: 'Yes' }, { id: '2', name: 'Both' }].map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.provider">
                <Form.Label>Provider</Form.Label>
                <Form.Control as="select" value={provider} onChange={(e) => setProvider(e.currentTarget.value)}>
                  {[{ id: '', name: 'All' }, { id: 'Payex', name: 'Payex' }, { id: 'PaymentIQ', name: 'PaymentIQ' }].map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.type">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" value={type} onChange={(e) => setType(e.currentTarget.value)}>
                  {[{ id: '', name: 'All' }, { id: 'AddCard', name: 'AddCard' }, { id: 'Invoice', name: 'Invoice' }, { id: 'Subscription', name: 'Subscription' }].map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.status">
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" value={status} onChange={(e) => setStatus(e.currentTarget.value)}>
                  {[{ id: '', name: 'All' }, { id: 'Success', name: 'Success' }, { id: 'Failed', name: 'Failed' }].map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="form.statusType">
                <Form.Label>IsFinancial</Form.Label>
                <Form.Control as="select" value={statusType} onChange={(e) => setStatusType(e.currentTarget.value)}>
                  {[{ id: 'financial', name: 'Financial (success and amount not zero)' }, { id: 'non-financial', name: 'Non-financial (failed or amount zero)' }, { id: 'all', name: 'All' }].map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="form.search">
                <Form.Label>Search</Form.Label>
                <Form.Control as="input" value={search} onChange={(e) => setSearch(e.currentTarget.value)}>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <div>
                  <Button variant="outline-primary" onClick={onButtonClick} style={{marginRight: '10px'}}>
                    Submit
                  </Button>
                  <Button variant="outline-secondary" onClick={downloadAsExcel} style={{marginRight: '10px'}}>
                    Excel
                  </Button>
                  <Button variant="outline-secondary" onClick={onClickReset}>
                    Reset
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>

          {loading || getAsExcelLoading && (
            <Loading isLoading/>
          )}

          {!loading && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>CreatedAt</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>VatAmount</th>
                  <th>User</th>
                  <th>Invoice</th>
                  <th>Issuer</th>
                  <th>Subscription</th>
                  <th>Provider</th>
                  <th>TxNumber</th>
                  <th>PaymentId</th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.result?.transactions?.map(({
                                                     id,
                                                     paymentId,
                                                     amount,
                                                     vatAmount,
                                                     type,
                                                     transactionNumber,
                                                     paymentMethod,
                                                     remoteCreatedAt,
                                                     localCreatedAt,
                                                     bkProvider,
                                                     userName,
                                                     issuerName,
                                                     userSubscriptionStartsAt,
                                                     userSubscriptionEndsAt,
                                                     subscriptionDuration,
                                                     bkUserSubscriptionId,
                                                     bkInvoiceTransactionId,
                                                     bkUserId,
                                                     bkInvoiceId,
                                                     bkType,
                                                     bkStatus,
                                                   }) => (
                    <tr key={id}>
                      <td>
                        <a href={`/system/psp-transactions/${id}`}>
                          {id}
                        </a>
                      </td>
                      <td>
                        <div style={ remoteCreatedAt && remoteCreatedAt !== "null" ? {} : {display: "none"} }>
                          {remoteCreatedAt}
                        </div>
                        <div style={ remoteCreatedAt && remoteCreatedAt !== "null" ? {display: "none"} : {color: "#888", fontStyle: "italic"} }>
                          {localCreatedAt}
                        </div>
                      </td>
                      <td>{bkType}</td>
                      <td>{bkStatus}</td>
                      <td>{amount}</td>
                      <td>{vatAmount}</td>
                      <td>{bkUserId && (
                        userName + ' (' + bkUserId + ')'
                      )}</td>
                      <td>{bkInvoiceId && (
                        'Invoice ' + bkInvoiceId + ' (TX ' + bkInvoiceTransactionId + ')'
                      )}</td>
                      <td>{issuerName}</td>
                      <td>{bkUserSubscriptionId && (
                        '#' + bkUserSubscriptionId + ' (' + subscriptionDuration + ' months): ' + userSubscriptionStartsAt?.split('T')[0] + ' - ' + userSubscriptionEndsAt?.split('T')[0]
                      )}</td>
                      <td>{bkProvider}</td>
                      <td>
                        {transactionNumber}
                      </td>
                      <td>{paymentId}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )}
        </Card.Body>

      </Card>

    </Container>
  )

}

const CardStyle = {
  marginTop: '2.5%',
  marginBottom: '2.5%',
}
