import gql from "graphql-tag";

export const InvoiceFragments = {
    result: gql`
        fragment InvoiceResult on Invoice {
            id
            issuer{
                name
                orgNumber
            }
            kind
            lastTransaction {
                amount    
            }
            currency {
                symbol
            }
            fee {
                amount
                percentage
                reason
                total
            }
            reference
            referenceProvider
            customerNumber
            invoiceDate
            dueDate
            user {
                id
                firstname
            }
        }
    `
};

export const InvoiceHistoryFragments = {
    result: gql`
        fragment InvoiceHistoryResult on InvoiceHistory {
            id
            userId
            amount {
                original
                current
            }
            payment {
                incoming
                outgoing
            }
            lastTransaction{
                status
                statusDate
                remitStatus
            }
            transactions {
                time
                event
                status
                remitStatus
                remitStatusDescription
                amount
                reference
                details
                actor
                source
            }
        }
    `
};
