import {SubscriptionType} from "../types/graphql";

interface Subscription {
	type: SubscriptionType
	period: number
	amount: number
}

export const getSubscriptionName = (subscription: Subscription): string => {
	const periodUnit = subscription.period === 1 ? "month" : "months";
	const name = `${subscription.type} ${subscription.period} ${periodUnit} ${subscription.amount} kr`;
	return name[0].toUpperCase() + name.slice(1)
};
