import React, { useCallback, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { ReadUserGroupMembers, ReadUserGroups } from "../graphql/userGroup";
import {
    CreateUserGroupMutationVariables,
    Maybe,
    RegionEnum,
    useCreateUserGroupMemberMutation,
    useCreateUserGroupMutation,
    useDeleteUserGroupMutation,
    UserGroup,
    useUpdateUserGroupMutation,
} from "../types/graphql";
import UserGroupMemberList from "./UserGroupMemberList";
import UsersList from "./UsersList";
import { RegionFlag } from "./RegionFlag";

interface Props {
    isCreationMode: boolean;
    selectedUserGroup: Maybe<UserGroup>;
    onHide: () => void;
}

const UserGroupOverview = (props: Props) => {
    const { isCreationMode, selectedUserGroup, onHide } = props;
    const [region, setRegion] = useState<RegionEnum>(RegionEnum.Notset)
    const [createUserGroup] = useCreateUserGroupMutation({
        refetchQueries: [{ query: ReadUserGroups }],
    });
    const [updateUserGroup] = useUpdateUserGroupMutation({
        refetchQueries: [{ query: ReadUserGroups }],
    });
    const [deleteUserGroup] = useDeleteUserGroupMutation({
        refetchQueries: [{ query: ReadUserGroups }],
    });
    const [createUserGroupMember] = useCreateUserGroupMemberMutation({
        refetchQueries: [
            {
                query: ReadUserGroupMembers,
                variables: { groupId: selectedUserGroup?.id! },
            },
        ],
    });

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const formData = Object.fromEntries(
            form.entries()
        ) as CreateUserGroupMutationVariables;
        formData.region = region as RegionEnum;
        if (isCreationMode) {
            await createUserGroup({
                variables: formData,
            });
        } else {
            await updateUserGroup({
                variables: {
                    groupId: selectedUserGroup?.id!,
                    ...formData,
                },
            });
        }
        onHide();
    };

    const handleDelete = async () => {
        try {
            await deleteUserGroup({
                variables: {
                    groupId: selectedUserGroup?.id!,
                },
            });
        } catch (err) {
            console.log(err);
        } finally {
            onHide();
        }
    };

    const handleClickUser = useCallback(
        (userId: string) => {
            createUserGroupMember({
                variables: {
                    userId: userId,
                    groupId: selectedUserGroup?.id!,
                },
            });
        },
        [createUserGroupMember, selectedUserGroup]
    );

    return (
        <Modal
            centered
            show={isCreationMode || !!selectedUserGroup}
            size="xl"
            onHide={onHide}
        >
            <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h5 className="dark"
                    style={{ margin: 0 }}>{isCreationMode ? "Create a user group" : "Edit user group"}</h5>
                <div style={{ alignSelf: 'right' }}>
                    <RegionFlag region={props?.selectedUserGroup?.region as RegionEnum} normalSize hideText/>
                </div>
            </Card.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group controlId="name">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control name="name" defaultValue={selectedUserGroup?.name}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            name="description"
                            defaultValue={selectedUserGroup?.description}
                        />
                    </Form.Group>
                    {isCreationMode && ( // Only show dropdown in creation mode
                        <Form.Group controlId="region">
                            <Form.Label>Region:</Form.Label>
                            <Form.Control
                                as="select"
                                value={region}
                                onChange={(e) => setRegion(e.currentTarget.value as RegionEnum)}
                                required
                            >
                                <option value="">Select Region</option>
                                <option value="no">Norway</option>
                                <option value="sv">Sweden</option>
                                <option value="all">All</option>
                            </Form.Control>
                        </Form.Group>
                    )}
                    {!!selectedUserGroup && (
                        <>
                            <UserGroupMemberList groupId={selectedUserGroup?.id!}/>
                            <UsersList onClickUser={handleClickUser} region={selectedUserGroup.region}/>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!isCreationMode && (
                        <Button variant="danger" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button type="submit">{isCreationMode ? "Create" : "Save"}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UserGroupOverview;
