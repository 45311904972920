import {Image} from "react-bootstrap";
import React from "react";
import {colors} from "../theme";

interface IProps {
    picture?: string | null
}

export const IssuerPicture = ({picture}: IProps) => {
    return picture ? (
        <Image src={picture} style={PictureStyle}/>
    ) : (
        <div style={PictureMissingStyle}/>
    )
}

const PictureStyle = {
    width: "50px",
    height: "50px",
    marginRight: "15px",
};

const PictureMissingStyle = {
    width: "50px",
    height: "50px",
    marginRight: "15px",
    backgroundColor: colors.grey
}