import React, { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ReadUserById } from "../graphql/user";
import { getUserNameForCheck } from "../helper/user";
import { useRemoveAccountMutation, UserResultFragment } from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";

interface IProps {
    visible: boolean
    onExit: () => void
    onSuccess: () => void
    user: UserResultFragment
}

// TODO: Nice if this component can be exported as a generic "ModalWithConfirmationCode" and customizable in different situations for different entities.
// PS: visible, onExit, and onSuccess are properties that are common in every modal. Nice if we can create a basic interface (IModalProps) that can be extended by other modal implementations.
export const ModalDeleteAccount = ({
    visible,
    onExit,
    onSuccess,
    user
}: IProps) => {

    const [validated, setValidated] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const [removeAccount, {
        loading: loadingRemoveAccount,
        error: errorRemoveAccount
    }] = useRemoveAccountMutation();

    const deleteUser = async (message: string) => {
        try {
            await removeAccount({
                variables: {
                    userId: user.id,
                    message
                },
                refetchQueries: [{
                    query: ReadUserById,
                    variables: {
                        userId: user.id
                    }
                }]
            })
        } catch (e) {
            console.error(e)
        }
    }

    const codeForConfirmation = getUserNameForCheck(user, "DELETE")

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        const isFormValid = form.checkValidity();
        setValidated(true);

        // Create subscription
        if (isFormValid) {
            try {
                await deleteUser('');
                onSuccess();
            } catch (e) {
                console.debug(e);
            }
        }
    };

    return (
        <Modal show={visible} onHide={onExit}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete account
                </Modal.Title>
            </Modal.Header>

            <Loading isLoading={loadingRemoveAccount} />
            <Error error={errorRemoveAccount?.graphQLErrors[0].message} />

            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group
                        as={Col}
                        md="12"
                        controlId="confirmationCode"
                    >
                        <Form.Label>Confirmation code️</Form.Label>
                        <Form.Text>
                            Insert the following code <code>{codeForConfirmation}</code>{" "}
                            to confirm the operation.
                        </Form.Text>
                        <Form.Control
                            type="text"
                            placeholder="Confirmation code"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.currentTarget.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide the confirmation code{" "}
                            <code>{codeForConfirmation}</code>.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        onExit()
                    }}>
                        Exit
                    </Button>
                    <Button type="submit" variant="primary" disabled={confirmationCode !== codeForConfirmation}>
                        Delete account
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal >
    );
}