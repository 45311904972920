import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Card, Col, Row, Table, Toast } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import { ReadEmailsForNewsletter, ReadUsers } from "../graphql/user";
import InfiniteScroll from "react-infinite-scroll-component";
import Select, { ValueType } from "react-select";
import { FormSearchByString } from "./FormSearchByString";
import {
    Maybe,
    ReadAllUsersQuery,
    ReadAllUsersQueryVariables,
    ReadEmailsForNewsletterQuery,
    ReadEmailsForNewsletterQueryVariables, Scalars,
    UserActivityEnum,
    UserResultFragment,
} from "../types/graphql";
import { Error } from "./Error";
import { Loading } from "./Loading";
import {
    getIconFromPEPStatus,
    getIconFromEInvoice,
    getIconFromEmailCheck,
} from "../helper/user";
import Button from "react-bootstrap/Button";
import { generateFile } from "../helper/file";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { RegionFlag } from "./RegionFlag";

type OptionType = {
    value: boolean;
    label: string;
};

type TrueFalseOptionUndefinedType = {
    value: boolean | undefined;
}

type OptionActivityType = {
    value: UserActivityEnum;
    label: string;
};

const emailOptions: OptionType[] = [
    { value: true, label: "Email checked" },
    { value: false, label: "Email not checked" },
];

const mobileOptions: OptionType[] = [
    { value: true, label: "Mobile checked" },
    { value: false, label: "Mobile not checked" },
];

const activityOptions: OptionActivityType[] = [
    { value: UserActivityEnum.Present, label: "Total Available" },
    { value: UserActivityEnum.Active, label: "Active" },
    { value: UserActivityEnum.Inactive, label: "Inactive" },
    { value: UserActivityEnum.Deleted, label: "Deleted" }
];

const efakturaOptions: OptionType[] = [
    { value: true, label: "Accepts eFaktura" },
    { value: false, label: "Does not accept eFaktura" },
];


export const UsersOverview = () => {
    const isFirstFetch = useRef(true);
    const isChangedFetch = useRef(false);
    const [orderByIdDesc, setOrderByIdDesc] = useState<boolean>(true);
    const [items, setItems] = useState<Array<UserResultFragment>>([]);
    const [readUsers, { data, loading, error }] = useLazyQuery<
        ReadAllUsersQuery,
        ReadAllUsersQueryVariables
    >(ReadUsers);
    const [
        readEmails,
        { data: dataEmails, loading: loadingEmails, error: errEmails },
    ] = useLazyQuery<
        ReadEmailsForNewsletterQuery,
        ReadEmailsForNewsletterQueryVariables
    >(ReadEmailsForNewsletter);

    const [copiedText, setCopiedText] = useState('');
    const [showToast, setShowToast] = useState(false);

    const handleCopy = (text: string) => {
        setCopiedText(text)
        setShowToast(true);
        setTimeout(() => setShowToast(false), 2000); // hide after 2 seconds
    };

    // Filters
    const [filterByName, setFilterByName] = useState<string>();
    const [filterByCheckedEmail, setFilterByCheckedEmail] = useState<boolean | undefined>();
    const [acceptEInvoice, setAcceptEInvoice] = useState<boolean | undefined>(undefined);
    const [filterByCheckedMobile, setFilterByCheckedMobile] = useState<boolean | undefined>();
    const [filterByActivity, setFilterByActivity] = useState<UserActivityEnum | undefined>();

    const fetchData = useCallback(
        async (resetCursor?: boolean) => {
            let after = data?.result?.pageInfo?.endCursor;

            if (resetCursor) {
                setItems([]);
                after = null;
            }

            await readUsers({
                variables: {
                    first: 25,
                    after,
                    filterByName,
                    filterByCheckedEmail,
                    filterByCheckedMobile,
                    filterByActivity,
                    descId: orderByIdDesc,
                    acceptEfaktura: acceptEInvoice,
                },
            });
        },
        [
            data,
            filterByCheckedEmail,
            filterByCheckedMobile,
            filterByActivity,
            filterByName,
            readUsers,
            orderByIdDesc,
            acceptEInvoice
        ]
    );

    const copyToClipboard = (value: string) => {
        if (!navigator.clipboard) {
            console.error("Navigator clipboard is not supported");
            return;
        }

        navigator.clipboard.writeText(value).then(
            () => {
                console.debug("Async: Copying to clipboard was successful!");
            },
            (err) => {
                console.error("Async: Could not copy text: ", err);
            }
        );
    };

    useEffect(() => {
        fetchData(true);
    }, [orderByIdDesc]);


    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData();
            isFirstFetch.current = false;
        }
    }, [fetchData]);

    useEffect(() => {
        if (isChangedFetch.current) {
            fetchData(true);
            isChangedFetch.current = false;
        }
    }, [fetchData, isChangedFetch]);

    useEffect(() => {
        const edges = data?.result?.edges;
        if (!loading && !error && edges) {
            setItems((prev) => [
                ...prev,
                ...edges.map((v) => v?.node as UserResultFragment),
            ]);
        }
    }, [loading, error, data]);

    useEffect(() => {
        // Hubspot requirement: Contain fewer than 250,000 rows and 1,000 columns.
        if (dataEmails?.readEmailsForNewsletter?.emails) {
            const emails = dataEmails.readEmailsForNewsletter.emails;
            const emailsLength = emails.length;
            const group = 250000;
            for (let i = 0; i <= emailsLength / group; i++) {
                generateFile({
                    fileName: `newsletter_${moment().format("YYYY-MM-DD")}_${i + 1}.csv`,
                    fileType: "plain/text",
                    content:
                        "Email Address\n" +
                        emails.slice(i * group, (i + 1) * group).join("\n"),
                });
            }
        }
    }, [dataEmails]);

    const onCardClick = useCallback((id: string) => {
        window.open(`/user/${id}`, "_blank");
    }, []);

    const onEmailNewsletter = async () => {
        try {
            await readEmails({
                variables: {
                    acceptEInvoice,
                    filterByActivity,
                    filterByCheckedMobile,
                    filterByName,
                    descId: orderByIdDesc,
                    isDeleted: false,
                }
            })
        } catch (e) {
            console.error(e);
        }
    };

    const loadMoreOnScroll = () => {
        setTimeout(() => {
            fetchData();
        }, 500);
    };

    const itemsToRender = useMemo(() => {
        return items.map((v) => (
            <tr key={v.id}>
                <td onClick={() => onCardClick(v.id)} style={TappableCell}>
                    {v.id}
                </td>
                <td onClick={() => onCardClick(v.id)} style={TappableCell}>
                    {v.firstname} {v.lastname}
                </td>
                <CopyToClipboard text={v.email || ''} onCopy={() => handleCopy(v.email || '')}>
                    <td style={{ cursor: 'pointer' }}>
                        {v.email}
                    </td>
                </CopyToClipboard>
                <td>{getIconFromEmailCheck(v.emailCheck)}</td>
                <td>{getIconFromPEPStatus(v.permission.pep)}</td>
                <td>{getIconFromEInvoice(v.permission.einvoice)}</td>
                <td style={{ textAlign: 'center' }}>
                    <RegionFlag region={v.region} normalSize hideText/>
                </td>
            </tr>
        ));
    }, [items, onCardClick]);

    return (
        <Card>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={1400} autohide style={{
                position: 'fixed',
                top: '50%',
                right: '45%',
                border: '1px solid darkblue',
                minWidth: 200,
            }}>
                <Toast.Body><b>Copied to clipboard:</b><br/>{copiedText}</Toast.Body>
            </Toast>
            <Card.Header>
                <h5 className="dark">Users</h5>
                <Table striped bordered>
                    <thead>
                    <tr>
                        <th>PEP Symbol</th>
                        <th>Explanation</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>⚪️</td>
                        <td>Not affected by PEP and is an automatic user</td>
                    </tr>
                    <tr>
                        <td>✅</td>
                        <td>Self pointed out as affected by PEP and is an approved user</td>
                    </tr>
                    <tr>
                        <td>❌</td>
                        <td>Self pointed out as affected by PEP and is not approved as a user</td>
                    </tr>
                    <tr>
                        <td>⏳</td>
                        <td>Self pointed out as affected by PEP and is not currently treated.</td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Header>
            <Card.Header>
                <Row>
                    <Col sm={12} md={12}>
                        <Button variant="primary" onClick={onEmailNewsletter}>
                            Download emails for newsletter
                        </Button>
                        <Loading isLoading={loadingEmails}/>
                        {errEmails && <Error error={error?.graphQLErrors[0].message}/>}
                    </Col>
                </Row>
            </Card.Header>

            <Card.Header>
                <Row>
                    <Col sm={12} md={4}>
                        <FormSearchByString
                            placeholder="Search user by name or email"
                            onSubmit={async (name) => {
                                setFilterByName(name);
                                isChangedFetch.current = true;
                            }}
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={async (v: ValueType<OptionType>) => {
                                setFilterByCheckedEmail(
                                    // @ts-ignore
                                    typeof v?.value === "boolean" ? v.value : undefined
                                );
                                isChangedFetch.current = true;
                            }}
                            placeholder={"Filter by email check"}
                            options={emailOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={(v: ValueType<OptionType>) => {
                                setFilterByCheckedMobile(
                                    // @ts-ignore
                                    typeof v?.value === "boolean" ? v.value : undefined
                                );
                                isChangedFetch.current = true;
                            }}
                            placeholder={"Filter by mobile check"}
                            options={mobileOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={(v: ValueType<OptionActivityType>) => {
                                // @ts-ignore
                                setFilterByActivity(v?.value);
                                isChangedFetch.current = true;
                            }}
                            placeholder={"Filter by activity"}
                            options={activityOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col>
                        <Select
                            defaultValue={null}
                            onChange={(v: ValueType<TrueFalseOptionUndefinedType>) => {
                                // @ts-ignore
                                setAcceptEInvoice(typeof v?.value === "boolean" ? v.value : undefined);
                                isChangedFetch.current = true;
                            }}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    width: "18em", // This sets the width
                                }),
                            }}
                            placeholder={"Filter by eFaktura"}
                            options={efakturaOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Error error={error?.graphQLErrors[0].message}/>

                <InfiniteScroll
                    hasMore={data?.result?.pageInfo.hasNextPage || false}
                    loader={<Loading isLoading/>}
                    dataLength={items.length}
                    next={loadMoreOnScroll}
                >
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th onClick={() => {
                                setOrderByIdDesc(!orderByIdDesc);
                            }}># Id
                            </th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Email check</th>
                            <th>PEP</th>
                            <th>eFaktura</th>
                            <th style={{ textAlign: 'center' }}>Region</th>
                        </tr>
                        </thead>
                        <tbody>{itemsToRender}</tbody>
                    </Table>
                </InfiniteScroll>
            </Card.Body>
        </Card>
    );
};

const TappableCell = {
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.08)",
};
