import { gql } from "@apollo/client";
import {
  UserGroupFragments,
  UserGroupMemberFragments,
} from "./fragments/userGroup";

export const ReadUserGroups = gql`
  query readUserGroups {
    userGroups: readUserGroups {
      ...UserGroupResult
    }
  }
  ${UserGroupFragments.result}
`;

export const CreateUserGroup = gql`
  mutation createUserGroup($name: String!, $description: String!, $region: RegionEnum!) {
    userGroup: createUserGroup(name: $name, description: $description, region: $region) {
      ...UserGroupResult
    }
  }
  ${UserGroupFragments.result}
`;

export const UpdateUserGroup = gql`
  mutation updateUserGroup(
    $groupId: ID!
    $name: String!
    $description: String!
  ) {
    userGroup: updateUserGroup(
      groupId: $groupId
      name: $name
      description: $description
    ) {
      ...UserGroupResult
    }
  }
  ${UserGroupFragments.result}
`;

export const DeleteUserGroup = gql`
  mutation deleteUserGroup($groupId: ID!) {
    userGroups: deleteUserGroup(groupId: $groupId) {
      ...UserGroupResult
    }
  }
  ${UserGroupFragments.result}
`;

export const ReadUserGroupMembers = gql`
  query readUserGroupMembers(
    $groupId: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    userGroupMembers: readUserGroupMembers(
      groupId: $groupId
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      ...UserGroupMemberResult
    }
  }
  ${UserGroupMemberFragments.result}
`;

export const CreateUserGroupMember = gql`
  mutation createUserGroupMember($userId: ID! $groupId: ID!) {
    userGroupMember: createUserGroupMember(userId: $userId groupId: $groupId) {
      ...UserGroupMemberResult
    }
  }
  ${UserGroupMemberFragments.result}
`;

export const DeleteUserGroupMember = gql`
  mutation deleteUserGroupMember($memberId: ID! $groupId: ID!) {
    userGroupMembers: deleteUserGroupMember(memberId: $memberId groupId: $groupId) {
      ...UserGroupMemberResult
    }
  }
  ${UserGroupMemberFragments.result}
`;
