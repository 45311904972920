import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Moment from "react-moment";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import { ReadUserActivities } from "../graphql/user";
import {
    LogWithReference,
    ReadUserActivitiesQuery,
    ReadUserActivitiesQueryVariables,
} from "../types/graphql";
import InfiniteScroll from "react-infinite-scroll-component";
import { Error } from "./Error";
import { Loading } from "./Loading";

interface IProps {
    userId: string;
}

export const UserActivity = ({ userId }: IProps) => {
    const isFirstFetch = useRef(true);
    const [items, setItems] = useState<Array<LogWithReference>>([]);
    const [readUserActivities, { data, loading, error }] = useLazyQuery<
        ReadUserActivitiesQuery,
        ReadUserActivitiesQueryVariables
    >(ReadUserActivities);

    const fetchData = useCallback(async () => {
        let after = data?.result?.pageInfo?.endCursor;
        await readUserActivities({
            variables: {
                first: 10,
                userId,
                after,
            },
        });
    }, [data, readUserActivities, userId]);

    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData();
            isFirstFetch.current = false;
        }
    }, [fetchData]);

    useEffect(() => {
        const edges = data?.result?.edges;
        if (!loading && !error && data && edges) {
            setItems((prev) => [
                ...prev,
                ...edges.map((v) => v?.node as LogWithReference),
            ]);
        }
    }, [loading, error, data]);

    const rows = useMemo(() => {
        return items.map((v, i) => {
            return (
                <tr key={i}>
                    <td>
                        <OverlayTrigger
                            key={i}
                            placement="top"
                            overlay={
                                <Tooltip id={i.toString()}>
                                    <Moment fromNow={true}>{v.time}</Moment>
                                </Tooltip>
                            }
                        >
                            <Moment format="DD MMMM YYYY HH:mm:ss">{v.time}</Moment>
                        </OverlayTrigger>
                    </td>
                    <td>{v.event}</td>
                    <td>{v.status}</td>
                    <td>{v.error}</td>
                    <td>{v.component}</td>
                    <td>{v.details.context}</td>
                    <td>{v.details.stackTrace}</td>
                </tr>
            );
        });
    }, [items]);

    return (
        <>
            <Error error={error?.graphQLErrors[0].message}/>

      <InfiniteScroll
        hasMore={data?.result?.pageInfo.hasNextPage || false}
        loader={<Loading isLoading />}
        dataLength={items.length}
        next={() => fetchData()}
      >
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Time</th>
              <th>Event</th>
              <th>Status</th>
              <th>Error</th>
              <th>Component</th>
              <th>Context</th>
              <th>Stack trace</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </InfiniteScroll>
    </>
  );
};
