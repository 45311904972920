import React, { useMemo, useCallback, useState } from "react";
import { Button, Card, Col, Row, Table, Modal, Form } from "react-bootstrap";

import {
  IssuerWhitelistOrigin,
  useCreateIssuerWhitelistMutation,
  useReadIssuerWhitelistQuery,
  useRemoveIssuerWhitelistMutation,
} from "../types/graphql";
import { Loading } from "./Loading";
import { ReadIssuerWhitelist } from "../graphql/issuer";
import { Error } from "./Error";
import LoadingButton from "./LoadingButton";

interface IProps {
  issuerId: string;
}

export const IssuerWhitelistOverview = ({ issuerId }: IProps) => {
  const [isShowAddModal, setShowModal] = useState(false);
  const {
    data: dataWhitelist,
    loading: loadingWhitelist,
    error: errWhitelist,
  } = useReadIssuerWhitelistQuery({
    variables: { issuerId },
    fetchPolicy: "cache-and-network",
  });

  const [
    createWhitelist,
    { loading: loadingCreateWhitelist, error: errCreateWhitelist },
  ] = useCreateIssuerWhitelistMutation({
    refetchQueries: [{ query: ReadIssuerWhitelist, variables: { issuerId } }],
  });

  const [
    removeWhitelist,
    { loading: loadingRemoveWhitelist, error: errRemoveWhitelist },
  ] = useRemoveIssuerWhitelistMutation({
    refetchQueries: [{ query: ReadIssuerWhitelist, variables: { issuerId } }],
  });

  const onCreatePress = () => {
    setShowModal(true);
  };

  const onRemovePress = useCallback(
    (accountNumber: string) => {
      removeWhitelist({
        variables: {
          issuerId,
          accountNumber,
        },
      });
    },
    [issuerId, removeWhitelist]
  );

  const hide = () => {
    setShowModal(false);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    try {
      await createWhitelist({
        variables: { issuerId, accountNumber: data.accountNumber as string },
      });
      setShowModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const itemsToRender = useMemo(() => {
    return dataWhitelist?.whitelist.map((i) => (
      <tr key={i.id}>
        <td>{i.id}</td>
        <td>{i.accountNumber}</td>
        <td>
          {i.origin === IssuerWhitelistOrigin.Portal && (
            <LoadingButton
              isLoading={loadingRemoveWhitelist}
              onClick={() => onRemovePress(i.accountNumber)}
              variant={"danger"}
            >
              Remove
            </LoadingButton>
          )}
        </td>
      </tr>
    ));
  }, [dataWhitelist, loadingRemoveWhitelist, onRemovePress]);

  return (
    <>
      <Card.Body>
        <h5 className="dark">Whitelist</h5>
        <Error error={errWhitelist?.graphQLErrors[0].message} />
        <Error error={errCreateWhitelist?.graphQLErrors[0].message} />
        <Error error={errRemoveWhitelist?.graphQLErrors[0].message} />
      </Card.Body>

      <Card.Header>
        <Row>
          <Col sm="auto">
            <Button onClick={onCreatePress}>Add account number</Button>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {loadingWhitelist ? (
          <Loading isLoading={loadingWhitelist} />
        ) : (
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Account number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{itemsToRender}</tbody>
          </Table>
        )}
      </Card.Body>
      <Modal show={isShowAddModal} onHide={hide}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>Add a new account</Modal.Header>
          <Modal.Body>
            <Error error={errCreateWhitelist?.graphQLErrors[0].message} />
            <Form.Group controlId="accountNumber">
              <Form.Label>Account number</Form.Label>
              <Form.Control name="accountNumber" placeholder="Account Number" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={hide}>
              Close
            </Button>
            <LoadingButton isLoading={loadingCreateWhitelist} type="submit">
              Save
            </LoadingButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
